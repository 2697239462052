import { AsAssociateBusinessUnitResult } from '@Types/business-unit/BusinessUnit';
import {
  OrderStatus,
  scheduledDeliveryWindow,
  OrderDetailLineItem,
  ReturnRow,
  OrderDetail,
} from '@Types/shamrockApi/Order';
import { ExtraProductData, ShamrockCutOffsDaysOfWeek } from '@Types/shamrockApi/Product';
import colors from 'composable/chakra/theme/foundations/colors';
import {
  KEY_A_LA_CARTE,
  KEY_PICK_UP,
  KEY_SCHEDULED_DELIVERY,
  ORDER_TYPE_FOR_A_LA_CARTE,
  ORDER_TYPE_FOR_PICK_UP,
  ORDER_TYPE_FOR_SCHEDULED_DELIVERY,
} from 'composable/components/checkout/utils/constants';
import { getTagsFromExtendedProductData } from 'composable/components/pdp/components/parts/tags';
import { adjustDeliveryDateForTimezone } from 'composable/helpers/utils/adjust-delivery-date-for-timezone';
import {
  isCancelled,
  isCompleted,
  isDeliveredComplete,
  isError,
  isOutForDelivery,
  isProcessing,
  isReadyForPickup,
  isSubmitted,
} from 'composable/helpers/utils/order-status';
import { addMinutes, format, getTime, isAfter, isEqual, parse } from 'date-fns';
import { parseISO } from 'date-fns/parseISO';
import { subDays } from 'date-fns/subDays';
import { formatInTimeZone, getTimezoneOffset } from 'date-fns-tz';
import { filterDeliveryScheduleByDate, getDeliveryPlanForActiveWarehouse } from 'functions/deliverySchedule';
import { isPastCutoffTime } from 'frontastic/tastics/composable/edit-order/utils';
import { InventoryStatus, getInventoryInfo } from './inventory-utils';
import { isProductOrderable } from './is-product-orderable';
import { getActiveAccountDeliveryDates, getDateWithCutoff } from '../hooks';

export const getStatusBadgeColors = (
  orderStatus: OrderStatus,
  orderNumber?: string,
): { border: string; background: string; text: string } => {
  if (!orderStatus || orderStatus === '' || isCancelled(orderStatus)) {
    return { border: colors.red[500], background: colors.red[100], text: colors.neutral[900] };
  }

  if (isDeliveredComplete(orderStatus) || isCompleted(orderStatus)) {
    return { border: colors.primary[600], background: colors.primary[600], text: colors.white };
  }

  if (isSubmitted(orderStatus)) {
    return { border: colors.yellow[400], background: colors.yellow[100], text: colors.neutral[900] };
  }

  if (isProcessing(orderStatus)) {
    return { border: colors.orange[500], background: colors.orange[200], text: colors.neutral[900] };
  }

  if (isOutForDelivery(orderStatus)) {
    return { border: colors.primary[400], background: '#D6FADF', text: colors.neutral[900] };
  }

  if (isReadyForPickup(orderStatus)) {
    return { border: colors.primary[400], background: '#D6FADF', text: colors.neutral[900] };
  }

  if (orderNumber && isError(orderStatus, orderNumber)) {
    return { border: colors.red[500], background: colors.red[100], text: colors.black };
  }

  // just to be safe in case order status is not recognized
  return { border: colors.red[500], background: colors.red[100], text: colors.neutral[900] };
};

export const getStatusBadgeCopyKey = ({
  orderStatus,
  isReturns,
  orderNumber,
}: {
  orderStatus: OrderStatus;
  orderNumber?: string;
  isReturns?: boolean;
}): string | null => {
  if (!orderStatus || orderStatus === '') {
    return null;
  }

  if (isReturns) {
    if (isCompleted(orderStatus)) {
      return 'orders.allReturns.status.requestCompleted';
    }

    if (isSubmitted(orderStatus)) {
      return 'orders.allReturns.status.requestSubmitted';
    }

    if (isCancelled(orderStatus)) {
      return 'orders.allReturns.status.requestCancelled';
    }

    return null;
  }

  if (isDeliveredComplete(orderStatus)) {
    return 'orders.filters.sort.deliveredComplete';
  }

  if (isCancelled(orderStatus)) {
    return 'orders.filters.sort.cancelled';
  }

  if (isSubmitted(orderStatus)) {
    return 'orders.filters.sort.submitted';
  }

  if (isProcessing(orderStatus)) {
    return 'orders.filters.sort.processing';
  }

  if (isOutForDelivery(orderStatus)) {
    return 'orders.filters.sort.outForDelivery';
  }

  if (isReadyForPickup(orderStatus)) {
    return 'orders.filters.sort.readyForPickup';
  }

  if (orderNumber && isError(orderStatus, orderNumber)) {
    return 'orders.filters.sort.error';
  }

  return null;
};

export const getDeliveryStatusCopyKey = (
  orderStatus: OrderStatus,
  scheduledDeliveryWindow?: scheduledDeliveryWindow,
): string => {
  if (!orderStatus) {
    return '-';
  }

  if (isCancelled(orderStatus)) {
    return 'orders.filters.sort.cancelled';
  }

  if (isDeliveredComplete(orderStatus)) {
    return 'account.orders.status.delivered';
  }

  if (isSubmitted(orderStatus)) {
    return 'orders.delivery.pending';
  }

  if (isProcessing(orderStatus)) {
    return 'orders.delivery.scheduled';
  }

  if (isOutForDelivery(orderStatus) || isReadyForPickup(orderStatus)) {
    if (!scheduledDeliveryWindow) {
      return isReadyForPickup(orderStatus) ? 'orders.delivery.estimated.delivery' : '';
    }
    if (scheduledDeliveryWindow?.isOvernight) {
      return 'orders.delivery.estimated.tomorrow';
    }
    return 'orders.delivery.estimated.today';
  }
};

export const getDeliveryTimeOutput = (
  orderStatus: OrderStatus,
  deliveryDate: string,
  scheduledDeliveryWindow?: scheduledDeliveryWindow,
): string => {
  if (!orderStatus || orderStatus === '') {
    return '';
  }

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let deliveryDateFixed = deliveryDate;

  if (deliveryDate.indexOf('/') > -1) {
    deliveryDateFixed = deliveryDate ? format(parse(deliveryDate, 'MM/dd/yyyy', new Date()), 'yyyy-MM-dd') : '';
  }

  if (isDeliveredComplete(orderStatus) || isSubmitted(orderStatus) || isProcessing(orderStatus)) {
    return adjustDeliveryDateForTimezone(deliveryDateFixed, timezone);
  }

  if (isOutForDelivery(orderStatus) || isReadyForPickup(orderStatus)) {
    if (!scheduledDeliveryWindow) {
      return isReadyForPickup(orderStatus) ? adjustDeliveryDateForTimezone(deliveryDateFixed, timezone) : '';
    }

    return getFormattedDeliveryWindow(
      deliveryDateFixed,
      scheduledDeliveryWindow.deliveryWindowStartTime,
      scheduledDeliveryWindow.deliveryWindowEndTime,
      timezone,
    );
  }

  return !!deliveryDateFixed ? formatInTimeZone(new Date(deliveryDateFixed), timezone, 'MM/dd/yyyy') : '';
};

export const getFormattedDeliveryWindow = (
  deliveryDateFixed: string,
  deliveryWindowStartTime: string,
  deliveryWindowEndTime: string,
  timezone: string,
): string => {
  const startIsoString = `${deliveryDateFixed}T${deliveryWindowStartTime}Z`;
  const endIsoString = `${deliveryDateFixed}T${deliveryWindowEndTime}Z`;

  const formattedStartTime = startIsoString ? formatInTimeZone(new Date(startIsoString), timezone, 'p') : '';
  const formattedEndTime = endIsoString ? formatInTimeZone(new Date(endIsoString), timezone, 'p') : '';

  const timezoneAbbreviation =
    Intl.DateTimeFormat('en-US', { timeZone: timezone, timeZoneName: 'short' })
      .formatToParts(new Date())
      .find((part) => part.type === 'timeZoneName')?.value || '';

  return `${formattedStartTime} - ${formattedEndTime} ${timezoneAbbreviation}`;
};

export const getOrderTypeCopyKey = (status: OrderStatus): string => {
  if (isReadyForPickup(status)) {
    return 'orderDetails.type.warehousePickUp';
  }
  return 'orderDetails.type.standardDelivery';
};

export const isStandardDeliveryType = (deliveryType?: string): boolean => {
  if (!deliveryType) {
    return false;
  }
  return [
    'standard',
    'pulloff',
    'relay',
    'relay *',
    'relay_pl',
    'relay_pk',
    'recovery',
    'compvan',
    'autoship',
    'merge',
    'aop',
    'sameday',
    'samples',
    'courier/xcourrier',
    'courier/xcourier',
    'courier',
    'xcourier',
  ].includes(deliveryType.toLowerCase());
};

export const isALaCartDeliveryType = (deliveryType?: string): boolean => {
  if (!deliveryType) {
    return false;
  }
  return ['flxmetro'].includes(deliveryType.toLowerCase());
};

export const isPickupDeliveryType = (deliveryType?: string): boolean => {
  if (!deliveryType) {
    return false;
  }
  return ['rtd_wc', 'willcall'].includes(deliveryType.toLowerCase());
};

export const isCustomerDirectDeliveryType = (deliveryType?: string): boolean => {
  if (!deliveryType) {
    return false;
  }
  return ['dropship'].includes(deliveryType.toLowerCase());
};

export const isInvoiceReissueDeliveryType = (deliveryType?: string): boolean => {
  if (!deliveryType) {
    return false;
  }
  return ['rebill'].includes(deliveryType.toLowerCase());
};

export const getDeliveryTypeCopyKey = (deliveryType?: string): string => {
  if (!deliveryType) {
    return '';
  }
  if (deliveryType.toLowerCase().includes('relay')) {
    return 'orders.delivery.type.standard';
  }

  if (isStandardDeliveryType(deliveryType)) {
    return 'orders.delivery.type.standard';
  }

  if (isALaCartDeliveryType(deliveryType)) {
    return 'orders.delivery.type.aLaCarte';
  }

  if (isPickupDeliveryType(deliveryType)) {
    return 'orders.delivery.type.pickup';
  }

  if (isCustomerDirectDeliveryType(deliveryType)) {
    return 'orders.delivery.type.customerDirect';
  }

  if (isInvoiceReissueDeliveryType(deliveryType)) {
    return 'orders.delivery.type.invoiceReissue';
  }

  return '';
};

/**
 * Gives a value for each status. Lowest values are displayed first
 * @param status Return status
 * @returns number value to use for comparison.
 */
const returnStatusSortingValues = (status: string): number => {
  if (isSubmitted(status)) {
    return 3;
  }

  if (isCancelled(status)) {
    return 2;
  }

  if (isCompleted(status)) {
    return 1;
  }

  // No Status itens should be displayed before canceled
  return 4;
};

export const sortReturnsByDateCreated = (a: string, b: string) => {
  return getTime(parseISO(a)) > getTime(parseISO(b)) ? -1 : 1;
};

const sortReturnsByStatus = (a: string, b: string) =>
  returnStatusSortingValues(a) > returnStatusSortingValues(b) ? -1 : 1;

/**
 * Sort returns based on Status and time.
 * @param returns returns array
 * @returns returns array sorted
 */
export const sortReturns = (returns: ReturnRow[], sortBy: string): ReturnRow[] => {
  return returns.sort((a, b) => {
    if (sortBy === 'dateCreated') {
      if (a.createdDateTime.substring(0, 10) !== b.createdDateTime.substring(0, 10)) {
        return sortReturnsByDateCreated(a.createdDateTime, b.createdDateTime);
      } else if (a.status !== b.status) {
        return sortReturnsByStatus(a.status, b.status);
      }
    } else if (sortBy === 'orderNumber') {
      return parseInt(a.orderNumber) < parseInt(b.orderNumber) ? 1 : -1;
    } else if (sortBy === 'creditAmountHighToLow') {
      return a.creditAmount < b.creditAmount ? 1 : -1;
    } else if (sortBy === 'requestAmountHighToLow') {
      return a.requestedAmount < b.requestedAmount ? 1 : -1;
    } else if (sortBy === 'status') {
      if (a.status !== b.status) {
        return sortReturnsByStatus(a.status, b.status);
      } else if (a.createdDateTime.substring(0, 10) !== b.createdDateTime.substring(0, 10)) {
        return sortReturnsByDateCreated(a.createdDateTime, b.createdDateTime);
      }
    }

    return parseInt(a.returnNumber) < parseInt(b.returnNumber) ? 1 : -1;
  });
};

/**
 * Checks if a line item is NOT refundable.
 * @param lineItem - The line item to check.
 * @returns Returns true if current date is greater than JIT edit cutoff.
 */
export const isNonRefundable = (justInTimeEditCutoff: string) => {
  if (!justInTimeEditCutoff) {
    return false;
  }

  const currentDate = new Date();
  const jitDate = parseISO(justInTimeEditCutoff);

  return isAfter(currentDate, jitDate) || isEqual(currentDate, jitDate);
};

export const getOrderTypeByShippingMethod = (shippingMethod: string): string => {
  switch (shippingMethod) {
    case KEY_PICK_UP:
      return ORDER_TYPE_FOR_PICK_UP;
    case KEY_A_LA_CARTE:
      return ORDER_TYPE_FOR_A_LA_CARTE;
    case KEY_SCHEDULED_DELIVERY:
    default:
      return ORDER_TYPE_FOR_SCHEDULED_DELIVERY;
  }
};

export const getShippingMethodByOrderType = (orderType: string): string => {
  switch (orderType) {
    case ORDER_TYPE_FOR_PICK_UP:
      return KEY_PICK_UP;
    case ORDER_TYPE_FOR_A_LA_CARTE:
      return KEY_A_LA_CARTE;
    case ORDER_TYPE_FOR_SCHEDULED_DELIVERY:
    default:
      return KEY_SCHEDULED_DELIVERY;
  }
};

export type ShouldDisableProps = {
  isDisabled: boolean;
  warningMessage: string;
};

export const getLineItemsSkus = (lineItems: OrderDetailLineItem[]) =>
  (lineItems ?? []).map((item) => item.productNumber);

type IsLineItemDisabled = (data: {
  ctBusinessUnit: Partial<AsAssociateBusinessUnitResult>;
  ctProduct: OrderDetailLineItem;
  editOrder?: { isEditingOrder?: boolean; editCart: OrderDetail };
  extraProductData: ExtraProductData;
}) => {
  isDisabled: boolean;
  code: string;
  warningMessage?: string;
};

export const isLineItemDisabled: IsLineItemDisabled = ({ ctBusinessUnit, ctProduct, editOrder, extraProductData }) => {
  let response = {
    isDisabled: false,
    warningMessage: null,
    code: null,
  };

  const companyInfo = extraProductData?.companyInfo?.data;

  // ------------------------------------------------
  if (editOrder?.isEditingOrder) {
    const currentDate = new Date();
    // Get cutoff for deliveryDate
    const deliveryDateStr = editOrder?.editCart?.estimatedDeliveryDate;
    const deliveryDate = deliveryDateStr ? parseISO(deliveryDateStr) : new Date();
    const deliveryPlan = getDeliveryPlanForActiveWarehouse(ctBusinessUnit as AsAssociateBusinessUnitResult);
    const schedule = filterDeliveryScheduleByDate(deliveryPlan.DeliverySchedules, deliveryDate);
    const cutoffDate = getDateWithCutoff(deliveryDate, schedule.LeadDays, schedule.CutoffTime);

    if (!isProductOrderable(extraProductData?.status)) {
      return {
        isDisabled: true,
        warningMessage: '',
        code: 'not_orderable',
      };
    }

    // Newly added items are always editable
    if (!ctProduct.hasOwnProperty('lineNumber')) {
      return {
        isDisabled: false,
        warningMessage: '',
        code: 'new_item',
      };
    }

    // All items are disabled if past business cutoff for standard delivery
    if (currentDate > cutoffDate && editOrder?.editCart?.orderType === ORDER_TYPE_FOR_SCHEDULED_DELIVERY) {
      return {
        isDisabled: true,
        warningMessage: '',
        code: `past_business_cutoff:${cutoffDate}`,
      };
    }

    // SpecialOrder (added by sales rep) products are never editable
    const productTags = getTagsFromExtendedProductData(extraProductData);
    if (productTags.isSpecialOrder) {
      return {
        isDisabled: true,
        warningMessage: 'editOrder.specialOrderProductWarning',
        code: 'special_order',
      };
    }

    if (!productTags?.isActive) {
      return {
        isDisabled: true,
        warningMessage: 'editOrder.inactiveProductWarning',
        code: 'not_active',
      };
    }

    //Cutoff time for all products
    const cutoffProductTime = companyInfo?.cutoffs?.daysOfWeek;
    const cutOffDayOfWeek = cutoffProductTime?.find((cutoff) => cutoff.dayOfWeek === deliveryDate.getUTCDay());
    if (cutOffDayOfWeek) {
      const productDateCutOff = calculateProductDateCutOff(deliveryDateStr, cutOffDayOfWeek);
      if (currentDate > productDateCutOff) {
        return {
          isDisabled: true,
          warningMessage: 'editOrder.productCutOffPassedWarning',
          code: 'past_product_cutoff',
        };
      }
    }
  }

  // ------------------------------------------------
  // frontend/composable/helpers/utils/order-utils.ts:shouldDisableLineItem()
  const isSurOrJitItem =
    (companyInfo?.isJustInTime || companyInfo?.isShipUponReceipt) && isNonRefundable(ctProduct?.justInTimeEditCutoff);
  const deliveryDates = getActiveAccountDeliveryDates({ activeAccount: ctBusinessUnit });
  if (
    (ctProduct.disableLogic?.isDisabled ||
      isPastCutoffTime(ctBusinessUnit, deliveryDates, extraProductData) ||
      isSurOrJitItem) &&
    ctProduct.hasOwnProperty('lineNumber') // Avoid disabling new items
  ) {
    return {
      isDisabled: true,
      warningMessage: '',
      code: 'past_business_cutoff',
    };
  }

  // ------------------------------------------------
  // frontend/composable/helpers/utils/inventory-utils.ts:shouldDisableQuantityPicker()
  const { status } = getInventoryInfo(extraProductData);
  if ([InventoryStatus.INACTIVE, InventoryStatus.NOT_FOUND, InventoryStatus.NULL].includes(status)) {
    return {
      isDisabled: true,
      warningMessage: '',
      code: `inventory_status:${status}`,
    };
  }

  return response;
};

export function calculateProductDateCutOff(deliveryDateStr: string, cutOffDayOfWeek: ShamrockCutOffsDaysOfWeek): Date {
  const deliveryDateTimeStr = `${deliveryDateStr}T${cutOffDayOfWeek.cutoffTime}Z`;
  const deliveryDateTime = parseISO(deliveryDateTimeStr);

  const adjustedDate = subDays(deliveryDateTime, Math.abs(cutOffDayOfWeek.leadDays));

  return adjustedDate;
}

export const formatDeliveryDate = (dateString) => {
  const dateParts = dateString.split('-');
  return `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`;
};

/**
 * Formats delivery status text based on order status
 * @param order The order with status information and delivery date
 * @param formatMessage Function to format i18n messages
 * @param deliveryStatusKey Delivery status key
 * @returns String
 */
export const getFormattedDeliveryStatus = (order, formatMessage, deliveryStatusKey) => {
  const isOutForDelivery = order?.status === 'OutForDelivery';
  const isOrderReadyForPickup = order?.status === 'ReadyForPickup';
  const isPickupWithoutScheduledWindow = Boolean(isReadyForPickup(order?.status) && !order?.scheduledDeliveryWindow);

  if (isOutForDelivery || isOrderReadyForPickup) {
    const estimatedDeliveryText = formatMessage({ id: 'orders.delivery.estimated.delivery' });

    if (order.deliveryDate) {
      return `${estimatedDeliveryText}, ${formatDeliveryDate(order.deliveryDate)}`;
    } else {
      return estimatedDeliveryText;
    }
  }

  if (isPickupWithoutScheduledWindow && order?.deliveryDate) {
    if (deliveryStatusKey) {
      return `${formatMessage({ id: deliveryStatusKey })}: ${order.deliveryDate}`;
    }
  }

  return deliveryStatusKey ? formatMessage({ id: deliveryStatusKey }) : '-';
};

/**
 * Parses UTC date and time string to local time string
 * @param dateString must be ISO string (YYYY-MM-DDTh:mm:sZ)
 */
export const parseUTCDatetimeToLocalTime = (dateString: string, timezone: string) => {
  const date = parseISO(dateString);

  const timezoneOffset = getTimezoneOffset(timezone, date);
  const adjustedTime = addMinutes(date, -timezoneOffset / 60000);

  return formatInTimeZone(adjustedTime, timezone, 'h:mm a');
};

/**
 * check if the group of products has disabled line items
 * @param editCart
 * @param ctBusinessUnit
 * @param extraProductData
 * @param isEditOrder
 * @returns boolean
 */
export const editGroupHasDisabledLineItems = (
  editCart: OrderDetail,
  ctBusinessUnit: Partial<AsAssociateBusinessUnitResult>,
  extraProductData: ExtraProductData[],
  isEditOrder: boolean,
) => {
  if (!editCart) {
    return false;
  }

  const skus = extraProductData.map((productData) => productData.productNumber);
  const lineItemsToCheck = editCart.lineItems.filter((cartLineItem) => skus.includes(cartLineItem.productNumber));

  const hasDisabled = lineItemsToCheck.some((lineItem) => {
    const extraData = extraProductData.find((data) => data.productNumber === lineItem.productNumber);
    const shouldDisable = isLineItemDisabled({
      ctBusinessUnit: ctBusinessUnit,
      ctProduct: lineItem,
      extraProductData: extraData,
      editOrder: { editCart, isEditingOrder: isEditOrder },
    });

    return shouldDisable.isDisabled;
  });

  return hasDisabled;
};
