import { FormatMessageParams } from "helpers/hooks";
import { InventoryStatus } from "composable/helpers/utils/inventory-utils";
import { getInventoryStatusMessage } from "composable/helpers/utils/get-inventory-status-message";

const discontinued = 'Discontinued';

export const getObsoleteInventoryMessage = (
  status: InventoryStatus,
  intl: { formatMessage: (args: Omit<FormatMessageParams, 'name'>) => string },
  availableQuantity?: number
): string | null => {
  let statusMessage = getInventoryStatusMessage(status, intl);

  if (statusMessage === discontinued) {
    if (availableQuantity > 0) {
      statusMessage = `${discontinued}: ${availableQuantity} available`;
    }
    return statusMessage;
  }

  return null;
}