import { FunctionComponent, PropsWithChildren } from 'react';
import NextLink, { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import { Box, BoxProps, Link, Text, TextProps, useMediaQuery } from '@chakra-ui/react';
import breakpoints from 'composable/chakra/theme/foundations/breakpoints';

type NameProps = {
  breakpointTokenToDesktop?: keyof typeof breakpoints;
  clickable?: boolean;
  slug?: string;
  onClick?: () => void;
  textProps?: TextProps;
  icon?: JSX.Element;
};

export const Name: FunctionComponent<PropsWithChildren<NameProps>> = ({
  breakpointTokenToDesktop = 'md',
  children,
  clickable,
  slug,
  onClick,
  textProps,
  icon = null,
}) => {
  const router = useRouter();
  const isSuccessPage = router.asPath === '/checkout/success';
  const pathProduct = `/product/${slug}`;
  const nameStr = children?.toString().toLowerCase();

  const [isLargerThan768, isLargerThan1267, isLargerThan1268, isLargerThan1440] = useMediaQuery(
    ['(min-width: 768px)', '(min-width: 1267px)', '(min-width: 1268px)', '(min-width: 1440px)'],
    {
      ssr: true,
      fallback: false,
    },
  );

  const getNoOfLines = () => {
    if (isLargerThan768) {
      if (isLargerThan1267) {
        if (isLargerThan1268) {
          if (isLargerThan1440) {
            return 3;
          }
          return 2;
        }
        return 3;
      }
      return 2;
    }
    return 0;
  };

  const commonStyles = {
    fontSize: { base: 'base', [breakpointTokenToDesktop]: 'lg' },
    color: 'neutral.900',
    textTransform: 'capitalize',
    cursor: 'pointer',
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
    boxSizing: 'border-box',
    fontWeight: { base: 'medium', [breakpointTokenToDesktop]: 'normal' },
    ...(textProps ? textProps : {}),
  } as TextProps;

  if (clickable) {
    const boxLinkStyles = {
      _focusWithin: {
        outlineColor: 'violet.500',
        outlineWidth: '2px',
        outlineStyle: 'solid',
        outlineOffset: '1px',
        boxShadow: 'none',
        borderRadius: '4px',
      },
      ...(clickable && { _hover: { color: isSuccessPage ? 'neutral.900' : 'primary.500' } }),
      ...commonStyles,
    } as BoxProps;

    return (
      <Box {...boxLinkStyles}>
        <Link
          as={NextLink}
          _hover={{ textDecoration: 'none' }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              router.push(pathProduct);
            }
          }}
          passHref
          href={pathProduct}
          onClick={(e) => e.stopPropagation()}
        >
          <Box
            as="a"
            onClick={(e) => {
              e.stopPropagation();
              if (onClick) {
                onClick();
              }
            }}
            noOfLines={getNoOfLines()}
          >
            {icon}
            {nameStr}
          </Box>
        </Link>
      </Box>
    );
  }

  return (
    <Text w="100%" noOfLines={getNoOfLines()} {...commonStyles} cursor="text">
      {icon}
      {nameStr}
    </Text>
  );
};
