import { Flex, Icon, Text, TextProps, Tooltip } from '@chakra-ui/react';
import { CloverIcon } from 'composable/components/product-card-v2/parts/icons/clover-icon';
import { useFormat } from 'helpers/hooks';
import { Gift } from 'phosphor-react';
import NextImage from 'next/image';

type ProductBadgesProps = {
  exclusive?: string;
  isPromotion?: boolean;
  trending?: boolean;
  enableText?: boolean;
  textProps?: TextProps;
  isCarousel?: boolean;
  isLightVariant?: boolean;
  sku: string;
  enableTooltip?: boolean;
  isExclusive?: boolean;
};

export const ProductBadges = ({
  exclusive,
  isPromotion,
  trending,
  enableText,
  textProps,
  isCarousel,
  isLightVariant,
  sku,
  enableTooltip = false,
  isExclusive = false,
}: ProductBadgesProps) => {
  const { formatMessage } = useFormat({ name: 'common' });

  return (
    <>
      {(exclusive || isExclusive) && (
        <Flex gap={1} alignItems="center">
          {enableTooltip && enableText ? (
            <Tooltip
              label={formatMessage({ id: 'pdp.badge.exclusive' })}
              aria-label={formatMessage({ id: 'pdp.badge.exclusive' })}
              fontSize="xs"
              hasArrow
              placement="top"
              borderRadius="2px"
              background="neutral.900"
              arrowShadowColor={'neutral.500'}
              border={`1px solid`}
              borderColor={'neutral.500'}
              {...textProps}
            >
              <Flex>
                <CloverIcon
                  isLightVariant={isLightVariant}
                  w={isCarousel ? '6' : '4'}
                  h={isCarousel ? '6' : '4'}
                  aria-label={formatMessage({ id: 'aria.label.badge.clover', values: { value: sku } })}
                />
              </Flex>
            </Tooltip>
          ) : (
            <>
              <CloverIcon
                isLightVariant={isLightVariant}
                w={isCarousel ? '6' : '4'}
                h={isCarousel ? '6' : '4'}
                aria-label={formatMessage({ id: 'aria.label.badge.clover', values: { value: sku } })}
              />
              {enableText && (
                <Text fontWeight="bold" fontSize="xs" color="neutral.200" {...textProps}>
                  {formatMessage({ id: 'pdp.badge.exclusive' })}
                </Text>
              )}
            </>
          )}
        </Flex>
      )}
      {/* TODO: to be implemented later */}
      {/* <Flex gap={1} alignItems="center">
        <Icon
          as={StarFour}
          color="yellow.500"
          width={isCarousel ? 6 : 4}
          height={isCarousel ? 6 : 4}
          weight="fill"
          aria-label={formatMessage({ id: 'aria.label.badge.star', values: { value: sku } })}
        />
        {enableText && (
          <Text fontWeight="bold" fontSize="xs" color="neutral.200" {...textProps}>
            New
          </Text>
        )}
      </Flex> */}
      {trending && (
        <Flex gap={1} alignItems="center">
          {enableTooltip && enableText ? (
            <Tooltip
              label={formatMessage({ id: 'pdp.badge.trending' })}
              aria-label={formatMessage({ id: 'pdp.badge.trending' })}
              fontSize="xs"
              hasArrow
              placement="top"
              borderRadius="2px"
              background="neutral.900"
              arrowShadowColor={'neutral.500'}
              border={`1px solid`}
              borderColor={'neutral.500'}
              {...textProps}
            >
              <Flex>
                <NextImage
                  width={isCarousel ? 24 : 16}
                  height={isCarousel ? 24 : 16}
                  src={isLightVariant ? `/icons/TrendUp-DarkMode.svg` : `/icons/TrendUp-LightMode.svg`}
                  alt={formatMessage({ id: 'aria.label.badge.trending', values: { value: sku } })}
                  aria-label={formatMessage({ id: 'aria.label.badge.trending', values: { value: sku } })}
                />
              </Flex>
            </Tooltip>
          ) : (
            <>
              <NextImage
                width={isCarousel ? 24 : 16}
                height={isCarousel ? 24 : 16}
                src={isLightVariant ? `/icons/TrendUp-DarkMode.svg` : `/icons/TrendUp-LightMode.svg`}
                alt={formatMessage({ id: 'aria.label.badge.trending', values: { value: sku } })}
                aria-label={formatMessage({ id: 'aria.label.badge.trending', values: { value: sku } })}
              />
              {enableText && (
                <Text fontWeight="bold" fontSize="xs" color="neutral.200" {...textProps}>
                  {formatMessage({ id: 'pdp.badge.trending' })}
                </Text>
              )}
            </>
          )}
        </Flex>
      )}
      {isPromotion && (
        <Flex gap={1} alignItems="center">
          {enableTooltip && enableText ? (
            <Tooltip
              label={formatMessage({ id: 'pdp.badge.rewards' })}
              aria-label={formatMessage({ id: 'pdp.badge.rewards' })}
              fontSize="xs"
              hasArrow
              placement="top"
              borderRadius="2px"
              background="neutral.900"
              arrowShadowColor={'neutral.500'}
              border={`1px solid`}
              borderColor={'neutral.500'}
              {...textProps}
            >
              <Icon
                as={Gift}
                color={isLightVariant ? 'purple.300' : 'purple.500'}
                width={isCarousel ? 6 : 4}
                height={isCarousel ? 6 : 4}
                weight="fill"
                aria-label={formatMessage({ id: 'aria.label.badge.rewards', values: { value: sku } })}
              />
            </Tooltip>
          ) : (
            <>
              <Icon
                as={Gift}
                color={isLightVariant ? 'purple.300' : 'purple.500'}
                width={isCarousel ? 6 : 4}
                height={isCarousel ? 6 : 4}
                weight="fill"
                aria-label={formatMessage({ id: 'aria.label.badge.rewards', values: { value: sku } })}
              />
              {enableText && (
                <Text fontWeight="bold" fontSize="xs" color="neutral.200" {...textProps}>
                  {formatMessage({ id: 'pdp.badge.rewards' })}
                </Text>
              )}
            </>
          )}
        </Flex>
      )}
    </>
  );
};
