import { useEffect, useMemo } from 'react';
import { ExtraProductData } from '@Types/shamrockApi/Product';
import { useGlobal } from 'components/globalProvider';
import { addCartItemException, removeCartItemException } from 'composable/helpers/utils/cart-utils';
import { isNonRefundable } from 'composable/helpers/utils/order-utils';
import { useFormat } from 'helpers/hooks/useFormat';
import { CartItemExceptionType, getCartItemException } from 'utils/cart/exceptions';

interface inventoryProps {
  availability: string;
  availableQuantity: number;
}
// returns the exception message for each item in the cart
// calculates qty > 10 and qty > inventory?.availableQuantity
// example values returned: Quantity exceeds stock on hand. or
// Large Quantity. Please confirm accuracy.

export const useCartItemException = (
  isLoading: boolean,
  sku: string = '',
  quantity: number = 0,
  extraProductData?: ExtraProductData,
) => {
  const companyInfo = extraProductData?.companyInfo?.data;
  const isSurOrJitItem = companyInfo?.isJustInTime || companyInfo?.isShipUponReceipt;
  const { getOriginalLineItemFromOrder, isEditingOrder } = useGlobal().useEditOrderGlobal;

  const { formatMessage } = useFormat({ name: 'common' });

  const fetchErrorPerItem = useMemo(() => {
    if (!extraProductData) {
      return;
    }

    const cartItemException = getCartItemException({ quantity, extraProductData });

    switch (cartItemException) {
      case CartItemExceptionType.ORDER_LIMIT:
        return formatMessage({
          id: 'cart.product.limit',
        });
      case CartItemExceptionType.ON_HAND_QUANTITY:
        return formatMessage({
          id: 'cart.product.qty.exceeds',
          values: { stock: Math.floor(extraProductData.inventoryInfo?.data?.availableQuantity || 0) },
        });
      case CartItemExceptionType.LARGE_QUANTITY:
        return formatMessage({
          id: 'cart.product.large.qty',
        });
      default:
        return;
    }
  }, [quantity, extraProductData]);

  // set number of exceptions in localStorage
  const handleAddCartItemException = () => {
    if (isEditingOrder && isSurOrJitItem) {
      const isOriginalOrder = getOriginalLineItemFromOrder(sku);
      if (isOriginalOrder && isNonRefundable(isOriginalOrder?.justInTimeEditCutoff)) {
        return;
      }
    }

    addCartItemException(sku);
  };

  useEffect(() => {
    const setExceptions = () => {
      // exception exists
      const cartItemException = getCartItemException({ quantity, extraProductData });
      if (cartItemException) {
        handleAddCartItemException();
      }
      // no expcetions on the product
      else {
        removeCartItemException(sku);
      }
    };

    // calling setExceptions
    if (sku != '' && quantity > 0 && !isLoading) {
      setExceptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extraProductData, isLoading, quantity, sku]);

  return fetchErrorPerItem;
};
