import { IncomingMessage, ServerResponse } from 'http';
import cookieCutter from 'cookie-cutter';
import ServerCookies from 'cookies';
import { SESSION_PERSISTENCE } from 'helpers/constants/auth';
import { REMEMBER_ME } from 'helpers/constants/localStorage';
import { Log } from 'helpers/errorLogger';
import pRetry from 'p-retry';
import { handleShamrockErrors } from './utils/handle-shamrock-errors';
import { mapLanguage } from '../../project.config';

export class LocaleStorage {
  static locale = '';
}

function resolveApiHubUrl(): string {
  if (process.env['NEXT_PUBLIC_FRONTASTIC_HOST'] === undefined) {
    throw new Error(`Env variable "NEXT_PUBLIC_FRONTASTIC_HOST" not set`);
  }
  const apiHubUrl = process.env.NEXT_PUBLIC_FRONTASTIC_HOST;
  /*
  if (process.env.NEXT_PUBLIC_VERCEL_ENV! === 'preview') {
    // FIXME: Get project & customer ID from configuration
    apiHubUrl =
      'https://<project>-' +
      process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF!.replace(/[^a-zA-Z0-9-]/g, '-') +
      '-<customer>.frontastic.dev/frontastic';
  }
  */
  return apiHubUrl;
}

type ExpressMessages = {
  req: IncomingMessage;
  res: ServerResponse;
};

type CookieManager = {
  getCookie: (cookieIdentifier: string) => string | undefined;
  setCookie: (cookieIdentifier: string, cookieValue: string) => void;
};

export class ResponseError extends Error {
  private readonly response: Response;

  constructor(response: Response) {
    super(`Got HTTP status code ${response.status} (${response.statusText})`);
    this.response = response;
  }

  getResponse() {
    return this.response;
  }

  getStatus() {
    return this.response.status;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FetchFunction = (
  endpointPath: string,
  init?: RequestInit,
  payload?: object,
  retries?: number,
  withRequestId?: boolean,
) => Promise<any>;

const performFetchApiHub = async (
  endpointPath: string,
  init: RequestInit,
  payload: object = null,
  cookieManager: CookieManager,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const frontasticSessionHeaders = {};

  const frontasticSessionCookie = cookieManager.getCookie('frontastic-session');
  if (frontasticSessionCookie) {
    frontasticSessionHeaders['Frontastic-Session'] = frontasticSessionCookie;
  }

  const bodyOverride = payload ? { body: JSON.stringify(payload) } : {};

  const actualInit = {
    ...bodyOverride,
    ...init,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...(init.headers || {}),
      'Commercetools-Frontend-Extension-Version': process.env.NEXT_PUBLIC_EXT_BUILD_ID ?? 'dev',
      ...frontasticSessionHeaders,
      'Frontastic-Locale': mapLanguage(LocaleStorage.locale),
      'CoFe-Custom-Configuration': JSON.stringify({ env: process.env.NEXT_PUBLIC_APPLICATION_ENV }),
    },
  };

  const endpoint = resolveApiHubUrl() + endpointPath;

  return await fetch(endpoint, actualInit).then((response): Response => {
    if (response.ok && response.headers.has('Frontastic-Session')) {
      cookieManager.setCookie('frontastic-session', response.headers.get('Frontastic-Session'));
    }
    return response;
  });
};

export const rawFetchApiHub: FetchFunction = async (endpointPath, init = {}, payload = null) => {
  return await performFetchApiHub(endpointPath, init, payload, {
    getCookie: (cookieIdenfier) => {
      return cookieCutter.get(cookieIdenfier);
    },
    setCookie: (cookieIdenfier, cookieValue) => {
      const expiryDate = window.localStorage.getItem(REMEMBER_ME)
        ? new Date(Date.now() + SESSION_PERSISTENCE)
        : 'Session';
      cookieCutter.set(cookieIdenfier, cookieValue, { path: '/', expires: expiryDate });
    },
  });
};

export const handleApiHubResponse = (
  fetchApiHubPromise: Promise<Response | ResponseError>,
  withRequestId?: boolean,
): Promise<object> => {
  return fetchApiHubPromise
    .then(async (response: Response) => {
      if (response.ok) {
        const json = await response.json();
        if (!withRequestId) {
          return json;
        }
        return {
          ...json,
          frontasticRequestid: response.headers.get('frontastic-request-id'),
        };
      }
      throw new ResponseError(response);
    })
    .catch(async (err: ResponseError) => {
      const status = err?.getStatus?.();
      if (status === 404) {
        return err;
      }
      if (err && err.getResponse) {
        const response = err.getResponse();
        let error: object | string = await response.text();
        try {
          error = JSON.parse(error);
        } catch (e) {
          // not a JSON response
        }
        // For conflict requests (like user trying to submit different cartVersion than the latest)
        // we avoid the errorLogger, but throw 409 error so we can catch it and handle it on the frontend
        if (status === 409) {
          const errorObject = typeof error === 'object' ? error : { message: error };
          throw new Error(JSON.stringify({ ...errorObject, status }));
        }
        Log.warn(error);
        await handleShamrockErrors(error, err);
        throw new Error(JSON.stringify(error));
      } else {
        Log.error('Network error: ' + err);
        throw new Error('Network error: ' + err);
      }
    })
    .then((response) => {
      if (response?.error) {
        throw new Error(response.errorCode);
      }
      return response;
    });
};

export const fetchApiHub: FetchFunction = async (
  endpointPath,
  init = {},
  payload = null,
  retries = 0,
  withRequestId = false,
) => {
  return await pRetry(() => handleApiHubResponse(rawFetchApiHub(endpointPath, init, payload), withRequestId), {
    retries,
    minTimeout: 100,
  }).catch((e) => {
    throw e;
  });
};

export const rawFetchApiHubServerSide = async (
  endpointPath: string,
  expressMessages: ExpressMessages,
  headers: HeadersInit = [],
) => {
  const cookies = new ServerCookies(expressMessages.req, expressMessages.res);
  return await performFetchApiHub(endpointPath, { method: headers['method'] ?? 'GET', headers }, null, {
    getCookie: (cookieIdentifier) => {
      return cookies.get(cookieIdentifier);
    },
    setCookie: () => {
      // Do nothing. Only actions are eligible to set the session.
    },
  });
};

export const fetchApiHubServerSide = async (
  endpointPath: string,
  expressMessages: ExpressMessages,
  headers: HeadersInit = [],
) => {
  return handleApiHubResponse(rawFetchApiHubServerSide(endpointPath, expressMessages, headers));
};
