import { Container, ContainerProps } from '@chakra-ui/react';
import { LAYOUT_VALUES } from 'composable/components/pdp/constants';

interface ContentContainerProps {
  children: React.ReactElement | React.ReactElement[];
  containerProps?: ContainerProps;
  extend?: boolean;
}

export const ContentContainer = ({ containerProps, children, extend = false }: ContentContainerProps) => {
  const { PADDING_LEFT, PADDING_RIGHT } = LAYOUT_VALUES;

  return (
    <Container
      maxW="container.full"
      pr={extend ? 0 : PADDING_RIGHT}
      pl={PADDING_LEFT}
      m={0}
      justifyContent="flex-start"
      {...containerProps}
    >
      {children}
    </Container>
  );
};
