import { useEffect, useRef, useState, forwardRef } from 'react';
import {
  BoxProps,
  ButtonProps,
  FlexProps,
  HStack,
  IconProps,
  NumberInputFieldProps,
  StackProps,
  Text,
} from '@chakra-ui/react';
import { useGlobal } from 'components/globalProvider';
import { analyticsTrackCart } from 'composable/analytics/analytics-event-tracking';
import { QUANTITY_PICKER_DEBOUNCE_TIME } from 'composable/components/general';
import { QuantityPicker, QuantityPickerRef } from 'composable/components/quantity-picker';
import { useOrderLineItemUpdater } from 'composable/helpers/hooks/orders/useOrderLineItemUpdater';
import { InventoryStatus } from 'composable/helpers/utils/inventory-utils';
import { LIST_ID_CATALOG, LIST_NAME_CATALOG } from 'helpers/constants/eventTracking';
import { useFormat } from 'helpers/hooks/useFormat';
import { cartItemsAnalyticsManager } from 'helpers/utils/productsAnalyticsManager';

type QuantityProps = {
  isDisabled?: boolean;
  isLoading?: boolean;
  isReadonly?: boolean;
  status?: InventoryStatus;
  sku: string;
  productName?: string;
  price: number;
  min?: number;
  orderQuantity?: number;
  title?: string;
  stackProps?: StackProps;
  qtyButtonProps?: object;
  size?: string;
  variant?: string;
  _container?: BoxProps;
  _numberInputField?: object;
  hideLabel?: boolean;
  showAddToCartButton?: boolean;
  index?: number;
  page?: string;
  productCategories?: string[];
  analyticEvent?: { listId: string; listName: string };
  onQuantityUpdate?: (quantity: number) => void; // if overwriteUpdateQuantity is defined it will be executed instead of addOrUpdateLineItemToCart
  showAddToOrderGuide?: boolean;
  onAddToOrderGuide?: () => void;
  buttonProps?: ButtonProps;
  qtyIconProps?: IconProps;
  darkVariant?: boolean;
  numberInputFieldProps?: NumberInputFieldProps;
  addToCartProps?: ButtonProps;
  quantityPickerProps?: FlexProps;
  onAddToCart?: (quantity: number) => void;
  component: string;
  isError?: boolean;
  errorMessage?: string;
  errorMessageInside?: boolean;
  forceShowQuantityPicker?: boolean;
  onQuantityChange?: (quantity: number) => void;
  onFocusChange?: (isFocused: boolean) => void;
  onNavigate?: (direction: 'up' | 'down') => void;
  lastProductIndex?: number;
  mappedIndex?: number | null;
  isCardFocused?: boolean;
  focusListKey?: string;
  cartPageOnRemove?: (() => void) | null;
  isCarousel?: boolean;
};

type QuantityUpdate = {
  totalUpdateRequests: number;
  lastQuantityDetected: number;
  showLastQuantityDetected?: boolean;
};

const MAXQUANTITY = 999;

export const Quantity = forwardRef<QuantityPickerRef, QuantityProps>(
  (
    {
      isDisabled,
      isReadonly,
      sku,
      productName,
      price,
      min = 0,
      orderQuantity,
      title,
      stackProps,
      qtyButtonProps,
      size,
      variant,
      _container,
      _numberInputField,
      hideLabel,
      index,
      productCategories,
      analyticEvent,
      showAddToOrderGuide,
      onAddToOrderGuide,
      onQuantityUpdate,
      buttonProps,
      qtyIconProps,
      darkVariant,
      numberInputFieldProps,
      addToCartProps,
      quantityPickerProps,
      onAddToCart,
      component,
      page,
      isError,
      errorMessage,
      errorMessageInside = false,
      forceShowQuantityPicker = false,
      onQuantityChange,
      onFocusChange,
      onNavigate,
      lastProductIndex,
      mappedIndex = null,
      focusListKey = null,
      cartPageOnRemove = null,
      isCarousel = false,
    },
    ref,
  ) => {
    const { formatMessage } = useFormat({ name: 'common' });

    const { getLineItemQuantity, updateLineItemQuantity } = useOrderLineItemUpdater({ sku });

    const delayedAction = useRef<NodeJS.Timeout | null>(null);

    const cartQuantity = getLineItemQuantity();
    const [tempQuantity, setTempQuantity] = useState(cartQuantity);
    const [, setShowQuantityPicker] = useState(forceShowQuantityPicker || tempQuantity > 0);
    const [canBlurInput, setCanBlurInput] = useState(false);
    const { isOffline } = useGlobal();

    const [quantityUpdate, setQuantityUpdate] = useState<QuantityUpdate>({
      totalUpdateRequests: 0,
      lastQuantityDetected: 0,
    });

    useEffect(() => {
      if (!isOffline) {
        setTempQuantity(cartQuantity);
      }
    }, [cartQuantity, isOffline]);

    const handleChangeQuantity = async (
      sku: string,
      quantity: number,
      price: number,
      productName: string,
      productCategories: string[],
      index: number,
    ) => {
      try {
        // if overwriteUpdateQuantity is defined it will be executed instead of addOrUpdateLineItemToCart
        if (onQuantityUpdate) {
          onQuantityUpdate(quantity);
          return;
        }

        const categories = productCategories;
        const { setCartData } = cartItemsAnalyticsManager();
        setCartData(
          {
            productId: sku,
            categories,
            index,
            itemListId: analyticEvent?.listId || LIST_ID_CATALOG,
            itemListName: analyticEvent?.listName || LIST_NAME_CATALOG,
          },
          'add',
        );
        const res = await updateLineItemQuantity({ price, productName, quantity });

        if (res && res?.success) {
          if (quantity > 0) {
            try {
              analyticsTrackCart({ ...res, fromPAR: false });
            } catch (err) {
              console.info('Analytics unable to track cart', err);
            }
          }
        }

        if (res && typeof res.success !== 'undefined' && res.success === false) {
          setTempQuantity(res.prevQuantity);
        }

        setQuantityUpdate((prev) => ({ ...prev, totalUpdateRequests: prev.totalUpdateRequests - 1 }));

        onAddToCart?.(quantity);
      } catch (error: any) {
        setTempQuantity(quantity - 1);

        setQuantityUpdate((prev) => ({ ...prev, totalUpdateRequests: prev.totalUpdateRequests - 1 }));

        console.error('Error occured when adding item to cart', error);
      }
    };

    const handleDelayedChange = (
      sku: string,
      qtyValue: number,
      price: number,
      productName: string,
      productCategories: string[],
      index: number,
    ) => {
      setTempQuantity(qtyValue);

      clearTimeout(delayedAction.current);

      // if initial add to cart don't delay the call
      if (qtyValue === 1) {
        handleChangeQuantity(sku, qtyValue, price, productName, productCategories, index);
        return;
      }

      delayedAction.current = setTimeout(() => {
        handleChangeQuantity(sku, qtyValue, price, productName, productCategories, index);
      }, QUANTITY_PICKER_DEBOUNCE_TIME);
    };

    useEffect(() => {
      setShowQuantityPicker(forceShowQuantityPicker || tempQuantity > 0);
    }, [forceShowQuantityPicker, tempQuantity]);

    const handleQuantityChange = (qtyValue: number) => {
      if (qtyValue === tempQuantity) {
        return;
      }

      setQuantityUpdate((prev) => ({
        totalUpdateRequests: prev.totalUpdateRequests + 1,
        lastQuantityDetected: qtyValue,
      }));

      setTempQuantity(qtyValue);
      handleDelayedChange(sku, qtyValue, price, productName, productCategories, index);
      if (onQuantityChange) {
        onQuantityChange(qtyValue);
      }
    };

    const handleBlur = () => {
      if (tempQuantity === 0) {
        setShowQuantityPicker(false);
      }
    };

    useEffect(() => {
      if (forceShowQuantityPicker) {
        setShowQuantityPicker(true);
      }
    }, [forceShowQuantityPicker]);

    useEffect(() => {
      if (canBlurInput) {
        if (tempQuantity === 0 && tempQuantity === cartQuantity) {
          const currentActiveElement = document.activeElement;
          if (currentActiveElement) {
            (currentActiveElement as HTMLElement).blur();
            setCanBlurInput(false);
          }
        }
      }
    }, [tempQuantity, cartQuantity, canBlurInput]);

    if (isReadonly) {
      return (
        <HStack justify="space-between" spacing={1} w="full" {...stackProps}>
          <Text color="neutral.900" fontSize="sm" lineHeight="21px" fontWeight="medium">
            {title ? title : formatMessage({ id: 'productCard.quantity' })}
          </Text>
          <Text color="neutral.900" fontSize="sm" lineHeight="21px" fontWeight="medium">
            {orderQuantity ? orderQuantity : cartQuantity}
          </Text>
        </HStack>
      );
    }

    return (
      <QuantityPicker
        darkVariant={darkVariant}
        min={min}
        max={MAXQUANTITY}
        disabled={isDisabled}
        size={size}
        variant={variant}
        _container={_container}
        _numberInputField={_numberInputField}
        hideLabel={hideLabel}
        value={quantityUpdate.totalUpdateRequests > 0 ? quantityUpdate.lastQuantityDetected : tempQuantity}
        onChange={handleQuantityChange}
        onBlur={handleBlur}
        isLoading={false}
        qtyButtonProps={qtyButtonProps}
        buttonProps={buttonProps}
        showAddButton={
          quantityUpdate.totalUpdateRequests > 0
            ? quantityUpdate.lastQuantityDetected === 0
            : !forceShowQuantityPicker && tempQuantity === 0
        }
        sku={sku}
        showAddToOrderGuide={showAddToOrderGuide}
        onAddToOrderGuide={onAddToOrderGuide}
        qtyIconProps={qtyIconProps}
        rootProps={stackProps}
        numberInputFieldProps={numberInputFieldProps}
        addToCartProps={addToCartProps}
        quantityPickerProps={quantityPickerProps}
        index={index}
        page={page}
        component={component}
        isError={isError}
        errorMessage={errorMessage}
        errorMessageInside={errorMessageInside}
        onFocusChange={onFocusChange}
        onNavigate={onNavigate}
        ref={ref}
        lastProductIndex={lastProductIndex}
        mappedIndex={mappedIndex}
        focusListKey={focusListKey}
        setCanBlurInput={setCanBlurInput}
        cartPageOnRemove={cartPageOnRemove}
        isCarousel={isCarousel}
      />
    );
  },
);

Quantity.displayName = 'Quantity';
