import { Box, Flex, HStack, Skeleton, SkeletonCircle, SkeletonProps, Text, VStack } from '@chakra-ui/react';
import { Product } from '@Types/product/Product';
import { getProductDetails } from 'composable/components/product-card-v2/helpers';
import { HitWithExtraData } from 'composable/components/qc-search/type';

type QuickCatalogProductSkeletonProps = {
  index: number;
  product: HitWithExtraData;
  isLastCard?: boolean;
  showAddToOGButton?: boolean;
};

const commonSkeletonProps: SkeletonProps = {
  borderRadius: 2,
  color: 'neutral.300',
  opacity: '50%',
};

export const QuickCatalogProductSkeleton = ({
  index,
  product,
  isLastCard = false,
  showAddToOGButton = true,
}: QuickCatalogProductSkeletonProps) => {
  const { productName, brand, sku, packSize, storage, description } = getProductDetails(
    product as unknown as Product,
    product.extraData,
  );

  return (
    <Flex
      data-testid={`qc-product-skeleton-${index}`}
      flexDirection={{ base: 'column', lg: 'row' }}
      paddingBottom={2}
      marginBottom={!isLastCard && 2}
      gap={2}
      borderBottomWidth={1}
      borderColor="neutral.200"
    >
      <Flex flexDirection={{ base: 'column', lg: 'row' }} flex={{ lg: 1 }}>
        <Box display={{ base: 'none', lg: 'flex' }} marginRight={3}>
          <Skeleton width={12} height={12} {...commonSkeletonProps} />
        </Box>
        <Box width="100%" display="flex" flexDirection="column" gap={1}>
          <HStack gap={2}>
            {[brand, sku, packSize].map((info, index) => (
              <Text
                key={`qc_product_info_${index}`}
                fontSize="xs"
                fontWeight="normal"
                lineHeight="3.9"
                color="neutral.600"
              >
                {info}
              </Text>
            ))}
          </HStack>
          <Text fontSize="sm" fontWeight="semibold" lineHeight="4.5" color="neutral.900">
            {productName}
          </Text>
          <Flex flexDirection="column">
            <Text fontSize="xs" fontWeight="medium" lineHeight="3.9" color="neutral.900">
              {storage}
            </Text>
            <Text fontSize="xs" fontWeight="normal" lineHeight="3.9" color="neutral.900">
              {description && `${description.charAt(0).toLocaleUpperCase()}${description.slice(1).toLocaleLowerCase()}`}
            </Text>
          </Flex>
          <Box display="flex" flexDirection="column" gap={1 / 2}>
            <Skeleton width="275px" height={3.5} {...commonSkeletonProps} />
            <Skeleton width="88px" height={3.5} {...commonSkeletonProps} />
          </Box>
        </Box>
        <Box marginTop={{ base: 2, lg: 0 }} width="fit-content">
          <HStack gap={3}>
            {[1, 2, 3, 4].map((badge) => (
              <Skeleton key={`qc_skeleton_badge_${badge}`} width={4} height={4} {...commonSkeletonProps} />
            ))}
          </HStack>
        </Box>
      </Flex>
      <Flex
        flexDirection="column"
        paddingTop={{ base: 2, lg: 0 }}
        paddingLeft={{ lg: 2 }}
        borderLeftWidth={{ lg: 1 }}
        borderTopWidth={{ base: 1, lg: 0 }}
        borderColor="neutral.50"
      >
        <Flex width={{ base: '100%', lg: '283px' }} justify="space-between">
          <Box>
            <Flex alignItems="center" gap={1} marginBottom={1 / 2}>
              <SkeletonCircle size="9px" {...commonSkeletonProps} />
              <Skeleton width="43px" height="17px" {...commonSkeletonProps} />
            </Flex>
            <Skeleton width="70px" height={3.5} {...commonSkeletonProps} />
          </Box>
          <Flex gap={3}>
            <Skeleton width={27} height={8} {...commonSkeletonProps} />
            {showAddToOGButton && <Skeleton width={8} height={8} {...commonSkeletonProps} />}
          </Flex>
        </Flex>
        <VStack width="100%" marginTop={2} gap={1 / 2} alignItems="flex-start">
          <Skeleton width="106px" height={3.5} {...commonSkeletonProps} />
          <Skeleton width="51px" height={3.5} {...commonSkeletonProps} />
          <Skeleton width="156px" height={3.5} {...commonSkeletonProps} />
        </VStack>
      </Flex>
    </Flex>
  );
};
