import { HitWithExtraData } from 'composable/components/qc-search/type';

export enum QuickCatalogContents {
  CATALOG = 'CATALOG',
  ADD_TO_OG = 'ADD_TO_OG',
}

export type GlobalSearchState = {
  inputValue: string;
  debouncedInputValue: string;
  activeElement: Element | null;
  isQuickCatalogOpen: boolean;
  shouldOpenDropdown: boolean;
  quickCatalogContent: QuickCatalogContents;
  addToOGProduct: HitWithExtraData | null;
};

export enum GlobalSearchStateActions {
  SET_INPUT_VALUE = 'SET_INPUT_VALUE',
  SET_DEBOUNCED_INPUT_VALUE = 'SET_DEBOUNCED_INPUT_VALUE',
  SET_ACTIVE_ELEMENT = 'SET_ACTIVE_ELEMENT',
  SET_DROPDOWN_OPEN = 'SET_DROPDOWN_OPEN',
  OPEN_QUICK_CATALOG = 'OPEN_QUICK_CATALOG',
  CLOSE_QUICK_CATALOG = 'CLOSE_QUICK_CATALOG',
  SWIPE_TO_ADD_TO_OG = 'SWIPE_TO_ADD_TO_OG',
  SWIPE_TO_QUICK_CATALOG = 'SWIPE_TO_QUICK_CATALOG',
}

export type GlobalSearchStateAction = {
  type: GlobalSearchStateActions;
  payload?: Partial<GlobalSearchState>;
};

export const globalSearchReducer = (state: GlobalSearchState, action: GlobalSearchStateAction): GlobalSearchState => {
  switch (action.type) {
    case GlobalSearchStateActions.SET_INPUT_VALUE:
      return {
        ...state,
        inputValue: action?.payload?.inputValue ?? state.inputValue,
      };
    case GlobalSearchStateActions.SET_DEBOUNCED_INPUT_VALUE:
      return {
        ...state,
        debouncedInputValue: action?.payload?.debouncedInputValue ?? state.debouncedInputValue,
      };
    case GlobalSearchStateActions.SET_ACTIVE_ELEMENT:
      return {
        ...state,
        activeElement: action?.payload?.activeElement ?? state.activeElement,
      };
    case GlobalSearchStateActions.SET_DROPDOWN_OPEN:
      return {
        ...state,
        shouldOpenDropdown: action?.payload?.shouldOpenDropdown ?? state.shouldOpenDropdown,
      };
    case GlobalSearchStateActions.OPEN_QUICK_CATALOG:
      return {
        ...state,
        isQuickCatalogOpen: true,
        shouldOpenDropdown: false,
      };
    case GlobalSearchStateActions.CLOSE_QUICK_CATALOG:
      return {
        ...state,
        isQuickCatalogOpen: false,
        quickCatalogContent: QuickCatalogContents.CATALOG,
      };
    case GlobalSearchStateActions.SWIPE_TO_ADD_TO_OG:
      return {
        ...state,
        quickCatalogContent: QuickCatalogContents.ADD_TO_OG,
        addToOGProduct: action?.payload?.addToOGProduct ?? null,
      };
    case GlobalSearchStateActions.SWIPE_TO_QUICK_CATALOG:
      return {
        ...state,
        quickCatalogContent: QuickCatalogContents.CATALOG,
        addToOGProduct: null,
      };
    default:
      return state;
  }
};
