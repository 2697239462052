import { FunctionComponent } from 'react';
import { Box, HStack, StackProps } from '@chakra-ui/react';
import { ExtraProductData } from '@Types/shamrockApi/Product';
import { QuantityComponent } from 'composable/components/quantity-picker';
import { PageVariant } from 'composable/components/types';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import * as Parts from './parts';
import { Price } from '../price';

type CheckoutVariantProps = {
  brand: string;
  name: string;
  id: string;
  packSize: string;
  currentPrice: number;
  discountedPrice: number;
  productPrice: number;
  sku: string;
  quantityFromOrder?: number;
  priceClusterProps?: StackProps;
  extraProductData: ExtraProductData;
  removeQuantity?: boolean;
  index?: number;
  lastProductIndex?: number;
};

export const CheckoutVariant: FunctionComponent<CheckoutVariantProps> = ({
  brand,
  name,
  id,
  packSize,
  currentPrice,
  discountedPrice,
  productPrice,
  sku,
  quantityFromOrder,
  priceClusterProps,
  removeQuantity,
  index,
  lastProductIndex,
}) => {
  const hasSpecialPrice = discountedPrice && discountedPrice > 0 && currentPrice !== discountedPrice ? true : false;
  const { formatMessage } = useFormat({ name: 'common' });

  return (
    <Parts.Card flexDirection={{ base: 'column', lg: 'row' }} className="product-card-checkout">
      <Parts.Info breakpointTokenToDesktop="lg">
        <Parts.Brand breakpointTokenToDesktop="lg">{brand}</Parts.Brand>
        <Parts.Name breakpointTokenToDesktop="lg">{name}</Parts.Name>
        <HStack alignItems="center" spacing={2}>
          <Parts.Id breakpointTokenToDesktop="lg">{id}</Parts.Id>
          <Parts.PackSize breakpointTokenToDesktop="lg">{packSize}</Parts.PackSize>
        </HStack>
      </Parts.Info>
      <Parts.PriceCluster
        align={{ base: 'center', lg: 'unset' }}
        breakpointTokenToDesktop="lg"
        direction={'column'}
        w={{ base: 'unset', lg: '216px' }}
        {...priceClusterProps}
      >
        <Box width="100%">
          <Price
            priceProps={{
              fontWeight: 'bold',
              fontSize: { base: 'base', lg: 'lg' },
              lineHeight: { base: '19.2px', lg: '24px' },
              color: 'yellow.600',
            }}
            specialPriceProps={{
              fontWeight: 'normal',
              fontSize: 'base',
              textDecoration: 'line-through',
              lineHeight: '19.2px',
              color: 'neutral.600',
              pt: '4px',
            }}
            regularPrice={currentPrice ? CurrencyHelpers.formatForCurrency(currentPrice) : '$0.00'}
            specialPrice={
              hasSpecialPrice
                ? CurrencyHelpers.formatForCurrency(discountedPrice)
                : CurrencyHelpers.formatForCurrency(currentPrice)
            }
            pageVariant={PageVariant.Checkout}
          />
        </Box>
        {!removeQuantity && (
          <Parts.Quantity
            component={QuantityComponent.CHECKOUT_VARIANT}
            isReadonly
            price={productPrice}
            sku={sku}
            orderQuantity={quantityFromOrder}
            title={formatMessage({ id: 'editOrder.cases' })}
            index={index}
            lastProductIndex={lastProductIndex}
          />
        )}
      </Parts.PriceCluster>
    </Parts.Card>
  );
};
