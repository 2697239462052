export const GOOGLE_ANALYTICS_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID;

export const GA_TRACK_CONSTANTS = {
  ITEM_LIST_ID_CAROUSEL: 'Carousel',
  ITEM_LIST_ID_CATALOG: 'catalog',
  ITEM_LIST_NAME_SIMILAR_ITEMS: 'Similar Items',
  ITEM_LIST_NAME_YOU_MAY_ALSO_LIKE: 'You May Also Like',
  ITEM_LIST_QUICK_CATALOG: 'Quick Catalog',
  ITEM_ORIGIN_MAP: 'item-origin-map',
  LAST_ITEM_ORIGIN: 'last_item_origin',
  CAROUSEL_LIST_VIEW: 'carousel_list_view',
};
