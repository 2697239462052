import { Dispatch } from 'react';
import { SoftCutoffsResponse } from 'helpers/services/shamrock';
import { UserState } from '../use_privateUserGlobal/types';

export type CutoffsState = {
  softCutoffs?: SoftCutoffsResponse;
  extendedCutoff?: string;
  loading: boolean;
};

export enum CutoffsGlobalStateActions {
  SET_INITIAL = 'SET_INITIAL',
  SET_SOFT_CUTOFFS = 'SET_SOFT_CUTOFFS',
  SET_EXTENDED_CUTOFFS = 'SET_EXTENDED_CUTOFFS',
  SET_LOADING = 'SET_LOADING',
}

export type UseCutoffsGlobalParams = {
  isPublic?: boolean;
  user?: UserState;
};

export type CutoffsGlobalActions = {
  type: CutoffsGlobalStateActions;
  payload?: Partial<CutoffsState>;
};

export type UseCutoffsGlobalResponse = {
  softCutoffs?: SoftCutoffsResponse;
  extendedCutoff?: string;
  loading: boolean;
  dispatch: Dispatch<CutoffsGlobalActions>;
};
