import { AsAssociateBusinessUnitResult } from '@Types/business-unit/BusinessUnit';
import { LineItem } from '@Types/cart/LineItem';
import { OrderDetailLineItem } from '@Types/shamrockApi/Order';
import { ExtraProductData } from '@Types/shamrockApi/Product';
import { getDeliveryCutoff, shouldIgnoreCutoff } from 'composable/components/product-card-v2/helpers';
import { isAfter } from 'date-fns/isAfter';
import { RemainingDays } from '../hooks/useGetDeliveryDates';

export function findFurthestDates(
  productsWithLaterDeliveryDate: ExtraProductData[],
  selectedBusinessUnit: Partial<AsAssociateBusinessUnitResult>,
  businessDeliveryDates: { dates: Date[]; orderBy: RemainingDays },
  lineItems: (LineItem | OrderDetailLineItem)[],
) {
  const initialDate = new Date(0); // Represents the earliest date possible in JavaScript

  const { furthestCutoffDate, furthestDeliveryDate } = productsWithLaterDeliveryDate.reduce(
    (acc, product) => {
      const { cutoffDate, deliveryDay } = getDeliveryCutoff(
        product,
        selectedBusinessUnit,
        businessDeliveryDates?.dates[0],
      );

      const cartItem = lineItems?.find(
        (item) =>
          (item as LineItem).variant?.sku === product?.productNumber ||
          (item as OrderDetailLineItem).productNumber === product?.productNumber,
      );
      const ignoreCutOff = shouldIgnoreCutoff(product, cartItem);

      if (ignoreCutOff || !cutoffDate || !deliveryDay) {
        acc.furthestCutoffDate = businessDeliveryDates?.orderBy.orderByDate;
        acc.furthestDeliveryDate = businessDeliveryDates?.dates[0];
        return acc;
      }

      if (isAfter(cutoffDate, acc.furthestCutoffDate)) {
        acc.furthestCutoffDate = cutoffDate;
      }

      if (isAfter(deliveryDay, acc.furthestDeliveryDate)) {
        acc.furthestDeliveryDate = deliveryDay;
      }

      return acc;
    },
    { furthestCutoffDate: initialDate, furthestDeliveryDate: initialDate },
  );

  return { furthestCutoffDate, furthestDeliveryDate };
}
