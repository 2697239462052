import NextImage from 'next/image';
import { Flex, FlexProps, Tooltip } from '@chakra-ui/react';
import { ExtraProductData } from '@Types/shamrockApi/Product';
import { getInventoryMessageWithDueDate } from 'composable/helpers/utils/get-inventory-message-with-due-date';
import { getObsoleteInventoryMessage } from 'composable/helpers/utils/get-obsolete-inventory-message';
import { InventoryStatus, getInventoryStatusIcon } from 'composable/helpers/utils/inventory-utils';
import { useFormat } from 'helpers/hooks/useFormat';

type BulletProps = Pick<ExtraProductData, 'nextDeliveryDueDates'> & {
  sku: string;
  status: InventoryStatus;
  flexProps?: FlexProps;
  availableQuantity?: number;
};

export const Bullet = ({ nextDeliveryDueDates, sku, status, availableQuantity, flexProps }: BulletProps) => {
  const bulletStatus = getInventoryStatusIcon(status);
  const { formatMessage } = useFormat({ name: 'common' });
  const intl = useFormat({ name: 'common' });
  const { dueDate, status: statusMessage } = getInventoryMessageWithDueDate(
    status,
    intl,
    nextDeliveryDueDates,
    availableQuantity,
  );
  const obsoleteMessage = getObsoleteInventoryMessage(status, intl, availableQuantity);

  if (!bulletStatus) {
    return null;
  }

  return (
    <Tooltip
      hasArrow
      placement="top"
      borderRadius="2px"
      color="neutral.200"
      background="neutral.900"
      label={
        <>
          {obsoleteMessage ?? statusMessage}
          <br />
          {dueDate}
        </>
      }
      aria-label={`${statusMessage} ${dueDate}`}
    >
      <Flex {...flexProps}>
        <NextImage
          src={`/icons/bullets/${bulletStatus}`}
          alt={`${formatMessage({ id: 'order.guide.bullet.iventory.status' })} ${sku}: ${bulletStatus}`}
          height={9}
          width={9}
          style={{ borderRadius: '50%' }}
        />
      </Flex>
    </Tooltip>
  );
};
