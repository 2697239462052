import { useGlobal } from 'components/globalProvider';
import { HitWithExtraData } from 'composable/components/qc-search/type';
import { useCartItemException, useOrderLineItemUpdater } from 'composable/helpers/hooks';
import { isLineItemDisabled } from 'composable/helpers/utils/order-utils';
import { useLocaleCode } from 'helpers/hooks/useLocaleCode';

export const useQuickCatalogPickerHelpers = (product: HitWithExtraData) => {
  const localeCode = useLocaleCode();
  const productCategories = product?.categoryKeys?.[localeCode];

  const { getLineItemQuantity } = useOrderLineItemUpdater({ sku: product.sku });
  const quantity = getLineItemQuantity();

  const perItemErrorMessage = useCartItemException(false, product.sku, quantity, product.extraData);

  const { state: user } = useGlobal().useUserGlobal;
  const { isEditingOrder, editCart } = useGlobal().useEditOrderGlobal;
  const { activeAccount } = user;
  const selectedBusinessUnit = activeAccount;

  let shouldDisable = false;
  let warningMessage = null;

  if (isEditingOrder) {
    const lineItemInEditOrder = editCart.lineItems.find((item) => item.productNumber === product.sku);

    if (lineItemInEditOrder) {
      const { isDisabled, warningMessage: _warningMessage } = isLineItemDisabled({
        ctBusinessUnit: selectedBusinessUnit,
        ctProduct: lineItemInEditOrder,
        extraProductData: product.extraData,
        editOrder: { editCart, isEditingOrder },
      });

      shouldDisable = isDisabled;
      warningMessage = _warningMessage;
    }
  }

  return {
    productCategories,
    shouldDisable,
    warningMessage,
    perItemErrorMessage,
  };
};
