import { Order } from '@Types/cart/Order';
import { OrderDetail, OrderDetailLineItem } from '@Types/shamrockApi/Order';

export enum EditOrderActionType {
  ENABLE_EDIT_ORDER = 'ENABLE_EDIT_ORDER',
  DISABLE_EDIT_ORDER = 'DISABLE_EDIT_ORDER',
  CHANGE_ORDER_EDITING = 'CHANGE_ORDER_EDITING',
  SET_UPDATING_ORDER = 'SET_UPDATING_ORDER',
  SET_UPDATING_SPLIT_ORDER = 'SET_UPDATING_SPLIT_ORDER',
  LOAD_EDIT_ORDER_STATE = 'LOAD_EDIT_ORDER_STATE',
  ADD_PRODUCTS_TO_ORDER = 'ADD_PRODUCTS_TO_ORDER',
}

export type EditOrderAction =
  | { type: EditOrderActionType.ENABLE_EDIT_ORDER; payload: { editCart: OrderDetail; originalOrder: OrderDetail } }
  | { type: EditOrderActionType.DISABLE_EDIT_ORDER }
  | { type: EditOrderActionType.CHANGE_ORDER_EDITING; payload: { editCart: Partial<OrderDetail> } }
  | { type: EditOrderActionType.SET_UPDATING_ORDER; payload: boolean }
  | { type: EditOrderActionType.LOAD_EDIT_ORDER_STATE; payload: EditOrderState }
  | {
      type: EditOrderActionType.SET_UPDATING_SPLIT_ORDER;
      payload: { parentOrderNumber: string; list: Order };
    }
  | { type: EditOrderActionType.ADD_PRODUCTS_TO_ORDER; payload: OrderDetailLineItem[] };

export interface EditOrderState {
  loading: boolean;
  isEditingOrder: boolean;
  editCart: OrderDetail | null;
  originalOrder: OrderDetail | null;
  splitOrder: { parentOrderNumber: string; list: Order } | null;
  moutingInitialState: boolean;
}

export interface UseEditOrderGlobalProps extends EditOrderState {
  updateSplitOrder: (parentOrderNumber: string, list: Order) => void;
  enableOrderEditing: (originalOrder: OrderDetail) => void;
  disableOrderEditing: () => void;
  updateEditedOrder: (order: Partial<OrderDetail>) => void;
  getOriginalLineItemFromOrder: (sku: string, shouldSearchInEdit?: boolean) => OrderDetailLineItem;
  toggleOrderUpdatingState: (payload: boolean) => void;
  submitOrderUpdate: () => Promise<void>;
  addProductsToEditOrder: (product: OrderDetailLineItem[]) => void;
}
