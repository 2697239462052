import { IconProps, createIcon } from '@chakra-ui/react';

const CustomCloverDark = createIcon({
  displayName: 'Clover',
  viewBox: '0 0 24 24',

  path: (
    <>
      <path
        d="M12.1108 11.6439C12.1108 11.6439 10.9923 18.716 7.31809 20.8373"
        stroke="#5B9332"
        strokeWidth="1.875"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1077 11.6438L17.3387 8.62375C18.7842 7.78914 19.2787 5.94382 18.4441 4.49823C17.6095 3.05264 15.3244 2.55375 13.9658 4.70921L12.1046 11.6556L12.1077 11.6438Z"
        fill="#5B9332"
        stroke="#5B9332"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9689 4.69729C13.8583 2.14816 11.63 1.4377 10.1844 2.27231C8.73879 3.10692 8.24434 4.95224 9.07895 6.39783L12.099 11.6288L13.9572 4.69414L13.9689 4.69729Z"
        fill="#5B9332"
        stroke="#5B9332"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1101 11.644L7.56083 7.65358C6.30725 6.54923 4.39534 6.67941 3.291 7.93299C2.18666 9.18658 2.45742 11.5141 4.92968 12.1135L12.0983 11.6408L12.1101 11.644Z"
        fill="#5B9332"
        stroke="#5B9332"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.92941 12.1136C2.55253 13.0388 2.59598 15.3683 3.84957 16.4727C5.10315 17.577 7.01505 17.4469 8.1194 16.1933L12.1098 11.644L4.94116 12.1167L4.92941 12.1136Z"
        fill="#5B9332"
        stroke="#5B9332"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1078 11.6438L18.0427 10.4626C19.6805 10.133 21.2712 11.2017 21.6008 12.8395C21.9304 14.4773 20.5321 16.3576 18.0914 15.6406L12.1195 11.647L12.1078 11.6438Z"
        fill="#5B9332"
        stroke="#5B9332"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.0803 15.6375C19.6762 17.6271 18.4737 19.6229 16.8359 19.9525C15.1981 20.2821 13.6075 19.2134 13.2779 17.5756L12.0967 11.6406L18.0686 15.6343L18.0803 15.6375Z"
        fill="#5B9332"
        stroke="#5B9332"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});

const CustomCloverLight = createIcon({
  displayName: 'Clover',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        d="M12.1108 11.6439C12.1108 11.6439 10.9923 18.716 7.31809 20.8373"
        stroke="#BBE18B"
        strokeWidth="1.875"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1077 11.6438L17.3387 8.62375C18.7842 7.78914 19.2787 5.94382 18.4441 4.49823C17.6095 3.05264 15.3244 2.55375 13.9658 4.70921L12.1046 11.6556L12.1077 11.6438Z"
        fill="#BBE18B"
        stroke="#BBE18B"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9689 4.69729C13.8583 2.14816 11.63 1.4377 10.1844 2.27231C8.73879 3.10692 8.24434 4.95224 9.07895 6.39783L12.099 11.6288L13.9572 4.69414L13.9689 4.69729Z"
        fill="#BBE18B"
        stroke="#BBE18B"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1101 11.644L7.56083 7.65358C6.30725 6.54923 4.39534 6.67941 3.291 7.93299C2.18666 9.18658 2.45742 11.5141 4.92968 12.1135L12.0983 11.6408L12.1101 11.644Z"
        fill="#BBE18B"
        stroke="#BBE18B"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.92941 12.1136C2.55253 13.0388 2.59598 15.3683 3.84957 16.4727C5.10315 17.577 7.01505 17.4469 8.1194 16.1933L12.1098 11.644L4.94116 12.1167L4.92941 12.1136Z"
        fill="#BBE18B"
        stroke="#BBE18B"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1078 11.6438L18.0427 10.4626C19.6805 10.133 21.2712 11.2017 21.6008 12.8395C21.9304 14.4773 20.5321 16.3576 18.0914 15.6406L12.1195 11.647L12.1078 11.6438Z"
        fill="#BBE18B"
        stroke="#BBE18B"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.0803 15.6375C19.6762 17.6271 18.4737 19.6229 16.8359 19.9525C15.1981 20.2821 13.6075 19.2134 13.2779 17.5756L12.0967 11.6406L18.0686 15.6343L18.0803 15.6375Z"
        fill="#BBE18B"
        stroke="#BBE18B"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});

export const CloverIcon = (props?: IconProps & { isLightVariant?: boolean }) => {
  const { isLightVariant, ...rest } = props ?? {};
  if (isLightVariant) {
    return <CustomCloverLight width="24px" height="24px" transform="rotate(15deg)" flexShrink="0" {...rest} />;
  }

  return <CustomCloverDark width="24px" height="24px" transform="rotate(15deg)" flexShrink="0" {...rest} />;
};
