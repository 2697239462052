import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { Box, Divider, Flex, HStack, Stack, StackProps, Text, VStack, useToast } from '@chakra-ui/react';
import { OrderDetail } from '@Types/shamrockApi/Order';
import { useGlobal } from 'components/globalProvider';
import { getCtProductsResponseTime } from 'composable/helpers/hooks';
import { ORDER_DETAILS_LOADTIME_INITIAL_VALUES, useOrderDetail } from 'composable/helpers/hooks/orders/useOrderDetail';
import { UnitStore } from 'composable/helpers/hooks/useUserHelpers';
import { isCancelled, isDeliveredComplete, isReadyForPickup } from 'composable/helpers/utils/order-status';
import { isCustomerDirectDeliveryType, isPickupDeliveryType } from 'composable/helpers/utils/order-utils';
import { transformPickupAddressToBuAddress } from 'composable/helpers/utils/transform-pickup-address-to-bu-address';
import routes from 'helpers/constants/routes';
import { useFormat } from 'helpers/hooks/useFormat';
import { useWarehouseByCustomerNumber } from 'helpers/hooks/useWarehouseByCustomerNumber';
import { getOrderQuantityFromStatus, getOrderTotalCentValueNew } from 'helpers/utils/calculateCartCount';
import { useProductVariants } from 'hooks/useProductVariants';
import { every, values, omit } from 'lodash';
import { Header, Modifications, OrderDetailsCard } from './components';
import { useCancelOrderStatus } from './components/parts/hooks/use-cancel-order-status';
import { OrderDetailsSkeleton } from './components/parts/order-details-skeleton';
import { OrderSummary } from '../checkout/parts/order-summary';
import { SplitOrderSummary } from '../checkout/parts/split-order-summary';
import { ContentContainer, TOAST_ICON } from '../general';
import OrderAddress from '../order-address';

type OrderDetailsPageProps = {
  customerNumber: string;
  orderId: string;
};

type LeftSideExtraProps = StackProps & {
  deliveryType?: string;
  businessUnit?: string;
  modifications?: OrderDetail['modifications'];
  pickupAddress?: OrderDetail['pickupAddress'];
};

export type CancelOrderStatus = 'SUCCESS' | 'FAILURE' | 'TIMEOUT';

export const APP_FAILURE_TOAST_ID = 'APP_FAILURE_TOAST_ID';

// time in miliseconds to refetch order details after split order
const REFETCH_ORDER_TIME = 1500;

const LeftSideExtra: FunctionComponent<LeftSideExtraProps> = ({
  deliveryType,
  businessUnit,
  modifications,
  pickupAddress,
  ...rest
}) => {
  const { formatMessage } = useFormat({ name: 'common' });

  //Setting delivery title based on the delivery type
  const isPickUp = isPickupDeliveryType(deliveryType);
  const deliveryTypeTitle = isPickUp
    ? formatMessage({ id: 'orderDetails.pickUpAt' })
    : formatMessage({ id: 'orderDetails.deliverTo' });

  const { accountList, shamrockUser } = useGlobal().useUserGlobal.state;
  const permissions = shamrockUser?.user?.permissions?.data ?? [];

  const orderBusinessUnit = accountList.find((bu) => bu?.key === businessUnit);

  const buAddress = orderBusinessUnit?.addresses?.find(
    (address) => address.id === orderBusinessUnit?.shippingAddressIds[0],
  );

  const deliveryAddress = isPickUp ? transformPickupAddressToBuAddress(pickupAddress) || buAddress : buAddress;
  const billingAddress = orderBusinessUnit?.addresses?.find(
    (address) => address.id === orderBusinessUnit?.billingAddressIds[0],
  );

  // Get store key from the order business unit
  const storeKey = orderBusinessUnit?.stores[0];
  const unitStores = JSON.parse(orderBusinessUnit?.custom?.fields?.sfc_business_unit_stores) as UnitStore[];

  // Get the store details from the store key to fetch the payment terms
  const unitStore = unitStores.find((unit) => unit.StoreKey.toLowerCase() === storeKey?.key.toLowerCase());

  return (
    <VStack width="full" {...rest} gap={2}>
      {!isCustomerDirectDeliveryType(deliveryType) && <Modifications modifications={modifications} />}
      {/* Delivery Address */}
      {deliveryAddress && (
        <Box borderRadius="lg" background="white" color="neutral.900" width="full" textAlign="center">
          <Flex textAlign="left" direction="column">
            <Box p={4} width="full" backgroundColor="neutral.50" borderTopRadius="lg">
              <Text color="neutral.900" fontSize="lg" fontWeight="bold" lineHeight="120%">
                {deliveryTypeTitle}
              </Text>
            </Box>
            <Box width="full" p={8}>
              <OrderAddress address={deliveryAddress} showPhone={isPickUp} />
            </Box>
          </Flex>
        </Box>
      )}

      {/* Billing Address */}
      {billingAddress && (
        <Box borderRadius="lg" background="white" color="neutral.900" width="full" textAlign="center">
          <Flex textAlign="left" direction="column">
            <Box p={4} width="full" backgroundColor="neutral.50" borderTopRadius="lg">
              <Text color="neutral.900" fontSize="lg" fontWeight="bold" lineHeight="120%">
                {formatMessage({ id: 'orderDetails.billTo' })}
              </Text>
            </Box>
            <Box width="full" p={8}>
              <Flex textAlign="left" direction="column" gap={2}>
                <Box pb={2}>
                  <OrderAddress address={billingAddress} />
                </Box>
                {permissions.includes('CanViewBillingInformation') && (
                  <Box>
                    <HStack
                      align="center"
                      borderBottomWidth={1}
                      borderColor="neutral.100"
                      borderTopWidth={1}
                      justify="space-between"
                      mt={2}
                      py={1}
                    >
                      <Text color="neutral.600" fontSize="sm" fontWeight="bold" lineHeight="150%">
                        {formatMessage({ id: 'orderDetails.paymentTerm' })}
                      </Text>
                      <Text color="neutral.600" fontSize="sm" lineHeight="150%">
                        {unitStore?.PaymentTerms}
                      </Text>
                    </HStack>
                  </Box>
                )}
              </Flex>
            </Box>
          </Flex>
        </Box>
      )}
    </VStack>
  );
};

export const OrderDetailsPage: FunctionComponent<OrderDetailsPageProps> = ({ customerNumber, orderId }) => {
  const router = useRouter();
  const { formatMessage } = useFormat({ name: 'common' });

  const { warehouseNumber } = useWarehouseByCustomerNumber(customerNumber);
  const {
    orderDetail: orderDetailResponse,
    isLoadingOrders,
    toggleRefetchOrder,
    orderLoadTime,
    setOrderLoadTime,
  } = useOrderDetail({ orderNumber: orderId, warehouseNumber });

  const [status, setStatus] = useState<OrderDetail['orderStatus'] | null>(null);
  const [cancelOrderStatus, setCancelOrderStatus] = useState<CancelOrderStatus | null>(null);
  const feWorkTime = useRef<number>(0);

  // Status needs to be held in state to able to update both components when the status changes
  useEffect(() => {
    if (orderDetailResponse && orderDetailResponse?.orderStatus) {
      setStatus(orderDetailResponse?.orderStatus);
    }
  }, [orderDetailResponse]);

  // sort line items by addedDateTime (time of adding to the cart)
  const orderDetail = orderDetailResponse && {
    ...orderDetailResponse,
    lineItems: orderDetailResponse?.lineItems?.sort(
      (orderItemA, orderItemB) => orderItemA?.lineNumber - orderItemB?.lineNumber,
    ),
  };

  const {
    switchAccount,
    state: { activeAccount },
  } = useGlobal().useUserGlobal;

  const { isEditingOrder, disableOrderEditing, enableOrderEditing, splitOrder } = useGlobal().useEditOrderGlobal;

  const { variants, isLoading, products } = useProductVariants({ lineItems: orderDetail?.lineItems });

  // Tracks the rest of the load time and sends to LogRocket
  useEffect(() => {
    if (!isLoading && !orderLoadTime.mdProductList) {
      // Tracks load time for CT Products API
      window.LogRocket?.log(`Order details CT variants loaded, count: ${products.length}`);
      feWorkTime.current = Date.now();
      setOrderLoadTime((prev) => ({ ...prev, mdProductList: Math.ceil(getCtProductsResponseTime()) }));
    }

    if (!isLoadingOrders && !isLoading && !orderLoadTime.feWork) {
      // Tracks load time for frontend work
      const feWork = Date.now() - feWorkTime.current;
      const logRocketPayload = { ...orderLoadTime, feWork };
      const omitTotalTime = omit(logRocketPayload, 'totalTime');
      if (every(values(omitTotalTime), (val) => !!val)) {
        logRocketPayload['totalTime'] = feWork + logRocketPayload.mdOrderDetail + logRocketPayload.mdProductList;
        window.LogRocket?.track('OrderDetailsPageLoad', logRocketPayload);
        window.LogRocket?.log('Order Details displayed');
        feWorkTime.current = 0;
        setOrderLoadTime(ORDER_DETAILS_LOADTIME_INITIAL_VALUES);
      }
    }
  }, [isLoadingOrders, isLoading, orderLoadTime]);

  const toast = useToast();

  const handleEditOrder = async () => {
    const userCurrentBusinessUnit = activeAccount?.key;
    if (userCurrentBusinessUnit !== orderDetail?.customerNumber) {
      await switchAccount(orderDetail?.customerNumber);
    }

    isEditingOrder ? disableOrderEditing() : enableOrderEditing(orderDetail);
  };

  const hasIssue = orderDetail?.lineItems?.some((item) => item.hasCreditIssued);
  const alertFlaggedForCasesOrdered = orderDetail?.lineItems?.some((item) => {
    if (
      !isDeliveredComplete(orderDetail.orderStatus) &&
      !isReadyForPickup(orderDetail.orderStatus) &&
      !isCancelled(orderDetail.orderStatus)
    ) {
      return item.allocatedQuantity !== item.orderedQuantity;
    }
    return false;
  });

  const orderStatus = orderDetail?.orderStatus;
  const lineItems = orderDetail?.lineItems;

  const orderTotal = getOrderTotalCentValueNew(lineItems, variants);
  const casesTotal = orderStatus && lineItems ? getOrderQuantityFromStatus(orderStatus, lineItems) : 0;

  if (
    !isLoadingOrders &&
    (!orderDetail || !orderDetail.orderNumber || orderDetail.lineItems.length === 0) &&
    !toast.isActive(APP_FAILURE_TOAST_ID)
  ) {
    toast({
      duration: 5000,
      id: APP_FAILURE_TOAST_ID,
      status: 'error',
      title: formatMessage({ id: 'app.failure' }),
      icon: TOAST_ICON.error,
    });

    router.push(routes.ORDER_LIST_PAGE);
  }

  // Monitors cancelOrderStatus to display toast message and change status
  useCancelOrderStatus(cancelOrderStatus);

  const isSplitOrder = !!splitOrder?.list && splitOrder?.parentOrderNumber === orderDetail?.orderNumber;
  // @TODO consider adding it to default checkout operations too, not only split order
  const shouldRefetchOnSplit = useRef(true);

  const poNumber =
    isSplitOrder && !!splitOrder?.list?.purchaseOrderNumber
      ? splitOrder?.list?.purchaseOrderNumber
      : orderDetail?.purchaseOrderNumber;

  const splitHandlingInstructions = isSplitOrder && splitOrder?.list?.custom?.fields?.specialHandlingInstructions;

  const handlingInstructions = !!splitHandlingInstructions
    ? splitHandlingInstructions
    : orderDetail?.handlingInstructions;

  useEffect(() => {
    if (isSplitOrder && shouldRefetchOnSplit.current) {
      setTimeout(() => {
        shouldRefetchOnSplit.current = false;
        toggleRefetchOrder();
      }, REFETCH_ORDER_TIME);
    }
  }, [isSplitOrder, shouldRefetchOnSplit]);

  const splitLoading = isSplitOrder && shouldRefetchOnSplit.current;

  return (
    <ContentContainer>
      <Flex color="white" mt={{ base: 9, lg: 16 }} mb={24.5} flexDirection="column">
        <Header orderNumber={orderDetail?.orderNumber} ownerNumber={customerNumber} />

        <Divider orientation="horizontal" borderColor="neutral.500" mt={4} mb={2} />
        <Text fontSize={{ base: 'xl', md: '44px' }} fontWeight="extrabold" mb={10} mt={5}>
          {formatMessage({ id: 'account.orders.label', values: { orderNumber: orderId } })}
        </Text>

        {/* Skeleton Loading */}
        {splitLoading && isLoadingOrders && !status && <OrderDetailsSkeleton />}

        {/* Left Side */}
        <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: 2, md: 5 }}>
          {!splitLoading && !isLoadingOrders && status && (
            <VStack w={{ base: 'full', md: '295px' }}>
              <OrderDetailsCard
                isLoadingOrders={isLoadingOrders}
                orderDetail={orderDetail}
                handleEditOrder={handleEditOrder}
                isEditOrder={isEditingOrder}
                cancelOrderStatus={cancelOrderStatus}
                setCancelOrderStatus={setCancelOrderStatus}
                status={status}
                toggleRefetchOrder={toggleRefetchOrder}
                lineItems={orderDetail?.lineItems}
                poNumber={poNumber}
                handlingInstructions={handlingInstructions}
              />
              <LeftSideExtra
                display={{ base: 'none', md: 'flex' }}
                deliveryType={orderDetail?.orderType}
                businessUnit={orderDetail?.customerNumber}
                modifications={orderDetail?.modifications}
                pickupAddress={orderDetail?.pickupAddress}
              />
            </VStack>
          )}

          {/* Order Summary Side */}
          {!splitLoading && !isLoadingOrders && status && (
            <VStack flex={1} gap={2}>
              <VStack align="unset" width="full" gap={{ base: 8, md: 10 }}>
                <OrderSummary
                  isSplitOrder={isSplitOrder}
                  lineItems={[]}
                  cases={casesTotal}
                  items={orderDetail?.lineItems?.length}
                  orderTotal={orderTotal}
                  orderLineItems={orderDetail?.lineItems}
                  orderStatus={status}
                  products={products}
                  variants={variants}
                  isLoading={isLoading || isLoadingOrders}
                  hasIssue={hasIssue}
                  alertFlaggedForCasesOrdered={alertFlaggedForCasesOrdered}
                  openOnLoad
                  isOrderPage
                  isInvoiced={Boolean(orderDetail?.invoiceNumber)}
                />
                {isSplitOrder && <SplitOrderSummary order={splitOrder.list} label="secondaryOrder" />}
              </VStack>

              <LeftSideExtra
                display={{ base: 'flex', md: 'none' }}
                deliveryType={orderDetail?.orderType}
                businessUnit={orderDetail?.customerNumber}
                modifications={orderDetail?.modifications}
                pickupAddress={orderDetail?.pickupAddress}
              />
            </VStack>
          )}
        </Stack>
      </Flex>
    </ContentContainer>
  );
};
