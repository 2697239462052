import { Box, BoxProps, Flex, FlexProps, Icon, IconProps, Text, TextProps } from '@chakra-ui/react';
import { largeSizes } from 'composable/chakra/theme/foundations/sizes';
import { fontSizes, fontWeights, lineHeights } from 'composable/chakra/theme/foundations/typography';
import { CheckCircle, Cloud, Truck, Warning } from 'phosphor-react';
import { useDarkMode } from 'frontastic';
import { CustomNonFilledInfoIcon } from './info-icon';
import { WarningDiamond } from './warning-icon';

interface InlineMessageProps extends BoxProps {
  variant?: 'success' | 'warning' | 'error' | 'info' | 'order' | 'general';
  dark?: boolean;
  iconAlignment?: 'start' | 'center' | 'end' | 'flex-start';
  textProps?: TextProps;
  iconProps?: IconProps;
  flexProps?: FlexProps;
  ariaLabel?: string;
}

const iconMap = {
  success: CheckCircle,
  warning: WarningDiamond,
  error: Warning,
  info: CustomNonFilledInfoIcon,
  order: Truck,
  general: Cloud,
} as const;

const iconColorMap: Record<string, [string, string]> = {
  success: ['secondary.700', 'secondary.400'],
  warning: ['yellow.700', 'yellow.400'],
  error: ['red.700', 'red.400'],
  info: ['violet.700', 'violet.400'],
  order: ['secondary.700', 'secondary.400'],
  general: ['neutral.700', 'neutral.400'],
};

const textColorMap: Record<string, [string, string]> = {
  success: ['secondary.800', 'secondary.200'],
  warning: ['yellow.800', 'yellow.200'],
  error: ['red.800', 'red.200'],
  info: ['violet.800', 'violet.200'],
  order: ['secondary.800', 'secondary.200'],
  general: ['neutral.800', 'neutral.300'],
};

const getColor = (variant: string, colorMap: Record<string, [string, string]>, mode: string, dark: boolean) => {
  const [lightColor, darkColor] = colorMap[variant];
  return mode === 'light' && !dark ? lightColor : darkColor;
};

export const InlineMessage = ({
  children,
  variant = 'general',
  iconAlignment = 'center',
  dark,
  textProps,
  iconProps,
  flexProps,
  ariaLabel,
  ...boxProps
}: InlineMessageProps) => {
  const { mode } = useDarkMode();

  const iconColor = getColor(variant, iconColorMap, mode, dark);
  const textColor = getColor(variant, textColorMap, mode, dark);

  return (
    <Box
      w={largeSizes.full}
      fontSize={{ base: fontSizes.xs, sm: fontSizes.sm }}
      lineHeight={lineHeights[5]}
      fontWeight={fontWeights.bold}
      {...boxProps}
    >
      <Flex alignItems={iconAlignment} {...flexProps}>
        <Icon
          as={iconMap[variant]}
          width={{ base: 5, sm: 7 }}
          height={{ base: 5, sm: 5 }}
          boxSize={{ base: 5, sm: 7 }}
          paddingRight={{ base: 1, sm: 2 }}
          color={iconColor}
          aria-label={ariaLabel}
          {...iconProps}
        />
        <Text color={textColor} {...textProps}>
          {children}
        </Text>
      </Flex>
    </Box>
  );
};
