import { InventoryStatus } from 'composable/helpers/utils/inventory-utils';
import { FormatMessageParams } from 'helpers/hooks';

export const getInventoryStatusMessage = (
  status: InventoryStatus,
  intl: { formatMessage: (args: Omit<FormatMessageParams, 'name'>) => string },
) => {
  let message = '';

  switch (status) {
    case InventoryStatus.HIGH:
    case InventoryStatus.MEDIUM:
      message = intl.formatMessage({
        id: 'inventory.inStock',
        defaultMessage: 'In Stock',
      });
      break;
    case InventoryStatus.LOW:
      message = intl.formatMessage({
        id: 'inventory.low',
        defaultMessage: 'Limited stock',
      });
      break;
    case InventoryStatus.OBSOLETE:
      message = intl.formatMessage({
        id: 'inventory.obsolete',
        defaultMessage: 'Discontinued',
      });
      break;
    case InventoryStatus.UNAVAILABLE:
    case InventoryStatus.OUT:
      message = intl.formatMessage({
        id: 'inventory.out',
        defaultMessage: 'Temporarily Unavailable',
      });
      break;
    case InventoryStatus.JIT:
    case InventoryStatus.SUR:
      message = intl.formatMessage({
        id: 'inventory.jit',
        defaultMessage: 'Shipped to Order',
      });
      break;
    case InventoryStatus.JITMEAT:
    case InventoryStatus.JITFISH:
      message = intl.formatMessage({
        id: 'inventory.jitMeat',
        defaultMessage: 'Cut to Order',
      });
      break;
    case InventoryStatus.INACTIVE:
    case InventoryStatus.PERMANENTOOS:
    case InventoryStatus.NOT_FOUND:
    case InventoryStatus.NULL:
    default:
      message = intl.formatMessage({
        id: 'inventory.inactive',
        defaultMessage: 'Unavailable',
      });
      break;
  }

  return message;
};

export const isOutStockProduct = (status: InventoryStatus) => {
  switch (status) {
    case InventoryStatus.OUT:
    case InventoryStatus.UNAVAILABLE:
    case InventoryStatus.INACTIVE:
    case InventoryStatus.PERMANENTOOS:
    case InventoryStatus.NOT_FOUND:
    case InventoryStatus.NULL:
      return true;
    default:
      return false;
  }
};
