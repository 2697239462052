import { BoxProps, Text, TextProps } from '@chakra-ui/react';
import { ExtraProductData } from '@Types/shamrockApi/Product';
import { useGetLastPurchaseDate } from 'composable/components/plp/hooks/use-get-last-purchase-date';
import { getVariantColorForText } from '../helpers/determineColor';

type LastPurchaseDateProps = BoxProps & {
  extraProductData: ExtraProductData;
  variant?: 'plp' | 'pdp';
  textProps?: TextProps & {
    'data-testid'?: string;
  };
};

export const LastPurchaseDate = ({ extraProductData, variant = 'plp', textProps, ...rest }: LastPurchaseDateProps) => {
  const { lastPurchaseDate } = useGetLastPurchaseDate(extraProductData);

  if (!lastPurchaseDate) return null;

  return (
    <Text
      as="p"
      fontSize={{ base: 'xs', md: 'sm' }}
      lineHeight="120%"
      color={getVariantColorForText(variant)}
      mt={2}
      {...rest}
      {...textProps}
      noOfLines={1}
    >
      {lastPurchaseDate}
    </Text>
  );
};
