import { Suspense, useEffect, useState, useRef } from 'react';
import { useRouter } from 'next/router';
import { Box, VStack, Flex, Button } from '@chakra-ui/react';
import { FEATURE_FLAGS } from 'composable/components/general';
import { MIN_CHARACTERS_FOR_INPUT } from 'composable/components/global-search/constants';
import { useGlobalSearch } from 'composable/components/global-search/context';
import { formatNumberWithComma } from 'composable/components/order-guide/utils';
import { useOrderGuidesByCustomerNumbersContext } from 'composable/components/order-guides-by-customers-numbers';
import { QuickCatalogVariant } from 'composable/components/product-card-v2';
import { HitWithExtraData } from 'composable/components/qc-search/type';
import {
  QuickCatalogModalEmptyState,
  QuickCatalogModalSearchInput,
  QuickCatalogProductSkeleton,
} from 'composable/components/quick-catalog/parts';
import { useGetElementSize } from 'composable/helpers/hooks';
import routes from 'helpers/constants/routes';
import { useFormat } from 'helpers/hooks';
import { useActiveProductsList } from 'helpers/hooks/useActiveProductsList';
import { useHits, useSearchBox } from 'react-instantsearch';
import { useGlobal } from 'components/globalProvider';
import { useQuickCatalogTracking } from './hooks/useQuickCatalogTracking';

export const QC_LIST_ID = 'quick-catalog-modal-list';

interface PerformanceTracking {
  modalOpen: {
    timestamp: number;
    completed: boolean;
  };
  algoliaQuery: {
    start: number;
    end: number;
    completed: boolean;
  };
  extraData: {
    start: number;
    end: number;
    completed: boolean;
  };
  skeletonRemoved: {
    timestamp: number;
    completed: boolean;
  };
  metricsReported: boolean;
}

export const QuickCatalogContent = () => {
  const router = useRouter();
  const { formatMessage } = useFormat({ name: 'common' });
  const { isQuickCatalogOpen, closeQuickCatalog, debouncedInputValue, setInputValue, closeMobileSearch, inputValue } =
    useGlobalSearch();
  const { showAddToOrderGuideModal } = useOrderGuidesByCustomerNumbersContext();
  const { items, results } = useHits();
  const { searchResults: filteredItems, isProcessing } = useActiveProductsList(
    debouncedInputValue.length >= MIN_CHARACTERS_FOR_INPUT ? items : [],
    true,
  );
  const { activeAccount } = useGlobal().useUserGlobal.state;

  // @TODO: fix in SHAM-3100
  // removing quickCatalog tracking as it does not accomplishes the desired functionality
  // const quickCatalogTracking = useQuickCatalogTracking();

  const performanceTracking = useRef<PerformanceTracking>({
    modalOpen: {
      timestamp: 0,
      completed: false,
    },
    algoliaQuery: {
      start: 0,
      end: 0,
      completed: false,
    },
    extraData: {
      start: 0,
      end: 0,
      completed: false,
    },
    skeletonRemoved: {
      timestamp: 0,
      completed: false,
    },
    metricsReported: false,
  });

  const subsequentSearchTracking = useRef<{
    currentSearchTerm: string;
    algoliaStart: number;
    algoliaEnd: number;
    extraDataStart: number;
    extraDataEnd: number;
    searchCompleted: boolean;
  }>({
    currentSearchTerm: '',
    algoliaStart: 0,
    algoliaEnd: 0,
    extraDataStart: 0,
    extraDataEnd: 0,
    searchCompleted: false,
  });

  const prevInputValue = useRef('');
  const prevResults = useRef(results);
  const prevItems = useRef(items);
  const prevIsProcessing = useRef(isProcessing);
  const initialLoadCompleted = useRef(false);

  const noResults = results.nbHits === 0;
  const { refine } = useSearchBox();
  const [isScrollbarDisplayed, setScrollbarDisplayed] = useState(false);
  const [isSearchEmpty, setSearchEmpty] = useState(false);
  const [isLoadingResults, setLoadingResults] = useState(false);
  const [showNoResultsText, setShowNoResultsText] = useState(true);

  const size = useGetElementSize(QC_LIST_ID);
  const quickCatalogItems = (isProcessing || filteredItems.length === 0 ? items : filteredItems) as HitWithExtraData[];

  const checkAndSendMetrics = () => {
    if (performanceTracking.current.metricsReported) return;

    const allStepsCompleted =
      performanceTracking.current.modalOpen.completed &&
      performanceTracking.current.algoliaQuery.completed &&
      performanceTracking.current.extraData.completed &&
      performanceTracking.current.skeletonRemoved.completed;

    if (allStepsCompleted) {
      if (performanceTracking.current.algoliaQuery.end < performanceTracking.current.algoliaQuery.start) {
        return;
      }
      if (performanceTracking.current.extraData.end < performanceTracking.current.extraData.start) {
        return;
      }
      if (performanceTracking.current.skeletonRemoved.timestamp < performanceTracking.current.extraData.end) {
        performanceTracking.current.skeletonRemoved.timestamp =
          performanceTracking.current.extraData.end +
          (performanceTracking.current.extraData.end - performanceTracking.current.extraData.start);
      }

      performanceTracking.current.metricsReported = true;
      initialLoadCompleted.current = true;

      const algoliaQuery =
        performanceTracking.current.algoliaQuery.end - performanceTracking.current.algoliaQuery.start;
      const mdExtraProductData =
        performanceTracking.current.extraData.end - performanceTracking.current.extraData.start;
      const feWork = performanceTracking.current.skeletonRemoved.timestamp - performanceTracking.current.extraData.end;
      const totalTime =
        performanceTracking.current.skeletonRemoved.timestamp - performanceTracking.current.modalOpen.timestamp;

      if (typeof window !== 'undefined' && window.LogRocket) {
        window.LogRocket?.track('QuickCatalogPageLoad', {
          account: activeAccount?.key,
          warehouse: activeAccount?.stores[0]?.key,
          algoliaQuery,
          mdExtraProductData,
          feWork,
          totalTime,
        });
      }
    }
  };

  const trackSubsequentSearch = () => {
    if (
      !subsequentSearchTracking.current.searchCompleted &&
      subsequentSearchTracking.current.algoliaEnd > 0 &&
      subsequentSearchTracking.current.extraDataEnd > 0
    ) {
      const algoliaQuery = subsequentSearchTracking.current.algoliaEnd - subsequentSearchTracking.current.algoliaStart;
      const mdExtraProductData =
        subsequentSearchTracking.current.extraDataEnd - subsequentSearchTracking.current.extraDataStart;
      const totalTime = subsequentSearchTracking.current.extraDataEnd - subsequentSearchTracking.current.algoliaStart;
      subsequentSearchTracking.current.searchCompleted = true;

      if (typeof window !== 'undefined' && window.LogRocket) {
        window.LogRocket?.track('QuickCatalogSubsequentSearch', {
          account: activeAccount?.key,
          warehouse: activeAccount?.stores[0]?.key,
          algoliaQuery,
          mdExtraProductData,
          totalTime,
        });
      }
    }
  };

  useEffect(() => {
    if (isQuickCatalogOpen && !performanceTracking.current.modalOpen.completed) {
      performanceTracking.current.modalOpen = {
        timestamp: performance.now(),
        completed: true,
      };

      performanceTracking.current.algoliaQuery = {
        start: 0,
        end: 0,
        completed: false,
      };
      performanceTracking.current.extraData = {
        start: 0,
        end: 0,
        completed: false,
      };
      performanceTracking.current.skeletonRemoved = {
        timestamp: 0,
        completed: false,
      };
      performanceTracking.current.metricsReported = false;
      initialLoadCompleted.current = false;
    } else if (!isQuickCatalogOpen) {
      performanceTracking.current.modalOpen.completed = false;
      initialLoadCompleted.current = false;
    }
  }, [isQuickCatalogOpen]);

  useEffect(() => {
    if (
      isQuickCatalogOpen &&
      debouncedInputValue.length >= MIN_CHARACTERS_FOR_INPUT &&
      debouncedInputValue !== prevInputValue.current
    ) {
      if (!initialLoadCompleted.current) {
        if (!performanceTracking.current.algoliaQuery.completed) {
          performanceTracking.current.algoliaQuery.start = performance.now();
        }
      } else {
        subsequentSearchTracking.current = {
          currentSearchTerm: debouncedInputValue,
          algoliaStart: performance.now(),
          algoliaEnd: 0,
          extraDataStart: 0,
          extraDataEnd: 0,
          searchCompleted: false,
        };
      }
    }

    prevInputValue.current = debouncedInputValue;
  }, [isQuickCatalogOpen, debouncedInputValue]);

  useEffect(() => {
    const resultChanged = results !== prevResults.current;

    if (
      isQuickCatalogOpen &&
      resultChanged &&
      results.query === inputValue &&
      inputValue.length >= MIN_CHARACTERS_FOR_INPUT
    ) {
      if (!initialLoadCompleted.current) {
        if (performanceTracking.current.algoliaQuery.start > 0 && !performanceTracking.current.algoliaQuery.completed) {
          performanceTracking.current.algoliaQuery.end = performance.now();
          performanceTracking.current.algoliaQuery.completed = true;
          performanceTracking.current.extraData.start = performance.now();

          window.LogRocket?.log('Quick Catalog - Algolia data received');
          checkAndSendMetrics();
        }
      } else if (
        subsequentSearchTracking.current.algoliaStart > 0 &&
        subsequentSearchTracking.current.algoliaEnd === 0
      ) {
        subsequentSearchTracking.current.algoliaEnd = performance.now();
        subsequentSearchTracking.current.extraDataStart = performance.now();
        window.LogRocket?.log(`Quick Catalog - Algolia data received`);
        trackSubsequentSearch();
      }
    }

    prevResults.current = results;
  }, [isQuickCatalogOpen, results, inputValue]);

  useEffect(() => {
    const itemsChanged = items !== prevItems.current;

    if (isQuickCatalogOpen && itemsChanged && items.length > 0) {
      if (!initialLoadCompleted.current) {
        if (performanceTracking.current.extraData.start > 0 && !performanceTracking.current.extraData.completed) {
          performanceTracking.current.extraData.end = performance.now();
          performanceTracking.current.extraData.completed = true;

          window.LogRocket?.log(`Quick Catalog - Extended product data received, product count: ${items.length}`);
          checkAndSendMetrics();
        }
      } else if (
        subsequentSearchTracking.current.extraDataStart > 0 &&
        subsequentSearchTracking.current.extraDataEnd === 0
      ) {
        subsequentSearchTracking.current.extraDataEnd = performance.now();
        window.LogRocket?.log(
          `Quick Catalog - Extended product data received for search, product count: ${items.length}`,
        );

        trackSubsequentSearch();
      }
    }

    prevItems.current = items;
  }, [isQuickCatalogOpen, items]);

  useEffect(() => {
    const processingFinished = prevIsProcessing.current && !isProcessing;
    const itemsLoaded = items.length > 0 && !isProcessing;

    if (
      isQuickCatalogOpen &&
      (processingFinished || itemsLoaded) &&
      !performanceTracking.current.skeletonRemoved.completed &&
      !initialLoadCompleted.current
    ) {
      performanceTracking.current.skeletonRemoved.timestamp = performance.now();
      performanceTracking.current.skeletonRemoved.completed = true;

      window.LogRocket?.log('Quick Catalog displayed');
      checkAndSendMetrics();
    }

    prevIsProcessing.current = isProcessing;
  }, [isQuickCatalogOpen, isProcessing, items]);

  useEffect(() => {
    if (!isQuickCatalogOpen) return;

    const timerId = setTimeout(() => {
      if (!performanceTracking.current.metricsReported && performanceTracking.current.modalOpen.completed) {
        if (
          !performanceTracking.current.algoliaQuery.completed &&
          performanceTracking.current.modalOpen.timestamp > 0
        ) {
          performanceTracking.current.algoliaQuery.start = performanceTracking.current.modalOpen.timestamp;
          performanceTracking.current.algoliaQuery.end = performanceTracking.current.modalOpen.timestamp + 100;
          performanceTracking.current.algoliaQuery.completed = true;
        }

        if (!performanceTracking.current.extraData.completed && performanceTracking.current.algoliaQuery.end > 0) {
          performanceTracking.current.extraData.start = performanceTracking.current.algoliaQuery.end;
          performanceTracking.current.extraData.end = performanceTracking.current.algoliaQuery.end + 50;
          performanceTracking.current.extraData.completed = true;
        }

        if (!performanceTracking.current.skeletonRemoved.completed && performanceTracking.current.extraData.end > 0) {
          performanceTracking.current.skeletonRemoved.timestamp = performance.now();
          performanceTracking.current.skeletonRemoved.completed = true;
        }

        checkAndSendMetrics();
      }
    }, 5000);

    return () => clearTimeout(timerId);
  }, [isQuickCatalogOpen]);

  useEffect(() => {
    const handleError = (error: any) => {
      if (error?.response?.config?.url) {
        if (error.showToast) {
          console.warn('Error toast shown', { reason: error.response.config.url });
        } else {
          console.warn('Error toast suppressed', { reason: error.response.config.url });
        }
      }
    };

    window.addEventListener('error', handleError);
    return () => window.removeEventListener('error', handleError);
  }, []);

  useEffect(() => {
    const list = document.getElementById(QC_LIST_ID);
    if (list) {
      setScrollbarDisplayed(list.scrollHeight > list.clientHeight);
    }
  }, [quickCatalogItems.length, size]);

  useEffect(() => {
    if (isQuickCatalogOpen) {
      if (debouncedInputValue.length >= MIN_CHARACTERS_FOR_INPUT) {
        refine(debouncedInputValue);
        setSearchEmpty(false);
        setLoadingResults(true);
        setShowNoResultsText(true);
      } else {
        setSearchEmpty(true);
        setLoadingResults(false);
      }
    }
  }, [isQuickCatalogOpen, debouncedInputValue, inputValue]);

  useEffect(() => {
    if (isLoadingResults === true && results.query === inputValue) {
      setLoadingResults(false);
    }
  }, [results, isLoadingResults, inputValue]);

  useEffect(() => {
    if (isQuickCatalogOpen === true && inputValue === '') {
      setShowNoResultsText(false);
    }

    if (isQuickCatalogOpen === false) {
      setShowNoResultsText(true);
    }
  }, [isQuickCatalogOpen]);

  useEffect(() => {
    const isReady = !isProcessing && !isLoadingResults;
    if (isQuickCatalogOpen && filteredItems?.length > 0 && isReady && debouncedInputValue) {
      setTimeout(() => {
        try {
          const viewedListsStr = localStorage.getItem('viewed_lists') || '{}';
          const viewedLists = JSON.parse(viewedListsStr);
          const now = new Date().getTime();
          const queryHash = debouncedInputValue || 'default_catalog';

          if (!viewedLists[queryHash] || now - viewedLists[queryHash] > 30000) {
            console.log('[Analytics Debug] Sending view_item_list for query:', queryHash);

            const trackableItems = filteredItems.map((item, index) => ({
              id: item.objectID,
              name: item.name,
              price: item.price?.value / 100,
              sku: item.sku,
              quantity: 1,
              image_url: item.imageURL || '',
              index: index + 1,
              categories: item.categories || [],
              brand: item.brand || '',
            }));

            // @TODO: fix in SHAM-3100
            // removing quickCatalog tracking as it does not accomplishes the desired functionality
            // quickCatalogTracking.trackViewItemList({
            //   items: trackableItems,
            // });

            viewedLists[queryHash] = now;
            localStorage.setItem('viewed_lists', JSON.stringify(viewedLists));
          } else {
            console.log('[Analytics Debug] Skipping duplicate view_item_list for query:', queryHash);
          }
        } catch (e) {
          console.error('Error tracking viewed lists:', e);
        }
      }, 1000); //delaying a little bit to avoid tracking 'alfonsino'
    }
  }, [isProcessing, isLoadingResults, debouncedInputValue, filteredItems?.length]);

  return (
    <VStack gap={5}>
      <Box w="100%">
        <Flex marginBottom={5}>
          <QuickCatalogModalSearchInput
            isDisabled={isProcessing || isLoadingResults}
            onClearSearch={() => setSearchEmpty(true)}
            onSearchSubmit={setInputValue}
          />
        </Flex>
        {isSearchEmpty || noResults || isLoadingResults ? (
          <QuickCatalogModalEmptyState showNoResultsText={showNoResultsText} />
        ) : (
          <VStack gap={5}>
            <Flex
              id={QC_LIST_ID}
              className={QC_LIST_ID}
              w="100%"
              height="calc(66vh - 3.75rem)"
              flexDir="column"
              paddingRight={isScrollbarDisplayed || quickCatalogItems.length >= 10 ? 2.5 : 0}
              paddingY={2}
              borderColor="neutral.300"
              borderTopWidth={1}
              borderBottomWidth={1}
              overflowX="hidden"
              overflowY={quickCatalogItems.length >= 10 ? 'scroll' : 'auto'}
            >
              {quickCatalogItems.map((product, index) => {
                const skeleton = (
                  <QuickCatalogProductSkeleton
                    key={`skeleton-${product.objectID}`}
                    index={index + 1}
                    product={product}
                    isLastCard={index === quickCatalogItems.length - 1}
                    showAddToOGButton={showAddToOrderGuideModal && FEATURE_FLAGS.QUICK_CATALOG_ADD_TO_OG}
                  />
                );

                if (isProcessing) {
                  return skeleton;
                }

                return (
                  <Suspense key={product.objectID} fallback={skeleton}>
                    <QuickCatalogVariant
                      product={product}
                      listIndex={index + 1}
                      lastProductIndex={quickCatalogItems.length - 1}
                      isLastCard={index === quickCatalogItems.length - 1}
                      resultsIndex={results.index}
                      showAddToOGButton={showAddToOrderGuideModal && FEATURE_FLAGS.QUICK_CATALOG_ADD_TO_OG}
                    />
                  </Suspense>
                );
              })}
            </Flex>
            <Box w="100%" display="flex" justifyContent="flex-end">
              <Button
                data-testid="qcmodal-results-button"
                width={{ base: '100%', md: 50 }}
                height={10}
                variant="ds-outlined"
                color="neutral.900"
                fontSize={{ base: 'base' }}
                fontWeight="bold"
                onClick={() => {
                  closeQuickCatalog();
                  if (closeMobileSearch) {
                    closeMobileSearch();
                  }
                  router.push(`${routes.SEARCH_RESULTS}?query=${debouncedInputValue}`);
                  setInputValue('');
                }}
              >
                {formatMessage({ id: 'qc.suggestions.product.allResults' })}{' '}
                <span>({formatNumberWithComma(results.nbHits)})</span>
              </Button>
            </Box>
          </VStack>
        )}
      </Box>
    </VStack>
  );
};
