import { Badge, Button, BadgeProps, ButtonProps, BoxProps, Icon, Tooltip } from '@chakra-ui/react';
import { useFormat } from 'helpers/hooks';
import { ListPlus } from 'phosphor-react';
import { FunctionComponent } from 'react';

export type AddToOrderGuideTooltipButtonProps = {
  badgeProps?: BadgeProps;
  buttonProps?: ButtonProps;
  filled?: boolean;
  isDisabled?: boolean;
  sku: string;
} & BoxProps;

export const AddToOrderGuideTooltipButton: FunctionComponent<AddToOrderGuideTooltipButtonProps> = ({
  filled = true,
  isDisabled = false,
  badgeProps,
  buttonProps,
  onClick,
  sku,
  ...rest
}) => {
  const { formatMessage } = useFormat({ name: 'common' });
  return (
    <Tooltip
      hasArrow
      placement="top"
      borderRadius="2px"
      color="neutral.200"
      background="neutral.900"
      label={formatMessage({ id: 'action.addToOrderGuide' })}
      aria-label={formatMessage({ id: 'aria.label.tooltip.addToOrderGuide', values: { value: sku } })}
      arrowShadowColor={'neutral.500'}
      border={`1px solid`}
      borderColor={'neutral.500'}
      isDisabled={isDisabled}
      {...rest}
    >
      <Badge
        p={0}
        m={2}
        top={0}
        right={0}
        zIndex={1}
        borderRadius="4px"
        position="absolute"
        bgColor={!filled && 'white'}
        onClick={!isDisabled ? onClick : null}
        aria-label={formatMessage({ id: 'aria.label.badge.addToOrderGuide', values: { value: sku } })}
        {...badgeProps}
      >
        <Button
          width={10}
          height={10}
          zIndex={1}
          cursor={isDisabled ? 'not-allowed' : 'pointer'}
          variant={filled ? 'ds-icon-button-default-filled' : 'ds-icon-button-light-muted-ghost'}
          boxShadow={filled && '0px 2px 4px 0px rgba(0, 0, 0, 0.10)'}
          _hover={isDisabled ? {} : filled ? { background: 'primary.500' } : { boxShadow: '0 0 0 2px' }}
          border={!filled && `1px solid`}
          borderColor={isDisabled ? 'neutral.200' : !filled && `greenGradient`}
          {...buttonProps}
        >
          <Icon
            as={ListPlus}
            color={isDisabled ? 'neutral.200' : filled ? 'white' : 'neutral.900'}
            width={5}
            height={5}
            aria-label={formatMessage({ id: 'aria.label.icon.addToOrderGuide', values: { value: sku } })}
          />
        </Button>
      </Badge>
    </Tooltip>
  );
};
