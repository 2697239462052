import { LineItem } from "@Types/cart/LineItem";
import { OrderDetailLineItem } from "@Types/shamrockApi/Order";
import { ExtraProductData } from "@Types/shamrockApi/Product";

export function findSURWithLocalInventorySKUs(extraDataArray: ExtraProductData[], lineItems: LineItem[] | OrderDetailLineItem[]): String[] {
  const skuList = [];
  (extraDataArray || []).forEach((product) => {
    const item = (lineItems as any[])?.find((lineItem) => {
      return lineItem?.productNumber === product.productNumber || lineItem?.productSlug === product.productNumber;
    });

    if (product?.companyInfo?.data?.isShipUponReceipt) {
      const localInventory = product.inventoryInfo?.data?.actualInventory || 0;
      if (item?.count <= localInventory) {
        skuList.push(product.productNumber)
      }
    }
  });
  return skuList;
}
