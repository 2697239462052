import { useCallback } from 'react';
import { Money } from '@Types/product/Money';
import { useGlobal } from 'components/globalProvider';
import {
  algoliaInsightAddedToCart,
  algoliaInsightAddedToCartAfterSearch,
} from 'composable/analytics/algolia/algolia-tracking';
import { AlgoliaInsightsAddedToCartSearchEventProps } from 'composable/analytics/algolia/types';
import { useLocalStorageAddedProducts } from './useLocalStorageAddedProducts';
import { ALGOLIA_BASE_INDEX } from 'composable/components/general';

type AddToCartAlgoliaTrackingArgs = {
  eventData: Partial<AlgoliaInsightsAddedToCartSearchEventProps>;
};
export const useAddToCartAlgoliaTracking = ({ eventData }: AddToCartAlgoliaTrackingArgs) => {
  const { cart } = useGlobal().useCartGlobal;
  const cartTotalAmount: Money = cart?.sum;
  const { state: user } = useGlobal().useUserGlobal;
  const customer = user?.ctUser?.customer;
  const { activeAccount } = user;
  const { addProduct } = useLocalStorageAddedProducts();

  const trackAddToCart = useCallback(
    (quantity: number) => {
      const { objectData, objectIDs, queryID = '', indexName = ALGOLIA_BASE_INDEX, customData } = eventData;
      const trackingEventProps: AlgoliaInsightsAddedToCartSearchEventProps = {
        user: customer,
        activeAccount,
        objectData: [
          {
            ...objectData[0],
            quantity,
          },
        ],
        totalValue: cartTotalAmount.centAmount / 100,
        objectIDs,
        customData,
      };
      if (queryID && quantity > 0) {
        algoliaInsightAddedToCartAfterSearch({ ...trackingEventProps, indexName, queryID });
        addProduct({
          queryID,
          appliedFilters: customData?.appliedFilters,
          displayedFilters: customData?.displayedFilters,
          objectIDs,
          indexName,
        });
        return;
      }

      if (quantity > 0) {
        algoliaInsightAddedToCart({ ...trackingEventProps });
      }
    },
    [activeAccount, customer, cartTotalAmount, eventData, cart],
  );

  return trackAddToCart;
};
