import { AxiosError } from 'axios';
import { AuthErrorKeys } from 'helpers/constants/auth';
import { LOGOUT_CALLERS } from 'hooks/global/use_privateUserGlobal/constants';

export const handleAxiosError = async (
  error: AxiosError,
  logout: (hardLogout?: boolean, error_message?: string, caller?: string) => Promise<void>,
) => {
  console.error('Error API call: handleAxiosError', error);
  if ([401, 403].includes(error?.response?.status)) {
    const errorPage = error?.response?.status === 403 ? AuthErrorKeys.Unauthorized : undefined;
    await logout(true, errorPage, LOGOUT_CALLERS.ERROR_ON_API_CALL + error?.config?.baseURL);
  }
};
