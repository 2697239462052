import { ChangeEvent, useCallback, useRef, useEffect } from 'react';
import { useRouter } from 'next/router';
import {
  ButtonProps,
  Box,
  Icon,
  Input,
  InputProps,
  InputGroup,
  InputGroupProps,
  InputRightElement,
  BoxProps,
  Flex,
  Image,
  useOutsideClick,
} from '@chakra-ui/react';
import { FEATURE_FLAGS } from 'composable/components/general';
import { useGlobalEscapeKeyPress } from 'composable/helpers/hooks/useGlobalEscapeKeyPress';
import routes from 'helpers/constants/routes';
import { useFormat } from 'helpers/hooks/useFormat';
import { MagnifyingGlass } from 'phosphor-react';
import { useGlobalSearch } from 'composable/components/global-search/context';

export interface SearchInputProps {
  isOpen?: boolean;
  clearSearchInput?: () => void;
  closeSearch?: () => void;
  formSubmitHandle?: (e: ChangeEvent<HTMLFormElement>) => void;
  inputGroupProps?: InputGroupProps;
  inputProps?: InputProps;
  buttonProps?: ButtonProps;
  overlayProps?: BoxProps;
  isMobileView?: boolean;
  closeMobileSearch?: () => void;
  showCloseInsideInput?: boolean;
}

export const SEARCH_INPUT_ID = 'global-search-bar';

export const SearchInput = ({
  isOpen,
  clearSearchInput,
  closeSearch,
  formSubmitHandle,
  inputGroupProps,
  inputProps,
  buttonProps,
  overlayProps,
  closeMobileSearch,
  showCloseInsideInput = true,
}: SearchInputProps) => {
  const { asPath } = useRouter();
  const { formatMessage } = useFormat({ name: 'common' });
  const inputRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const { setInputValue, openQuickCatalog } = useGlobalSearch();

  const isValueNotEmpty = inputProps?.value && inputProps?.value !== '';

  const handleCloseSearch = useCallback(() => {
    if (closeSearch) {
      closeSearch();
    }

    if (closeMobileSearch) {
      closeMobileSearch();
    }
  }, [closeSearch, closeMobileSearch]);

  useEffect(() => {
    const handleHotkey = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.shiftKey && e.key.toLowerCase() === 'j') {
        e.preventDefault();
        setInputValue('');
        openQuickCatalog();
      }
    };

    document.addEventListener('keydown', handleHotkey);
    return () => document.removeEventListener('keydown', handleHotkey);
  }, []);

  const handleEscapePress = useCallback(() => {
    const globalSearchInput = document.getElementById(SEARCH_INPUT_ID) as HTMLInputElement;
    if (globalSearchInput) {
      if (document.activeElement === globalSearchInput) {
        globalSearchInput.blur();
      } else {
        globalSearchInput.focus();
      }
    }
  }, []);

  const handleEscapeDoublePress = useCallback(() => {
    if (clearSearchInput) {
      clearSearchInput();
    }
    const globalSearchInput = document.getElementById(SEARCH_INPUT_ID) as HTMLInputElement;
    if (globalSearchInput) {
      globalSearchInput.value = '';
      globalSearchInput.focus();
    }
  }, [clearSearchInput]);

  const handleSearchIconClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (formRef.current) {
      const submitEvent = new Event('submit', { cancelable: true, bubbles: true }) as unknown as SubmitEvent;
      formSubmitHandle?.(submitEvent as any);
    }
  };

  useGlobalEscapeKeyPress(handleEscapePress, handleEscapeDoublePress, inputRef, asPath === routes.ORDER_LIST_PAGE);

  useEffect(() => {
    clearSearchInput();
  }, [asPath]);

  return (
    <Box position="relative" width="100%" px={{ base: 5, md: 14.5, lg: 15, xl: 0 }}>
      <form ref={formRef} onSubmit={formSubmitHandle}>
        <Flex alignItems="center">
          <InputGroup {...inputGroupProps}>
            <Input
              ref={inputRef}
              bg="rgba(255, 255, 255, 0.07)"
              border="1px solid"
              borderColor="neutral.700"
              fontSize="base"
              {...inputProps}
              variant="filled"
              aria-label={formatMessage({
                id: FEATURE_FLAGS.HEADER_CHANGES ? 'navigation.search.placeholder.v2' : 'navigation.search.placeholder',
              })}
              color="white"
              placeholder={formatMessage({
                id: FEATURE_FLAGS.HEADER_CHANGES ? 'navigation.search.placeholder.v2' : 'navigation.search.placeholder',
              })}
              _placeholder={{
                color: 'neutral.300',
                fontSize: 'base',
              }}
              _hover={{
                bg: 'rgba(255, 255, 255, 0.07)',
              }}
              _focus={{
                boxShadow: 'none',
                borderColor: 'secondary.600',
                bg: 'rgba(255, 255, 255, 0.07)',
                _placeholder: {
                  color: 'neutral.300',
                },
              }}
              id={SEARCH_INPUT_ID}
            />
            {isOpen ? (
              <InputRightElement width="fit-content" right={showCloseInsideInput ? 2 : 0}>
                {!!inputProps?.value && (
                  <>
                    <Box as="button">
                      <Image
                        src="/images/XCircle.svg"
                        mr="2"
                        onClick={() => {
                          clearSearchInput?.();
                          inputRef.current?.focus();
                        }}
                        alt={formatMessage({ id: 'action.cancel' })}
                      />
                    </Box>
                    {showCloseInsideInput && (
                      <Icon
                        as={MagnifyingGlass}
                        color="primary.400"
                        boxSize="5"
                        cursor="pointer"
                        onClick={handleSearchIconClick}
                        aria-label={formatMessage({ id: 'search.filter.icon.label', values: { value: '3' } })}
                      />
                    )}
                  </>
                )}
              </InputRightElement>
            ) : (
              <InputRightElement>
                <Icon
                  as={MagnifyingGlass}
                  color="primary.400"
                  boxSize="5"
                  cursor={isValueNotEmpty && 'pointer'}
                  onClick={isValueNotEmpty ? handleSearchIconClick : undefined}
                  aria-label={formatMessage({ id: 'search.filter.icon.label', values: { value: '3' } })}
                />
              </InputRightElement>
            )}
          </InputGroup>

          <Box
            display={{ base: 'block', xl: 'none' }}
            ml="3"
            as="button"
            type="button"
            color="white"
            textDecoration="none"
            zIndex={inputGroupProps?.zIndex}
            onClick={handleCloseSearch}
            fontWeight="bold"
            fontSize="xs"
            {...buttonProps}
          >
            {formatMessage({ id: 'action.close' })}
          </Box>
        </Flex>
      </form>

      <Box
        position="absolute"
        top="-25px"
        left="-16px"
        width="calc(100% + 32px)"
        borderBottomRadius="12px"
        {...overlayProps}
      />
    </Box>
  );
};
