import { AlgoliaAttributes } from 'composable/components/plp/constants';
import { useMemo } from 'react';
import { useInstantSearch } from 'react-instantsearch';

//This hook should use inside of PLP since we are using useInstantSearch hook
export const useGetAppliedAndDisplayedFilters = () => {
  const search = useInstantSearch();

  const appliedFilters = useMemo(() => {
    const { indexUiState } = search;
    const { configure, sortBy, ...restUiState } = indexUiState;
    return Object.values(restUiState || {}).reduce((acc, item) => {
      const key = Object.keys(item || {})[0];
      if (!key) {
        return acc;
      }
      acc[key] = item?.[key];
      return acc;
    }, {});
  }, [search.indexUiState]);

  const displayedFilters = useMemo(() => {
    const { results } = search;
    const { Categories, CategoryKeys, ...rest } = AlgoliaAttributes;
    return Object.values(rest).reduce((acc, attribute) => {
      const filters = results?.getFacetValues(attribute, {});

      const formattedFilters = (filters as any[])?.reduce((acc, item) => {
        acc[item.name] = item.count;
        return acc;
      }, {});
      return { ...acc, [attribute]: formattedFilters };
    }, {});
  }, [search.results]);

  return {
    appliedFilters,
    displayedFilters,
    queryID: search.results?.queryID,
    indexName: search.results?.index,
  };
};
