import { useState, useEffect, useMemo } from 'react';
import { ExtraProductData } from '@Types/shamrockApi/Product';
import { useGlobal } from 'components/globalProvider';
import { getDeliveryCutoff, shouldHandleCutOffDate } from 'composable/components/product-card-v2/helpers';
import { isAfter } from 'date-fns/isAfter';
import { isEqual } from 'date-fns/isEqual';
import { isSameDay } from 'date-fns/isSameDay';
import { useGetDeliveryDates } from './useGetDeliveryDates';
import { getActiveAnchorDate, handleDST } from '../utils/dst';

export type UseGetOrderByDateType = {
  showDeliveryDate: boolean;
  deliveryDate: { deliveryDay: Date; cutoffDate: Date };
  loadingDate: boolean;
};
export const useGetOrderByDate = (extraProductData: ExtraProductData) => {
  const { deliveryDates } = useGetDeliveryDates();
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [showDeliveryDate, setShowDeliveryDate] = useState(false);
  const [loadingDate, setLoadingDate] = useState(true);
  const { activeAccount } = useGlobal().useUserGlobal.state;

  const isProductCutoffBeforeBusinessUnitCutoff = () => {
    // Dates W Cutoffs
    const productWCutoff = deliveryDate?.cutoffDate?.setMilliseconds(0);
    const businessUnitWCutoff = deliveryDates?.orderBy?.orderByDate?.setMilliseconds(0);

    // Dates W/O Cutoffs
    const dateToCompareProduct = deliveryDate?.deliveryDay;
    const dateToCompareBusinessUnit = deliveryDates?.dates[0];

    // Check if dates W/O cutoff are the same
    const isSame = isSameDay(dateToCompareProduct, dateToCompareBusinessUnit);

    // Check if product date W/O cutoff is after business unit date W/O cutoff
    const isLater = isAfter(dateToCompareProduct, dateToCompareBusinessUnit);

    if (isSame) {
      const isEqualDate = isEqual(productWCutoff, businessUnitWCutoff);

      if (isEqualDate) {
        return false;
      } else {
        return isAfter(productWCutoff, businessUnitWCutoff) ? false : true;
      }
    }

    return isLater;
  };

  useEffect(() => {
    if (deliveryDates?.dates?.length && extraProductData && activeAccount) {
      const deliveryDate = getDeliveryCutoff(extraProductData, activeAccount, deliveryDates?.dates[0]);
      setDeliveryDate({
        deliveryDay: handleDST(getActiveAnchorDate(activeAccount), deliveryDate.deliveryDay),
        cutoffDate: handleDST(getActiveAnchorDate(activeAccount), deliveryDate.cutoffDate),
      });
      setLoadingDate(false);
    }
  }, [deliveryDates, extraProductData, activeAccount]);

  const handleCutoffDates = useMemo(
    () => extraProductData && shouldHandleCutOffDate(extraProductData),
    [extraProductData],
  );

  useEffect(() => {
    if (deliveryDate && handleCutoffDates) {
      setShowDeliveryDate(isProductCutoffBeforeBusinessUnitCutoff());
    }
  }, [deliveryDate, handleCutoffDates]);

  return {
    deliveryDate,
    showDeliveryDate,
    loadingDate,
  } as UseGetOrderByDateType;
};
