import { Dispatch, SetStateAction } from 'react';
import { Address } from '@Types/account/Address';
import { Order } from '@Types/cart/Order';
import { ShippingMethod } from '@Types/cart/ShippingMethod';
import { StripePaymentIntentParams, StripePaymentIntent } from '@Types/payment/stripe';

export type CheckoutStateType = {
  config: {
    billingIsShipping: boolean;
    saveNewAddressToMyAccount: boolean;
  };
  customer: {
    email: string;
  };
  shipping_address: {
    id: string;
    additional_address_info: string;
    first_name: string;
    last_name: string;
    phone_number: string;
    street_name: string;
    street_number: string;
    city: string;
    state: string;
    postcode: string;
    country: string;
  };
  billing_address: {
    id: string;
    first_name: string;
    last_name: string;
    street_name: string;
    street_number: string;
    city: string;
    state: string;
    postcode: string;
    country: string;
  };
  flow: Record<string, any>;
  shipping_method?: ShippingOption;
};

export interface CheckoutContextInterface {
  checkoutState: CheckoutStateType;
  checkoutStateInitial: CheckoutStateType;
  setCheckoutState: Dispatch<SetStateAction<CheckoutStateType>>;
  validation: CheckoutValidationHandler;
  validationResults: Record<string, boolean>;
  setValidationResults: Dispatch<SetStateAction<Record<string, boolean>>>;
  // response: {
  //   checkout: CreateMyOrderFromCartMutation['createMyOrderFromCart'] | undefined;
  //   payment: PaymentResponse | undefined;
  // };
  isLoading: boolean;
  // __setCheckoutResponse: Dispatch<SetStateAction<CreateMyOrderFromCartMutation['createMyOrderFromCart'] | undefined>>;
  // __setPaymentResponse: Dispatch<SetStateAction<PaymentResponse | undefined>>;
  __setIsLoading: Dispatch<SetStateAction<boolean>>;
  // cartSnapshot: Partial<CartFragment> | undefined;
  // __setCartSnapshot: Dispatch<SetStateAction<Partial<CartFragment> | undefined>>;
  // shippingOptions: {
  //   list: ShippingOption[];
  //   setSelected: (id: string) => void;
  //   clearSelection: () => void;
  //   selected: (ShippingOption & { rate?: Money }) | undefined;
  // };
  isLoadingStep1: boolean;
  setIsLoadingStep1: Dispatch<SetStateAction<boolean>>;
  savedShippingAddresses: {
    setSelected: (id: string) => void;
    clearSelection: () => void;
    selected: Address | undefined;
  };
  payment: {
    stripeData?: StripeData;
    setStripeData: Dispatch<SetStateAction<StripeData | undefined>>;
  };
  paymentHandler: PaymentMethodHandlerState;
  __createPaymentIntent: (data: StripePaymentIntentParams) => Promise<{ data: StripePaymentIntent }>;
  order: Order;
  setOrder: Dispatch<SetStateAction<Order>>;
}

export type CheckoutValidationFn = () => Promise<boolean>;

export type CheckoutValidationList = Partial<Record<string, CheckoutValidationFn>>;

export interface StripeData {
  customerId?: string;
  paymentMethodId?: string;
}

export type CheckoutValidationHandler = {
  register: (key: string, validation: CheckoutValidationFn) => void;
  unregister: (key: string) => void;
  run: (key?: string) => Promise<boolean>;
  list: CheckoutValidationList;
};

export type PaymentResponse = {
  ctPaymentId: string;
  [key: string]: any;
};

export type ShippingOption = {
  id: string;
  name: string;
  description?: string;
  zoneRates: ShippingMethod['rates'];
};

export type ShippingOptionsList = Record<ShippingOption['id'], ShippingOption>;

export type SavedShippingAddress = CheckoutStateType['shipping_address'];

export type SavedShippingAddressesList = Record<SavedShippingAddress['id'], SavedShippingAddress>;

export interface PaymentMethod {
  key: string;
  title: string;
  icon?: any;
  disabled?: boolean;
}

export interface PaymentMethodHandlerState {
  register: (paymentMethod: PaymentMethod) => void;
  select: (key?: string) => void;
  selected: PaymentMethod | undefined;
  list: PaymentMethod[];
  paymentMethodExtraData?: Record<string, any>;
  setPaymentMethodExtraData: Dispatch<SetStateAction<Record<string, any> | undefined>>;
}

export type FormStatus = 'success' | 'error' | undefined;

export interface ProductAttributes {
  'shamrock-standard_additional_info': {
    en: string;
  };
  'shamrock-standard_allergens': string;
  'shamrock-standard_brand': string;
  'shamrock-standard_brand_long_description': {
    en: string;
  };
  'shamrock-standard_cwt_indicator': boolean;
  'shamrock-standard_depth': number;
  'shamrock-standard_dimension_uom': string;
  'shamrock-standard_dry_indicator': boolean;
  'shamrock-standard_family_name': {
    en: string;
  };
  'shamrock-standard_frozen_indicator': boolean;
  'shamrock-standard_gluten_free_indicator': boolean;
  'shamrock-standard_green_indicator': boolean;
  'shamrock-standard_group_name': {
    en: string;
  };
  'shamrock-standard_gtin': string;
  'shamrock-standard_hazardous_indicator': boolean;
  'shamrock-standard_height': number;
  'shamrock-standard_ingredients': {
    en: string;
  };
  'shamrock-standard_kosher_indicator': {
    en: string;
  };
  'shamrock-standard_line_name': {
    en: string;
  };
  'shamrock-standard_mfgid': string;
  'shamrock-standard_net_weight': number;
  'shamrock-standard_nutrition_facts': string; // parse this JSON ?
  'shamrock-standard_organic_indicator': boolean;
  'shamrock-standard_origin_country': string;
  'shamrock-standard_pack_size': string;
  'shamrock-standard_pallet_hi': number;
  'shamrock-standard_pallet_qty': number;
  'shamrock-standard_pallet_ti': number;
  'shamrock-standard_portion_qty': number;
  'shamrock-standard_portion_uom': string;
  'shamrock-standard_prep_instructions': string; // You might need to parse this JSON
  'shamrock-standard_price_uom': string;
  'shamrock-standard_refrigerated_indicator': boolean;
  'shamrock-standard_service_item_indicator': boolean;
  'shamrock-standard_shamrock_brand': boolean;
  'shamrock-standard_shelf_life': number;
  'shamrock-standard_ship_weight': number;
  'shamrock-standard_temperature_zone': {
    en: string;
  };
  'shamrock-standard_uom': string;
  'shamrock-standard_upc': string;
  'shamrock-standard_weight_uom': string;
  'shamrock-standard_width': number;
  'shamrock-standard_marketing_messages': {
    en: string;
  };
}

export enum CheckoutPanels {
  DELIVERY_TO = 'DeliveryTo',
  BILL_TO = 'BillTo',
  SPECIAL_HANDLING_INSTRUCTIONS = 'SpecialHandlingInstructions',
  ORDER_IMPORTANT_INFORMATION = 'OrderImportantInformation',
}

export enum ProductAttributeKeys {
  AdditionalInfo = 'shamrock-standard_additional_info',
  Allergens = 'shamrock-standard_allergens',
  Brand = 'shamrock-standard_brand',
  BrandLongDescription = 'shamrock-standard_brand_long_description',
  CwtIndicator = 'shamrock-standard_cwt_indicator',
  Depth = 'shamrock-standard_depth',
  DimensionUom = 'shamrock-standard_dimension_uom',
  DryIndicator = 'shamrock-standard_dry_indicator',
  FamilyName = 'shamrock-standard_family_name',
  FrozenIndicator = 'shamrock-standard_frozen_indicator',
  GlutenFreeIndicator = 'shamrock-standard_gluten_free_indicator',
  GreenIndicator = 'shamrock-standard_green_indicator',
  GroupName = 'shamrock-standard_group_name',
  Gtin = 'shamrock-standard_gtin',
  Storage = 'shamrock-standard_storage',
  HazardousIndicator = 'shamrock-standard_hazardous_indicator',
  Height = 'shamrock-standard_height',
  Ingredients = 'shamrock-standard_ingredients',
  KosherIndicator = 'shamrock-standard_kosher_indicator',
  LineName = 'shamrock-standard_line_name',
  MfgId = 'shamrock-standard_mfgid',
  NetWeight = 'shamrock-standard_net_weight',
  NutritionFacts = 'shamrock-standard_nutrition_facts',
  OrganicIndicator = 'shamrock-standard_organic_indicator',
  OriginCountry = 'shamrock-standard_origin_country',
  PackSize = 'shamrock-standard_pack_size',
  PalletHi = 'shamrock-standard_pallet_hi',
  PalletQty = 'shamrock-standard_pallet_qty',
  PalletTi = 'shamrock-standard_pallet_ti',
  PortionQty = 'shamrock-standard_portion_qty',
  PortionUom = 'shamrock-standard_portion_uom',
  PrepInstructions = 'shamrock-standard_prep_instructions',
  PriceUom = 'shamrock-standard_price_uom',
  RefrigeratedIndicator = 'shamrock-standard_refrigerated_indicator',
  ServiceItemIndicator = 'shamrock-standard_service_item_indicator',
  ShamrockBrand = 'shamrock-standard_shamrock_brand',
  ShelfLife = 'shamrock-standard_shelf_life',
  ShipWeight = 'shamrock-standard_ship_weight',
  TemperatureZone = 'shamrock-standard_temperature_zone',
  Uom = 'shamrock-standard_uom',
  Upc = 'shamrock-standard_upc',
  WeightUom = 'shamrock-standard_weight_uom',
  Width = 'shamrock-standard_width',
  MarketingMessages = 'shamrock-standard_marketing_messages',
  RewardsPoints = 'shamrock-stanard_rewards_points',
  UncontrolledHazardIndicator = 'shamrock-standard_uncontrolled_hazard_indicator',
}

export enum PageVariant {
  PDP = 'pdp',
  PLP = 'plp',
  OrderGuide = 'order-guide',
  Checkout = 'checkout',
  Cart = 'cart',
  OrderGuideEdit = 'order-guide-edit',
  OrderEdit = 'order-edit',
  OrderDetails = 'order-details',
  TrendingItems = 'trending-items',
  QC = 'quick-catalog',
}

export enum AddOgOrigin {
  PDP = 'PDP',
  PLP = 'PLP',
  PDPCarousel = 'PDPCarousel',
}
