import { Cart } from '@Types/cart/Cart';
import { LineItem } from '@Types/cart/LineItem';
import { Order } from '@Types/cart/Order';
import { Product } from '@Types/product/Product';
import { ExtraProductData } from '@Types/shamrockApi/Product';
import { CURRENT_ALGOLIA_LOCALE } from 'composable/components/plp/constants';
import { getValueByLocale } from 'composable/components/plp/utils';
import { DEFAULT_CURRENCY } from 'helpers/constants/eventTracking';
import { UpdateCartType } from 'helpers/types/cart-types';
import { getCategoriesFromProduct, preciseMultiply } from 'helpers/utils/eventTracking';
import { cartItemsAnalyticsManager, viewProductAnalytics } from 'helpers/utils/productsAnalyticsManager';
import { analyticsClient } from './client';
import {
  GenericAddOGEvent,
  GenericCartDeleted,
  GenericItem,
  GenericNewCartRequested,
  GenericParSetup,
  GenericPrintBackDownloadEvent,
} from './generic/types';
import { ProductAttributeKeys } from 'composable/components/types';
import { GA_TRACK_CONSTANTS } from './ga4';

/**
 * Find an LineItem by line item id
 * @param cartItem
 * @param id
 */
const findCartItemByLineItemId = (cartItem: LineItem, id: string) => {
  return cartItem.lineItemId === id;
};

/**
 * Find an LineItem by line item sku
 * @param cartItem
 * @param id
 */
const findCartItemBySku = (cartItem: LineItem, sku: string) => {
  return cartItem.variant.sku === sku;
};

const formatPrice = (price: number = 0) => {
  return price / 100;
};

const removeCartItemAnalyticsData = (listItem) => {
  const { setCartData } = cartItemsAnalyticsManager();
  const cartAnalyticsData = setCartData(listItem, 'remove');
  return cartAnalyticsData;
};

const getCartAnalyticsData = () => {
  const { getCartData } = cartItemsAnalyticsManager();
  const cartAnalyticsData = getCartData();
  return cartAnalyticsData;
};

const getValueByLocaleFixed = (obj, locale = CURRENT_ALGOLIA_LOCALE) => {
  if (typeof obj === 'string') return obj;
  return obj?.value || obj?.[locale] || '';
};

/**
 * Prepare GA-compatible item data structure
 */
const prepareAnalyticsItem = (item: any, index: number = 0, itemListId?: string, itemListName?: string) => {
  const list_id = (itemListId || item.list_id || GA_TRACK_CONSTANTS.ITEM_LIST_ID_CATALOG).toString();
  const list_name = (itemListName || item.list_name || GA_TRACK_CONSTANTS.ITEM_LIST_ID_CATALOG).toString();

  return {
    id: item.id || '',
    name: item.name || '',
    price: item.price || 0,
    quantity: item.quantity || 1,
    brand: item.brand || '',
    list_id,
    list_name,
    index: item.index || index,
    categories: item.categories || [],
    ...(item.sku ? { sku: item.sku } : {}),
    ...(item.variant ? { variant: item.variant } : {}),
    ...(item.product_url ? { product_url: item.product_url } : {}),
    ...(item.image_url ? { image_url: item.image_url } : {}),
  };
};

export const mapAnalyticsDataToCart = (
  cart?: Partial<Cart>,
):
  | {
      cart_id: string;
      currency: string;
      value: number;
      items: Array<{
        id: string;
        name: string;
        price: number;
        quantity: number;
        brand: string;
        list_id: string;
        list_name: string;
        index: number;
        categories?: string[];
      }>;
    }
  | undefined => {
  if (!cart || !cart.lineItems?.length) {
    return undefined;
  }

  let itemOriginMap = {};
  try {
    const itemOriginMapStr = window.localStorage.getItem(GA_TRACK_CONSTANTS.ITEM_ORIGIN_MAP);
    if (itemOriginMapStr) {
      itemOriginMap = JSON.parse(itemOriginMapStr);
    }
  } catch (e) {
    console.error('Error retrieving product origin data:', e);
  }

  const items = cart.lineItems.map((lineItem, index) => {
    const sku = lineItem.variant?.sku || '';
    const price = lineItem.variant?.price?.centAmount ? formatPrice(lineItem.variant.price.centAmount) : 0;

    const brandAttribute = lineItem.variant?.attributes?.[ProductAttributeKeys.BrandLongDescription];
    const brand = brandAttribute
      ? typeof brandAttribute === 'string'
        ? brandAttribute
        : brandAttribute.value || ''
      : '';

    const categories = getCategoriesFromProduct(lineItem);
    let list_id = GA_TRACK_CONSTANTS.ITEM_LIST_ID_CATALOG;
    let list_name = GA_TRACK_CONSTANTS.ITEM_LIST_ID_CATALOG;

    if (itemOriginMap[sku]) {
      list_id = itemOriginMap[sku].list_id || list_id;
      list_name = itemOriginMap[sku].list_name || list_name;
    }

    return {
      id: sku,
      name: lineItem.name || '',
      price,
      quantity: lineItem.quantity || 1,
      brand,
      list_id,
      list_name,
      index,
      categories,
    };
  });

  const value = items.reduce((total, item) => total + item.price * (item.quantity || 1), 0);

  return {
    cart_id: (cart as any).id || (cart as any).cartId || '',
    currency: DEFAULT_CURRENCY,
    value,
    items,
  };
};

/**
 * Tracks a cartItem update
 */
export const analyticsTrackUpdateCart = (params: {
  lineItemId: string;
  quantity?: number;
  prevCart?: Partial<Cart>;
  nextCart?: Partial<Cart>;
  fromPAR: boolean;
}) => {
  const { lineItemId, quantity, prevCart, nextCart, fromPAR } = params;

  if (prevCart && nextCart) {
    const prevLineItem = prevCart.lineItems?.find((el) => findCartItemByLineItemId(el, lineItemId));

    if (prevLineItem && quantity === 0) {
      const product = {
        variant: prevLineItem.variant,
        name: prevLineItem.name,
      };

      analyticsTrackRemoveFromCart({
        product,
        cart: prevCart,
        quantity: prevLineItem.quantity,
      });
    }
  }
};

/**
 * Tracks the add to cart event
 */
export const analyticsTrackAddToCart = (params: {
  sku: string;
  quantity?: number;
  nextCart?: Partial<Cart>;
  fromPAR: boolean;
  itemListId?: string;
  itemListName?: string;
}) => {
  const { sku, quantity = 1, nextCart, fromPAR, itemListId, itemListName } = params;

  if (!sku) {
    return;
  }

  let list_id = GA_TRACK_CONSTANTS.ITEM_LIST_ID_CATALOG;
  let list_name = GA_TRACK_CONSTANTS.ITEM_LIST_ID_CATALOG;

  if (itemListId && itemListName) {
    list_id = itemListId;
    list_name = itemListName;
  } else {
    try {
      const itemOriginMapStr = localStorage.getItem(GA_TRACK_CONSTANTS.ITEM_ORIGIN_MAP) || '{}';
      const itemOriginMap = JSON.parse(itemOriginMapStr);

      if (itemOriginMap[sku]) {
        list_id = itemOriginMap[sku].list_id;
        list_name = itemOriginMap[sku].list_name;
      } else {
        const lastItemOriginStr = localStorage.getItem(GA_TRACK_CONSTANTS.LAST_ITEM_ORIGIN);
        if (lastItemOriginStr) {
          const lastItemOrigin = JSON.parse(lastItemOriginStr);
          list_id = lastItemOrigin.list_id;
          list_name = lastItemOrigin.list_name;
        }
      }
    } catch (e) {
      console.error('Error retrieving origin data:', e);
    }
  }

  try {
    const itemOrigin = {
      sku,
      list_id,
      list_name,
      timestamp: new Date().getTime(),
    };

    const itemOriginMapStr = localStorage.getItem(GA_TRACK_CONSTANTS.ITEM_ORIGIN_MAP) || '{}';
    const itemOriginMap = JSON.parse(itemOriginMapStr);
    itemOriginMap[sku] = itemOrigin;
    localStorage.setItem(GA_TRACK_CONSTANTS.ITEM_ORIGIN_MAP, JSON.stringify(itemOriginMap));
    localStorage.setItem(GA_TRACK_CONSTANTS.LAST_ITEM_ORIGIN, JSON.stringify(itemOrigin));
  } catch (e) {
    console.error('Error storing origin data:', e);
  }

  let rawItem: any = null;

  if (nextCart?.lineItems) {
    const lineItem = nextCart.lineItems.find((el) => findCartItemBySku(el, sku));

    if (lineItem) {
      const price = lineItem.variant?.price?.centAmount ? formatPrice(lineItem.variant.price.centAmount) : 0;

      const brandAttribute = lineItem.variant?.attributes?.[ProductAttributeKeys.BrandLongDescription];
      const brand = brandAttribute
        ? typeof brandAttribute === 'string'
          ? brandAttribute
          : brandAttribute.value || ''
        : '';

      const categories = getCategoriesFromProduct(lineItem);

      rawItem = {
        id: sku,
        name: lineItem.name || '',
        price,
        quantity: quantity || 1,
        brand,
        list_id,
        list_name,
        index: 0,
        categories,
      };
    }
  }

  if (!rawItem) {
    rawItem = {
      id: sku,
      quantity,
      price: 0,
      list_id,
      list_name,
    };
  }

  const trackingItem = prepareAnalyticsItem(rawItem, 0, list_id, list_name);

  analyticsClient.track({
    name: 'add_to_cart',
    params: {
      cart_id: nextCart?.cartId || '',
      currency: rawItem.price?.currencyCode || DEFAULT_CURRENCY,
      value: trackingItem.price * quantity,
      items: [trackingItem],
      fromPAR,
    },
  });
};

/**
 * Tracks the search event
 */
export const analyticsTrackSearch = (params: { algoliaState: any }) => {
  const { algoliaState } = params;
  const { page, refinementList, toggle, sortBy, query, configure } = algoliaState;
  const { query: configureQuery } = configure || {};

  analyticsClient.track({
    name: 'search',
    params: {
      search_term: query ?? configureQuery ?? '',
      page,
      refinement_list: refinementList,
      toggle,
      sort_by: sortBy,
    },
  });
};

/**
 * Tracks the remove from cart event
 */
export const analyticsTrackRemoveFromCart = ({
  product,
  cart,
  quantity,
  itemListId,
  itemListName,
}: {
  product: Partial<LineItem>;
  cart?: Partial<Cart>;
  quantity?: number;
  itemListId?: string;
  itemListName?: string;
}) => {
  if (!product) {
    return;
  }

  const productQuantity = quantity || product.quantity || 1;
  const price = getValueByLocaleFixed(product.variant?.price);
  const variantSku = product.variant?.sku;

  if (!variantSku) {
    return;
  }

  let itemOriginMap = {};
  let lastItemOrigin = null;

  try {
    const itemOriginMapStr = localStorage.getItem(GA_TRACK_CONSTANTS.ITEM_ORIGIN_MAP) || '{}';
    itemOriginMap = JSON.parse(itemOriginMapStr);
    const lastItemOriginStr = localStorage.getItem(GA_TRACK_CONSTANTS.LAST_ITEM_ORIGIN);
    if (lastItemOriginStr) {
      lastItemOrigin = JSON.parse(lastItemOriginStr);
    }

    if (itemOriginMap[variantSku]) {
      delete itemOriginMap[variantSku];
      localStorage.setItem(GA_TRACK_CONSTANTS.ITEM_ORIGIN_MAP, JSON.stringify(itemOriginMap));
    }
  } catch (e) {
    console.error('Error managing origin data:', e);
  }

  let list_id = GA_TRACK_CONSTANTS.ITEM_LIST_ID_CATALOG;
  let list_name = GA_TRACK_CONSTANTS.ITEM_LIST_ID_CATALOG;

  if (itemListId && itemListName) {
    list_id = itemListId;
    list_name = itemListName;
  } else if (itemOriginMap[variantSku]) {
    list_id = itemOriginMap[variantSku].list_id;
    list_name = itemOriginMap[variantSku].list_name;
  } else if (lastItemOrigin) {
    list_id = lastItemOrigin.list_id;
    list_name = lastItemOrigin.list_name;
  }

  const trackingItem = prepareAnalyticsItem(
    {
      id: variantSku,
      name: product.name || '',
      price,
      quantity: productQuantity,
      list_id,
      list_name,
      index: 0,
    },
    0,
  );

  analyticsClient.track({
    name: 'remove_from_cart',
    params: {
      cart_id: cart?.cartId || '',
      currency: DEFAULT_CURRENCY,
      value: price * productQuantity,
      items: [trackingItem],
    },
  });
};

export const analyticsTrackViewCart = (params: {
  cart?: Partial<Cart>;
  itemListId?: string;
  itemListName?: string;
}) => {
  const { cart, itemListId, itemListName } = params;

  if (!cart || !cart.lineItems?.length) {
    return;
  }

  let itemOriginMap = {};
  let lastItemOrigin = null;

  try {
    const itemOriginMapStr = localStorage.getItem(GA_TRACK_CONSTANTS.ITEM_ORIGIN_MAP) || '{}';
    itemOriginMap = JSON.parse(itemOriginMapStr);

    const lastItemOriginStr = localStorage.getItem(GA_TRACK_CONSTANTS.LAST_ITEM_ORIGIN);
    if (lastItemOriginStr) {
      lastItemOrigin = JSON.parse(lastItemOriginStr);
    }
  } catch (e) {
    console.error('Error retrieving origin data:', e);
  }

  const rawItems = cart.lineItems.map((lineItem, index) => {
    const sku = lineItem.variant?.sku || '';

    const price = lineItem.variant?.price?.centAmount ? formatPrice(lineItem.variant.price.centAmount) : 0;
    const brandAttribute = lineItem.variant?.attributes?.[ProductAttributeKeys.BrandLongDescription];
    const brand = brandAttribute
      ? typeof brandAttribute === 'string'
        ? brandAttribute
        : brandAttribute.value || ''
      : '';

    const categories = getCategoriesFromProduct(lineItem);
    let list_id = GA_TRACK_CONSTANTS.ITEM_LIST_ID_CATALOG;
    let list_name = GA_TRACK_CONSTANTS.ITEM_LIST_ID_CATALOG;

    if (itemListId && itemListName) {
      list_id = itemListId;
      list_name = itemListName;
      console.log(`[Analytics Debug] Using explicit params for ${sku}`);
    } else if (itemOriginMap[sku]) {
      list_id = itemOriginMap[sku].list_id;
      list_name = itemOriginMap[sku].list_name;
      console.log(`[Analytics Debug] Using item-specific origin for ${sku}:`, list_id, list_name);
    } else if (lastItemOrigin) {
      list_id = lastItemOrigin.list_id;
      list_name = lastItemOrigin.list_name;
      console.log(`[Analytics Debug] Using last origin for ${sku}:`, list_id, list_name);
    }

    return {
      id: sku,
      name: lineItem.name || '',
      price,
      quantity: lineItem.quantity || 1,
      brand,
      list_id,
      list_name,
      index,
      categories,
    };
  });

  const items = rawItems.map((item, index) => prepareAnalyticsItem(item, index, item.list_id, item.list_name));

  const value = items.reduce((total, item) => total + item.price * (item.quantity || 1), 0);

  analyticsClient.track({
    name: 'view_cart',
    params: {
      cart_id: cart.cartId || '',
      currency: DEFAULT_CURRENCY,
      value,
      items,
    },
  });
};

export const analyticsTrackBeginCheckout = (params: {
  cart?: Partial<Cart>;
  shippingMethodId?: string;
  itemListId?: string;
  itemListName?: string;
}) => {
  const { cart, shippingMethodId, itemListId, itemListName } = params;

  if (!cart || !cart.lineItems?.length) {
    return;
  }

  let itemOriginMap = {};
  let lastItemOrigin = null;

  try {
    const itemOriginMapStr = localStorage.getItem(GA_TRACK_CONSTANTS.ITEM_ORIGIN_MAP) || '{}';
    itemOriginMap = JSON.parse(itemOriginMapStr);

    const lastItemOriginStr = localStorage.getItem(GA_TRACK_CONSTANTS.LAST_ITEM_ORIGIN);
    if (lastItemOriginStr) {
      lastItemOrigin = JSON.parse(lastItemOriginStr);
    }

    console.log('[Analytics Debug] Begin Checkout - Recovered origins:', {
      itemOriginMap,
      lastItemOrigin,
      explicitParams: { itemListId, itemListName },
    });
  } catch (e) {
    console.error('Error retrieving origin data:', e);
  }

  const items = cart.lineItems.map((lineItem, index) => {
    const sku = lineItem.variant?.sku || '';

    const price = lineItem.variant?.price?.centAmount ? formatPrice(lineItem.variant.price.centAmount) : 0;

    const brandAttribute = lineItem.variant?.attributes?.[ProductAttributeKeys.BrandLongDescription];
    const brand = brandAttribute
      ? typeof brandAttribute === 'string'
        ? brandAttribute
        : brandAttribute.value || ''
      : '';

    const categories = getCategoriesFromProduct(lineItem);

    let list_id = GA_TRACK_CONSTANTS.ITEM_LIST_ID_CATALOG;
    let list_name = GA_TRACK_CONSTANTS.ITEM_LIST_ID_CATALOG;

    if (itemListId && itemListName) {
      list_id = itemListId;
      list_name = itemListName;
    } else if (itemOriginMap[sku]) {
      list_id = itemOriginMap[sku].list_id;
      list_name = itemOriginMap[sku].list_name;
    } else if (lastItemOrigin) {
      list_id = lastItemOrigin.list_id;
      list_name = lastItemOrigin.list_name;
    }

    return prepareAnalyticsItem(
      {
        id: sku,
        name: lineItem.name || '',
        price,
        quantity: lineItem.quantity || 1,
        brand,
        list_id,
        list_name,
        index,
        categories,
      },
      index,
    );
  });

  const value = items.reduce((total, item) => total + item.price * (item.quantity || 1), 0);

  const currency = DEFAULT_CURRENCY;

  analyticsClient.track({
    name: 'begin_checkout',
    params: {
      cart_id: cart.cartId || '',
      currency,
      value,
      coupon: shippingMethodId ?? '',
      items,
    },
  });
};

export const analyticsTrackAddShippingInfo = (params: { cart?: Partial<Cart>; shippingMethodId?: string }) => {
  const { cart, shippingMethodId } = params;
  if (!cart) {
    return;
  }

  const shippingMethodName = cart.availableShippingMethods.find(
    (shippingMethod) => shippingMethod.shippingMethodId === shippingMethodId,
  )?.name;

  analyticsClient.track({
    name: 'add_shipping_info',
    params: {
      value: formatPrice(cart?.sum?.centAmount),
      currency: cart.sum?.currencyCode ?? '',
      coupon: cart.discountCodes?.[0]?.code,
      shipping_tier: shippingMethodName,
      items:
        cart.lineItems?.map((el) => {
          return {
            item_id: el.productId ?? '',
            item_name: el.name ?? '',
            currency: el.totalPrice?.currencyCode ?? '',
            price: formatPrice(el.totalPrice?.centAmount),
            quantity: el.count ?? 0,
          };
        }) ?? [],
    },
  });
};

export const analyticsTrackAddPaymentInfo = (params: { cart?: Partial<Cart>; paymentType?: string }) => {
  const { cart, paymentType } = params;
  if (!cart) {
    return;
  }

  analyticsClient.track({
    name: 'add_payment_info',
    params: {
      value: formatPrice(cart?.sum?.centAmount),
      currency: cart?.sum?.currencyCode ?? '',
      coupon: cart.discountCodes?.[0]?.code,
      payment_type: paymentType ?? '',
      items:
        cart.lineItems?.map((el) => {
          return {
            item_id: el.productId ?? '',
            item_name: el.name ?? '',
            currency: el.totalPrice?.currencyCode ?? '',
            price: formatPrice(el.totalPrice?.centAmount),
            quantity: el.count ?? 0,
          };
        }) ?? [],
    },
  });
};

export const analyticsTrackPurchase = (params: {
  order?: Partial<Order>;
  itemListId?: string;
  itemListName?: string;
}) => {
  const { order, itemListId, itemListName } = params;

  if (!order || !order.lineItems?.length) {
    return;
  }

  let itemOriginMap = {};
  let lastItemOrigin = null;

  try {
    const itemOriginMapStr = localStorage.getItem(GA_TRACK_CONSTANTS.ITEM_ORIGIN_MAP) || '{}';
    itemOriginMap = JSON.parse(itemOriginMapStr);

    const lastItemOriginStr = localStorage.getItem(GA_TRACK_CONSTANTS.LAST_ITEM_ORIGIN);
    if (lastItemOriginStr) {
      lastItemOrigin = JSON.parse(lastItemOriginStr);
    }
  } catch (e) {
    console.error('Error retrieving origin data:', e);
  }

  const items = order.lineItems.map((lineItem, index) => {
    const sku = lineItem.variant?.sku || '';
    const price = lineItem.variant?.price?.centAmount ? formatPrice(lineItem.variant.price.centAmount) : 0;
    const brandAttribute = lineItem.variant?.attributes?.[ProductAttributeKeys.BrandLongDescription];
    const brand = brandAttribute
      ? typeof brandAttribute === 'string'
        ? brandAttribute
        : brandAttribute.value || ''
      : '';

    let list_id = GA_TRACK_CONSTANTS.ITEM_LIST_ID_CATALOG;
    let list_name = GA_TRACK_CONSTANTS.ITEM_LIST_ID_CATALOG;

    if (itemListId && itemListName) {
      list_id = itemListId;
      list_name = itemListName;
    } else if (itemOriginMap[sku]) {
      list_id = itemOriginMap[sku].list_id;
      list_name = itemOriginMap[sku].list_name;
    } else if (lastItemOrigin) {
      list_id = lastItemOrigin.list_id;
      list_name = lastItemOrigin.list_name;
    }

    return prepareAnalyticsItem(
      {
        id: sku,
        name: lineItem.name || '',
        price,
        quantity: lineItem.quantity || 1,
        brand,
        list_id,
        list_name,
        index,
      },
      index,
    );
  });

  const value = items.reduce((total, item) => total + item.price * (item.quantity || 1), 0);

  const shipping = order.shippingInfo?.price?.centAmount ? formatPrice(order.shippingInfo.price.centAmount) : 0;

  const tax = order.taxedPrice?.totalTax?.centAmount ? formatPrice(order.taxedPrice.totalTax.centAmount) : 0;

  try {
    localStorage.removeItem(GA_TRACK_CONSTANTS.ITEM_ORIGIN_MAP);
  } catch (e) {
    console.error('Error clearing origin map:', e);
  }

  analyticsClient.track({
    name: 'purchase',
    params: {
      transaction_id: (order as any).id || (order as any).orderId || '',
      currency: DEFAULT_CURRENCY,
      value,
      shipping,
      tax,
      items,
    },
  });
};

export const analyticsTrackViewItem = (params: {
  product?: Partial<Product>;
  extraProductData?: Partial<ExtraProductData>;
  cart?: Cart;
  itemListId?: string;
  itemListName?: string;
}) => {
  const { product, extraProductData, cart, itemListId, itemListName } = params;
  if (!product || !extraProductData) {
    return;
  }

  const firstVariant = product.variants?.[0];
  const sku = firstVariant?.sku;
  const brand = firstVariant?.attributes['shamrock-standard_brand_long_description'];
  const price = extraProductData.pricingInfo?.data?.discountedPrice;

  const item = cart?.lineItems?.find((el) => findCartItemBySku(el, sku));
  const quantity = item?.count ?? 0;

  const { getViewProduct } = viewProductAnalytics();
  const viewProductData = getViewProduct();
  const index = viewProductData.productId === sku ? viewProductData.index : undefined;
  analyticsClient.track({
    name: 'view_item',
    params: {
      value: preciseMultiply(formatPrice(price), quantity),
      currency: DEFAULT_CURRENCY,
      items: [
        {
          id: sku ?? '',
          name: product?.name ?? '',
          price: formatPrice(price),
          quantity,
          brand: brand ?? '',
          categories: getCategoriesFromProduct(product),
          index,
          list_id: itemListId,
          list_name: itemListName,
        },
      ],
    },
  });
};

export const analyticsTrackSignUp = ({ method }: { method?: string }) => {
  analyticsClient.track({
    name: 'sign_up',
    params: {
      method,
    },
  });
};

export const analyticsTrackLogin = ({ method, customerNumber }: { method?: string; customerNumber?: string }) => {
  analyticsClient.track({
    name: 'login',
    params: {
      method,
      customerNumber,
    },
  });
};

export const analyticsTrackViewItemList = ({
  itemListId,
  itemListName,
  items,
}: {
  itemListId: string;
  itemListName: string;
  items: GenericItem[];
}) => {
  analyticsClient.track({
    name: 'view_item_list',
    params: {
      list_id: itemListId,
      list_name: itemListName,
      items,
    },
  });
};

export const analyticsTrackSelectItem = ({
  itemListId,
  itemListName,
  item,
}: {
  itemListId: string;
  itemListName: string;
  item: GenericItem;
}) => {
  analyticsClient.track({
    name: 'select_item',
    params: {
      item_list_id: itemListId,
      item_list_name: itemListName,
      items: [item],
    },
  });
};

export const analyticsTrackAddToOrderGuide = (params: GenericAddOGEvent['params']) => {
  analyticsClient.track({
    name: 'add_to_orderguide',
    params,
  });
};

export const analyticsTrackCart = (cartResponse: UpdateCartType & { fromPAR: boolean }) => {
  if (!cartResponse?.itemId) {
    return;
  }

  if (cartResponse?.quantity === 1 && !!cartResponse?.nextCart) {
    analyticsTrackAddToCart({
      sku: cartResponse?.itemId,
      quantity: cartResponse?.quantity,
      nextCart: cartResponse?.nextCart,
      fromPAR: cartResponse.fromPAR,
    });
  } else if (cartResponse?.quantity > 1 && !!cartResponse?.nextCart && !!cartResponse?.prevCart) {
    analyticsTrackUpdateCart({
      lineItemId: cartResponse?.itemId,
      quantity: cartResponse?.quantity,
      nextCart: cartResponse?.nextCart,
      prevCart: cartResponse?.prevCart,
      fromPAR: cartResponse.fromPAR,
    });
  } else if (cartResponse?.quantity === 0 && !!cartResponse?.nextCart && !!cartResponse?.prevCart) {
    const prevLineItem = cartResponse?.prevCart?.lineItems?.find((el) =>
      findCartItemByLineItemId(el, cartResponse?.itemId),
    );

    if (prevLineItem) {
      const product = {
        variant: prevLineItem.variant,
        name: prevLineItem.name,
      };

      analyticsTrackRemoveFromCart({
        product,
        cart: cartResponse?.prevCart,
        quantity: prevLineItem.quantity,
      });
    }
  }
};

export const analyticsTrackPrintBackDownload = (params: GenericPrintBackDownloadEvent['params']) => {
  analyticsClient.track({
    name: 'printback_download',
    params,
  });
};

export const analyticsTrackNewCartRequested = (params: GenericNewCartRequested['params']) => {
  analyticsClient.track({
    name: 'new_cart_requested',
    params,
  });
};

export const analyticsTrackCartDeleted = (params: GenericCartDeleted['params']) => {
  analyticsClient.track({
    name: 'cart_deleted',
    params,
  });
};

export const analyticsTrackParSetup = (params: GenericParSetup['params']) => {
  analyticsClient.track({ name: 'par_setup', params });
};
