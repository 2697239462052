import { useEffect, useState } from 'react';
import { productIsActive, productIsSpecialOrder } from 'helpers/utils/productTypeHandler';
import { useProductExtraData } from './useProductExtraData';

export const useActiveProductsList = (productsList, attachExtraData: boolean = false) => {
  const [filterProductData, setFilterProductData] = useState([]);

  // Get line items from the search results
  const lineItems = productsList?.map((item) => item?.sku);
  const lineItemsSkus = lineItems ? [...lineItems] : [];
  const { extraProductData, isLoadingShamrockData } = useProductExtraData(lineItemsSkus);

  useEffect(() => {
    if (!isLoadingShamrockData && productsList && productsList.length > 0) {
      // Filter out search products that are not active
      const searchResults = productsList?.reduce((acc, hit) => {
        const searchItemExtraData = extraProductData.find((product) => product?.productNumber === hit?.sku);
        const modifiedHit = attachExtraData ? { ...hit, extraData: searchItemExtraData } : hit;
        const isActive = productIsActive(searchItemExtraData);
        const isSpecialOrder = productIsSpecialOrder(searchItemExtraData);

        if (searchItemExtraData && isActive && !isSpecialOrder) {
          acc.push(modifiedHit);
        }

        return acc;
      }, []);
      setFilterProductData(searchResults);
    }
  }, [isLoadingShamrockData, JSON.stringify(lineItemsSkus)]);

  return {
    searchResults: filterProductData,
    isProcessing: isLoadingShamrockData,
  };
};
