import { useEffect, useMemo } from 'react';
import { Box, Flex, Wrap, TextProps } from '@chakra-ui/react';
import { Product } from '@Types/product/Product';
import { Variant } from '@Types/product/Variant';
import { useGlobal } from 'components/globalProvider';
import { AddToOrderGuideTooltipButton } from 'composable/components/add-to-order-guide-tooltip-button';
import { FEATURE_FLAGS } from 'composable/components/general';
import { useGlobalSearch } from 'composable/components/global-search/context';
import { getFirstApplicableSubstituteType } from 'composable/components/pdp';
import { ProductBadges } from 'composable/components/pdp/components/parts/product-badges';
import { useQuantityPickerFocus } from 'composable/components/plp/hooks/useQuantityPickerFocus';
import { Price } from 'composable/components/price';
import { getProductDetails } from 'composable/components/product-card-v2/helpers';
import { HitWithExtraData } from 'composable/components/qc-search/type';
import { QuantityComponent } from 'composable/components/quantity-picker';
import { useQuickCatalogPickerHelpers } from 'composable/components/quick-catalog/hooks/use-quick-catalog-picker-helpers';
import { QuickCatalogModalProductImage } from 'composable/components/quick-catalog/parts';
import { RewardPoints } from 'composable/components/reward-points';
import { PageVariant, ProductAttributeKeys } from 'composable/components/types';
import { useGetOrderByDate } from 'composable/helpers/hooks/useGetOrderByDate';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useAddToCartAlgoliaTracking } from 'helpers/hooks/useAddToCartAlgoliaTracking';
import { useGetAppliedAndDisplayedFilters } from 'helpers/hooks/useAppliedAndDisplayedFilters';
import * as Parts from './parts';
import { useQuickCatalogTracking } from 'composable/components/quick-catalog/hooks/useQuickCatalogTracking';
import { GA_TRACK_CONSTANTS } from 'composable/analytics/ga4';

type QuickCatalogVariantProps = {
  product: HitWithExtraData;
  listIndex: number;
  isLastCard?: boolean;
  resultsIndex?: string;
  showAddToOGButton?: boolean;
  lastProductIndex: number;
};

const { REWARDS_POINTS } = FEATURE_FLAGS;

export const QuickCatalogVariant = ({
  product,
  listIndex,
  isLastCard = false,
  resultsIndex,
  showAddToOGButton = false,
  lastProductIndex,
}: QuickCatalogVariantProps) => {
  const { openAddToOGInQuickCatalog, debouncedInputValue } = useGlobalSearch();
  const { isEditingOrder } = useGlobal().useEditOrderGlobal;
  const { trendingItemsList: trendingProducts } = useGlobal().useTrendingItemsGlobal;
  const { cart } = useGlobal().useCartGlobal;
  const {
    productName,
    brand,
    slug,
    sku,
    packSize,
    storage,
    description,
    isNonFoodProduct,
    imageURL,
    productPrice,
    status,
    currentPrice,
    discountedPrice,
    isOrderable,
    isCatchWeightItem,
    pricePerUnit,
    priceUom,
    portionUom,
  } = getProductDetails(product as unknown as Product, product.extraData);

  const infoTextSmallProps: TextProps = { fontSize: 'xs', lineHeight: '3.9', noOfLines: 1 };

  // Data
  const { deliveryDate, showDeliveryDate } = useGetOrderByDate(product.extraData);

  // Badges
  const exclusive =
    product?.attributes?.[ProductAttributeKeys.ShamrockBrand] ??
    product?.variants?.[0]?.attributes?.[ProductAttributeKeys.ShamrockBrand];
  const isPromotion = product.extraData?.companyInfo?.data?.isPromotion;
  const trending = useMemo(() => {
    return trendingProducts?.some((item) => item?.productId === sku);
  }, [sku, trendingProducts]);

  // Reward Points
  const shouldDisplayRewards = REWARDS_POINTS;
  const variant = (product?.variants?.[0] || product) as Variant;
  const rewardsPointsComponent = useMemo(
    () => (
      <RewardPoints
        variant={variant}
        size={4.5}
        containerProps={{
          fontSize: 'xs',
        }}
      />
    ),
    [variant],
  );

  // Quantity Picker
  const {
    productCategories,
    shouldDisable: disableQuantityPicker,
    perItemErrorMessage,
    warningMessage,
  } = useQuickCatalogPickerHelpers(product);

  const { showQuantityPicker, setShowQuantityPicker, isCardFocused, setIsCardFocused, quantityRef } =
    useQuantityPickerFocus(isOrderable);

  const { displayedFilters, appliedFilters } = useGetAppliedAndDisplayedFilters();

  // @TODO: fix in SHAM-3100
  // removing quickCatalog tracking as it does not accomplishes the desired functionality
  // const quickCatalogTracking = useQuickCatalogTracking();

  const trackAddToCart = useAddToCartAlgoliaTracking({
    eventData: {
      queryID: product.__queryID,
      objectIDs: [product.objectID],
      objectData: [
        {
          price: discountedPrice / 100,
          discount: (currentPrice - discountedPrice) / 100,
        },
      ],
      indexName: resultsIndex,
      customData: {
        customObjectData: {
          productNumber: sku,
          brand,
          unitPrice: pricePerUnit,
        },
        appliedFilters,
        displayedFilters,
        item_list_id: GA_TRACK_CONSTANTS.ITEM_LIST_QUICK_CATALOG,
        item_list_name: debouncedInputValue || '',
      },
    },
  });

  const handleAddToCart = (quantity: number) => {
    if (!sku || quantity <= 0) {
      return;
    }

    try {
      const itemOrigin = {
        sku,
        list_id: GA_TRACK_CONSTANTS.ITEM_LIST_QUICK_CATALOG,
        list_name: debouncedInputValue || GA_TRACK_CONSTANTS.ITEM_LIST_QUICK_CATALOG,
        timestamp: new Date().getTime(),
      };

      localStorage.setItem(GA_TRACK_CONSTANTS.LAST_ITEM_ORIGIN, JSON.stringify(itemOrigin));
      const itemOriginMapStr = localStorage.getItem(GA_TRACK_CONSTANTS.ITEM_ORIGIN_MAP) || '{}';
      const itemOriginMap = JSON.parse(itemOriginMapStr);
      itemOriginMap[sku] = itemOrigin;
      localStorage.setItem(GA_TRACK_CONSTANTS.ITEM_ORIGIN_MAP, JSON.stringify(itemOriginMap));
    } catch (e) {
      console.error('Error storing origin data:', e);
    }

    // @TODO: fix in SHAM-3100
    // quickCatalogTracking.trackAddToCart({
    //   sku,
    //   quantity,
    //   fromPAR: false,
    //   nextCart: cart,
    // });
  };

  const handleViewItem = () => {
    // Avoid multiple calls for the same product
    // Use localStorage to track when we've already sent this event for this product
    try {
      const viewedItemsStr = localStorage.getItem('viewed_items') || '{}';
      const viewedItems = JSON.parse(viewedItemsStr);

      // Check if we've already viewed this item recently (last 5 minutes)
      const now = new Date().getTime();
      const lastViewed = viewedItems[product.objectID];

      if (lastViewed && now - lastViewed < 300000) {
        console.log('[Analytics Debug] Skipping duplicate view_item event for:', product.objectID);
        return;
      }

      viewedItems[product.objectID] = now;
      localStorage.setItem('viewed_items', JSON.stringify(viewedItems));
    } catch (e) {
      console.error('Error tracking viewed items:', e);
    }

    // @TODO: fix in SHAM-3100
    // quickCatalogTracking.trackViewItem({
    //   product: {
    //     id: sku,
    //     name: productName,
    //     variants: [
    //       {
    //         sku: sku,
    //         attributes: {
    //           [ProductAttributeKeys.BrandLongDescription]: brand || 'Unknown Brand',
    //         },
    //       },
    //     ],
    //     categories: productCategories || [],
    //   },
    //   extraProductData: {
    //     ...product.extraData,
    //   },
    //   cart,
    // });
  };

  const handleSelectItem = () => {
    const itemParams = {
      id: sku,
      name: productName,
      price: discountedPrice / 100,
      sku: sku,
      quantity: 1,
      image_url: imageURL,
      index: listIndex,
      categories: productCategories,
      brand,
      list_id: GA_TRACK_CONSTANTS.ITEM_LIST_QUICK_CATALOG,
      list_name: debouncedInputValue || GA_TRACK_CONSTANTS.ITEM_LIST_QUICK_CATALOG,
    };

    // @TODO: fix in SHAM-3100
    // quickCatalogTracking.trackSelectItem({
    //   item: itemParams,
    // });
  };

  const handleAddToOrderGuide = () => {
    // @TODO: fix in SHAM-3100
    // quickCatalogTracking.trackAddToOrderGuide({
    //   id: sku,
    //   name: productName,
    //   price: discountedPrice / 100,
    //   sku: sku,
    //   quantity: 1,
    // });
  };

  return (
    <Parts.Card
      data-testid={`qc-modal-product-${listIndex}`}
      flexDir={{ base: 'column', lg: 'row' }}
      justifyContent={{ lg: 'space-between' }}
      borderColor="neutral.200"
      borderRadius={0}
      borderWidth={0}
      borderBottomWidth={1}
      marginBottom={!isLastCard && 3}
      paddingBottom={3}
      data-insights-object-id={product.objectID}
      data-insights-position={product.__position}
      data-insights-query-id={product.__queryID}
      onClick={(e) => {
        if (e.currentTarget === e.target) {
          handleSelectItem();
        }
      }}
    >
      <Flex
        width={{ base: '100%', lg: 'fit-content' }}
        gap={3}
        flex={1}
        justifyContent="space-between"
        alignItems="flex-start"
        paddingRight={{ lg: 2 }}
      >
        <QuickCatalogModalProductImage
          productName={productName}
          priority={listIndex <= 3}
          slug={slug}
          imageURL={imageURL}
          width={12}
          height={12}
          onClick={(e) => {
            e.stopPropagation();
            handleViewItem();
            handleSelectItem();
          }}
        />
        <Flex
          width="100%"
          flexDir={{ base: 'column', lg: 'row' }}
          alignItems={{ lg: 'flex-start' }}
          gap={{ base: 1, lg: 0 }}
        >
          <Flex flexDir="column" height="fit-content" gap={1} flex={1}>
            <Parts.Info p={0} spacing={0}>
              <Wrap spacingX={2} spacingY={0}>
                <Parts.Brand clickable textProps={infoTextSmallProps}>
                  {brand}
                </Parts.Brand>
                <Parts.Id textProps={infoTextSmallProps}>{sku}</Parts.Id>
                <Parts.PackSize textProps={infoTextSmallProps}>{packSize}</Parts.PackSize>
              </Wrap>
            </Parts.Info>
            <Parts.Name
              clickable
              slug={slug}
              textProps={{
                fontSize: 'sm',
                fontWeight: 'semibold',
                lineHeight: '4.5',
              }}
              onClick={() => {
                handleViewItem();
                handleSelectItem();
              }}
            >
              {productName}
            </Parts.Name>
            <Flex flexDir={{ base: 'column', lg: 'row' }} gap={{ lg: 2 }}>
              {!isNonFoodProduct && (
                <Parts.Description
                  textProps={{
                    textTransform: 'uppercase',
                    fontWeight: 'medium',
                    lineHeight: '3.9',
                  }}
                >
                  {storage}
                </Parts.Description>
              )}
              <Parts.Description
                textProps={{
                  whiteSpace: { base: 'normal', md: 'nowrap' },
                  lineHeight: '3.9',
                }}
              >
                {description && description.charAt(0).toLocaleUpperCase() + description.slice(1).toLocaleLowerCase()}
              </Parts.Description>
            </Flex>
            <Parts.ShippingMessage
              sku={sku}
              deliveryDate={deliveryDate}
              showDeliveryDate={showDeliveryDate}
              textProps={{ fontSize: 'xs', 'data-testid': `product-order-by-${sku}` }}
              stackProps={{ mb: 0 }}
              hideIcon
            />
            <Parts.SubstitutionNote
              textProps={{ fontSize: 'xs' }}
              shamrockProductSubstitution={getFirstApplicableSubstituteType(product.extraData?.productSubstitutions)}
              productNumber={sku}
              boxProps={{ mb: 0 }}
            />
          </Flex>
          <Flex width="fit-content" gap={3}>
            {(exclusive || isPromotion || trending) && (
              <Flex w="100%" alignItems="center" minH={6} flexWrap="wrap" columnGap={3} rowGap={1}>
                <ProductBadges
                  sku={sku}
                  exclusive={exclusive}
                  isPromotion={isPromotion}
                  enableText
                  trending={trending}
                  enableTooltip={true}
                  textProps={{ color: 'neutral.200' }}
                />
              </Flex>
            )}
            {shouldDisplayRewards && rewardsPointsComponent}
          </Flex>
        </Flex>
      </Flex>
      <Flex
        w={{ lg: '283px' }}
        marginTop={{ base: 2, lg: 0 }}
        paddingTop={{ base: 2, lg: 0 }}
        paddingLeft={{ base: 0, lg: 2 }}
        borderTopWidth={{ base: 1, lg: 0 }}
        borderLeftWidth={{ base: 0, lg: 1 }}
        borderColor="neutral.50"
      >
        <Parts.PriceCluster
          width="100%"
          height="fit-content"
          gap={{ base: 0, lg: 1 }}
          py={0}
          px={0}
          borderTopWidth={0}
          borderLeftWidth={0}
        >
          <Flex width="100%" justify="space-between">
            <Box>
              <Flex alignItems="center">
                <Parts.Bullet
                  nextDeliveryDueDates={product.extraData?.nextDeliveryDueDates}
                  status={status}
                  sku={sku}
                  availableQuantity={product.extraData?.inventoryInfo?.data?.availableQuantity}
                  flexProps={{
                    alignItems: 'flex-start',
                    lineHeight: '15px',
                    mr: 1,
                    minW: '9px',
                  }}
                />
                <Price
                  rootProps={{ display: 'flex', flexDir: { base: 'row', md: 'column' } }}
                  priceProps={{
                    fontWeight: 'bold',
                    fontSize: 'sm',
                    lineHeight: '16.8px',
                    color: 'yellow.600',
                    w: 'fit-content',
                  }}
                  specialPriceProps={{
                    fontWeight: 'normal',
                    fontSize: 'xs',
                    textDecoration: 'line-through',
                    lineHeight: '14.4px',
                    color: 'neutral.600',
                    w: 'fit-content',
                    pt: { base: 0.5, md: 0 },
                  }}
                  regularPrice={currentPrice ? CurrencyHelpers.formatForCurrency(currentPrice) : '$0.00'}
                  specialPrice={discountedPrice ? CurrencyHelpers.formatForCurrency(discountedPrice) : '$0.00'}
                  pageVariant={PageVariant.PLP}
                />
              </Flex>
              {isCatchWeightItem ? (
                <Parts.PricePerUnit
                  textProps={{
                    w: { base: 18, md: 16.5 },
                    fontSize: 'xs',
                    lineHeight: { md: '14.4px' },
                    pt: 1 / 2,
                  }}
                >
                  {pricePerUnit}/{priceUom}
                </Parts.PricePerUnit>
              ) : (
                <Parts.PricePerUnit
                  textProps={{
                    w: { base: 18, md: 16.5 },
                    fontSize: 'xs',
                    lineHeight: { md: '14.4px' },
                    pt: 1 / 2,
                  }}
                >
                  {pricePerUnit}/{portionUom}
                </Parts.PricePerUnit>
              )}
            </Box>
            <Box display="flex" gap={3}>
              <Parts.Quantity
                component={QuantityComponent.QC_VARIANT}
                price={productPrice}
                min={0}
                sku={sku}
                productName={productName}
                status={status}
                hideLabel={true}
                showAddToCartButton={true}
                index={listIndex - 1}
                lastProductIndex={lastProductIndex}
                productCategories={productCategories}
                isDisabled={disableQuantityPicker}
                addToCartProps={{ size: 'ds-md' }}
                onAddToCart={handleAddToCart}
                isError={!!perItemErrorMessage}
                stackProps={{ width: '108px', height: 8 }}
                _container={{
                  borderColor: isCardFocused ? 'violet.400' : 'neutral.500',
                  padding: 1.5,
                  height: 8,
                  ...(isCardFocused && {
                    outlineColor: 'violet.400',
                    outlineWidth: '1px',
                    outlineStyle: 'solid',
                  }),
                }}
                qtyButtonProps={{ minW: 5, h: 5 }}
                forceShowQuantityPicker={showQuantityPicker}
                onQuantityChange={(newQuantity) => {
                  if (newQuantity === 0) {
                    setShowQuantityPicker(false);
                    setIsCardFocused(false);
                  }
                }}
                ref={quantityRef}
                errorMessageInside
              />
              {showAddToOGButton && (
                <Box
                  data-testid={`qc-product-${listIndex}-add-to-og`}
                  w="35px"
                  h="35px"
                  position="relative"
                  onClick={() => {
                    openAddToOGInQuickCatalog(product);
                    handleAddToOrderGuide();
                  }}
                >
                  <AddToOrderGuideTooltipButton
                    sku={sku}
                    badgeProps={{
                      m: 0,
                    }}
                    buttonProps={{
                      size: 'ds-md',
                    }}
                    filled={false}
                    isDisabled={isEditingOrder || !showAddToOGButton}
                  />
                </Box>
              )}
            </Box>
          </Flex>
          <Box display="flex" flexDirection="column" width="100%" gap={1}>
            <Parts.StockStatus
              nextDeliveryDueDates={product.extraData?.nextDeliveryDueDates}
              status={status}
              availableQuantity={product.extraData?.inventoryInfo?.data?.availableQuantity}
              textProps={{
                pt: 0,
                fontSize: 'xs',
                whiteSpace: 'normal',
                'data-testid': `product-stock-status-${sku}`,
              }}
            />
            <Parts.OrderLimit fontSize="xs" mt={0} extraProductData={product.extraData} />
            <Parts.LastPurchaseDate
              mt={0}
              fontSize="xs"
              extraProductData={product.extraData}
              textProps={{
                'data-testid': `product-last-purchased-${sku}`,
              }}
            />
            {!!warningMessage && <Parts.WarningMessage textProps={{ mt: 1 }}>{warningMessage}</Parts.WarningMessage>}
            {!!perItemErrorMessage && (
              <Parts.ErrorMessage
                textProps={{ mt: 2, 'data-testid': `product-error-message-${sku}` }}
                iconProps={{ mt: 1 }}
              >
                {perItemErrorMessage}
              </Parts.ErrorMessage>
            )}
          </Box>
        </Parts.PriceCluster>
      </Flex>
    </Parts.Card>
  );
};
