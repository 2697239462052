import { Box, Flex, HStack, StackProps, Text, Tooltip } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { FEATURE_FLAGS } from './general';
import { useGlobal } from 'components/globalProvider';
import { useFormat } from 'helpers/hooks';
import { Variant } from '@Types/product/Variant';
import { ProductAttributeKeys } from './types';

type RewardPointsProps = {
  variant: Variant;
  showText?: boolean;
  darkMode?: boolean;
  containerProps?: StackProps;
  size?: number;
};
const { REWARDS_POINTS } = FEATURE_FLAGS;

export const RewardPoints: FunctionComponent<RewardPointsProps> = ({
  variant,
  containerProps,
  showText = false,
  darkMode = false,
  size = 4,
}) => {
  const { formatMessage } = useFormat({ name: 'common' });
  const { activeAccount } = useGlobal().useUserGlobal.state;
  const productPoints = +(variant?.attributes?.[ProductAttributeKeys.RewardsPoints] || 0);
  const shouldDisplayPoints =
    REWARDS_POINTS && activeAccount?.custom?.fields?.sfc_business_unit_rewards_eligible && productPoints > 0;

  if (!shouldDisplayPoints) {
    return null;
  }
  return (
    <Tooltip
      isDisabled={showText}
      hasArrow
      placement="top"
      borderRadius="2px"
      color="neutral.200"
      background="neutral.900"
      label={formatMessage({ id: 'pdp.badge.rewardsPoints' })}
      aria-label={formatMessage({ id: 'pdp.badge.rewardsPoints' })}
    >
      <HStack gap={1} fontWeight="bold" fontSize="xs" {...containerProps}>
        <Flex
          w={size}
          h={size}
          borderRadius="1000px"
          color="neutral.900"
          backgroundColor={darkMode ? 'yellow.400' : 'yellow.500'}
          justify="center"
          align="center"
          data-testid={`${productPoints}-reward-points`}
        >
          <Text mt="0.7px" lineHeight="120%">
            {productPoints}
          </Text>
        </Flex>
        {showText && <Box color="neutral.200">{formatMessage({ id: 'pdp.badge.rewardsPoints' })}</Box>}
      </HStack>
    </Tooltip>
  );
};
