import React, { createContext, useContext, ReactNode, useEffect, useReducer } from 'react';
import { MIN_CHARACTERS_FOR_INPUT } from './constants';
import { SEARCH_INPUT_ID } from './search-input';
import {
  globalSearchReducer,
  GlobalSearchStateActions,
  QuickCatalogContents,
} from 'composable/components/global-search/reducer';
import { HitWithExtraData } from 'composable/components/qc-search/type';

interface GlobalSearchType {
  inputValue: string;
  debouncedInputValue: string;
  shouldOpenDropdown: boolean;
  setInputValue: (value: string) => void;
  setActiveElement: (element: Element | null) => void;
  closeMobileSearch?: () => void;
  isQuickCatalogOpen: boolean;
  quickCatalogContent: QuickCatalogContents;
  openQuickCatalog: VoidFunction;
  closeQuickCatalog: VoidFunction;
  openAddToOGInQuickCatalog: (product: HitWithExtraData) => void;
  openCatalogInQuickCatalog: VoidFunction;
  addToOGProduct: HitWithExtraData | null;
}

const OPEN_DROPDOWN_DELAY = 200;

const GlobalSearchContext = createContext<GlobalSearchType | undefined>(undefined);

interface GlobalSearchProviderProps {
  children: ReactNode;
  closeMobileSearch?: () => void;
}

export const GlobalSearchProvider = ({ children, closeMobileSearch }: GlobalSearchProviderProps) => {
  const globalSearchInput = document.getElementById(SEARCH_INPUT_ID) as HTMLInputElement;

  const [
    {
      inputValue,
      debouncedInputValue,
      activeElement,
      isQuickCatalogOpen,
      shouldOpenDropdown,
      quickCatalogContent,
      addToOGProduct,
    },
    dispatch,
  ] = useReducer(globalSearchReducer, {
    inputValue: '',
    debouncedInputValue: '',
    activeElement: null,
    isQuickCatalogOpen: false,
    shouldOpenDropdown: false,
    quickCatalogContent: QuickCatalogContents.CATALOG,
    addToOGProduct: null,
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      dispatch({
        type: GlobalSearchStateActions.SET_DEBOUNCED_INPUT_VALUE,
        payload: {
          debouncedInputValue: inputValue,
        },
      });
    }, 200);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue]);

  useEffect(() => {
    setTimeout(() => {
      dispatch({
        type: GlobalSearchStateActions.SET_DROPDOWN_OPEN,
        payload: {
          shouldOpenDropdown: Boolean(
            inputValue.length >= MIN_CHARACTERS_FOR_INPUT && globalSearchInput && activeElement === globalSearchInput,
          ),
        },
      });
    }, OPEN_DROPDOWN_DELAY);
  }, [inputValue, globalSearchInput, activeElement]);

  const setInputValue = (value: string) => {
    dispatch({
      type: GlobalSearchStateActions.SET_INPUT_VALUE,
      payload: {
        inputValue: value,
      },
    });
  };

  const setActiveElement = (element: Element | null) => {
    dispatch({
      type: GlobalSearchStateActions.SET_ACTIVE_ELEMENT,
      payload: {
        activeElement: element,
      },
    });
  };

  const openQuickCatalog = () => {
    dispatch({
      type: GlobalSearchStateActions.OPEN_QUICK_CATALOG,
    });
  };

  const closeQuickCatalog = () => {
    dispatch({
      type: GlobalSearchStateActions.CLOSE_QUICK_CATALOG,
    });
  };

  const openAddToOGInQuickCatalog = (product: HitWithExtraData) => {
    dispatch({
      type: GlobalSearchStateActions.SWIPE_TO_ADD_TO_OG,
      payload: {
        addToOGProduct: product,
      },
    });
  };

  const openCatalogInQuickCatalog = () => {
    dispatch({
      type: GlobalSearchStateActions.SWIPE_TO_QUICK_CATALOG,
    });
  };

  return (
    <GlobalSearchContext.Provider
      value={{
        inputValue,
        debouncedInputValue,
        shouldOpenDropdown,
        setInputValue,
        setActiveElement,
        closeMobileSearch,
        isQuickCatalogOpen,
        openQuickCatalog,
        closeQuickCatalog,
        quickCatalogContent,
        openAddToOGInQuickCatalog,
        openCatalogInQuickCatalog,
        addToOGProduct,
      }}
    >
      {children}
    </GlobalSearchContext.Provider>
  );
};

export const useGlobalSearch = () => {
  const context = useContext(GlobalSearchContext);
  if (context === undefined) {
    throw new Error('useGlobalSearch must be used within a GlobalSearchProvider');
  }
  return context;
};
