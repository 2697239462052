import { ExtraProductData, ShamrockNextDeliveryDueDate } from '@Types/shamrockApi/Product';
import { FEATURE_FLAGS } from 'composable/components/general';
import { PageVariant } from 'composable/components/types';
import { getInventoryStatusMessage } from 'composable/helpers/utils/get-inventory-status-message';
import { getNextDueDateMessage } from 'composable/helpers/utils/get-next-due-date-message';
import { InventoryStatus } from 'composable/helpers/utils/inventory-utils';
import { isFuture } from 'date-fns/isFuture';
import { FormatMessageParams } from 'helpers/hooks';

const shouldIncludeDueDateMessage = [InventoryStatus.LOW, InventoryStatus.OUT, InventoryStatus.UNAVAILABLE];
const SUR = 'Shipped to Order';
const limitedStock = 'Limited Stock';
const discontinued = 'Discontinued';

export const getInventoryMessageWithDueDate = (
  status: InventoryStatus,
  intl: { formatMessage: (args: Omit<FormatMessageParams, 'name'>) => string },
  nextDeliveryDueDates?: ExtraProductData['nextDeliveryDueDates'],
  availableQuantity?: number,
  pageVariant?: string,
) => {
  let statusMessage = getInventoryStatusMessage(status, intl);

  // LOGIC TO SHOW AVAILABLE QTY DEPENDING OF THE PAGE, PRODUCT AND FF
  availableQuantity = Math.floor(availableQuantity);
  if (pageVariant === PageVariant.PDP) {
    if (statusMessage === SUR && FEATURE_FLAGS.DISPLAY_SUR_STOCK && availableQuantity > 0) {
      statusMessage = `${statusMessage}: ${availableQuantity} available`;
    } else if (statusMessage !== SUR && availableQuantity > 0) {
      statusMessage = `${statusMessage}: ${availableQuantity} available`;
    }
  } else if ((statusMessage === SUR && FEATURE_FLAGS.DISPLAY_SUR_STOCK) || statusMessage === limitedStock) {
    if (availableQuantity > 0) {
      statusMessage = `${statusMessage}: ${availableQuantity} available`;
    }
  } else if (statusMessage === discontinued) {
    if (availableQuantity > 0) {
      statusMessage = `${limitedStock}: ${availableQuantity} available`;
    }
  }

  const nextDueDate = getNextDueDate(nextDeliveryDueDates?.data);

  let dueDateMessage: string | undefined;

  if (shouldIncludeDueDateMessage.includes(status) && nextDueDate) {
    dueDateMessage = getNextDueDateMessage(nextDueDate, intl);
  }

  return { status: statusMessage, dueDate: dueDateMessage };
};

export const getNextDueDate = (nextDeliveryDueDates: ShamrockNextDeliveryDueDate[]) => {
  return nextDeliveryDueDates
    ?.map((date) => new Date(date.dateTime))
    .filter((date) => isFuture(date))
    .sort((a, b) => a.getTime() - b.getTime())[0];
};
