import {
  GaAddOgEvent,
  GaAddToCartEvent,
  GaBeginCheckoutEvent,
  GaCartDeletedEvent,
  GaEventsCollection,
  GaNewCartRequestedEvent,
  GaParSetup,
  GaPurchaseEvent,
  GaRemoveFromCartEvent,
  GaSearchEvent,
  GaSelectItemEvent,
  GaViewCartEvent,
  GaViewItemEvent,
  GaViewItemListEvent,
  PrintBackDownloadEvent,
} from './types';
import {
  GenericAddOGEvent,
  GenericAddToCartEvent,
  GenericBeginCheckoutEvent,
  GenericCartDeleted,
  GenericEventsCollection,
  GenericNewCartRequested,
  GenericParSetup,
  GenericPrintBackDownloadEvent,
  GenericPurchaseEvent,
  GenericRemoveFromCartEvent,
  GenericSearchEvent,
  GenericSelectItemEvent,
  GenericViewCartEvent,
  GenericViewItemEvent,
  GenericViewItemListEvent,
} from '../generic/types';
import { GA_TRACK_CONSTANTS } from './constants';

export function castToGoogleAnalyticsEvent(event: GenericEventsCollection): GaEventsCollection | undefined {
  switch (event.name) {
    case 'add_to_cart':
      return castAddToCartEvent(event);
    case 'remove_from_cart':
      return castRemoveFromCartEvent(event);
    case 'view_item':
      return castViewItemEvent(event);
    case 'view_cart':
      return castViewCartEvent(event);
    case 'begin_checkout':
      return castBeginCheckoutEvent(event);
    case 'purchase':
      return castPurchaseEvent(event);
    case 'view_item_list':
      return castViewItemListEvent(event);
    case 'select_item':
      return castSelectItemEvent(event);
    case 'search':
      return castSearchEvent(event);
    case 'add_to_orderguide':
      return castAddToOgEvent(event);
    case 'printback_download':
      return castPrintBackDownload(event);
    case 'new_cart_requested':
      return castNewCartRequested(event);
    case 'cart_deleted':
      return castCartDeleted(event);
    case 'par_setup':
      return castParSetup(event);
    case 'add_payment_info':
    case 'add_shipping_info':
    case 'login':
    case 'sign_up':
      return event; // so far only for ga
  }
}

function castAddToCartEvent({ params }: GenericAddToCartEvent): GaAddToCartEvent {
  return {
    name: 'add_to_cart',
    params: {
      currency: params?.currency ?? '',
      value: params?.value ?? 0,
      items:
        params?.items?.map((item) => ({
          item_id: item.id,
          item_name: item.name,
          affiliation: item.affiliation,
          coupon: item.coupon,
          discount: item.discount,
          index: item.index,
          item_brand: item.brand,
          item_category: item.categories?.[0],
          item_category2: item.categories?.[1],
          item_category3: item.categories?.[2],
          item_category4: item.categories?.[3],
          item_category5: item.categories?.[4],
          item_list_id: item.list_id,
          item_list_name: item.list_name,
          item_variant: item.variant,
          location_id: item.location_id,
          price: item.price,
          quantity: item.quantity,
        })) ?? [],
      fromPAR: params.fromPAR,
    },
  };
}

function castRemoveFromCartEvent({ params }: GenericRemoveFromCartEvent): GaRemoveFromCartEvent {
  return {
    name: 'remove_from_cart',
    params: {
      currency: params?.currency ?? '',
      value: params?.value ?? 0,
      items:
        params?.items?.map((item) => ({
          item_id: item.id,
          item_name: item.name,
          affiliation: item.affiliation,
          coupon: item.coupon,
          discount: item.discount,
          index: item.index,
          item_brand: item.brand,
          item_category: item.categories?.[0],
          item_category2: item.categories?.[1],
          item_category3: item.categories?.[2],
          item_category4: item.categories?.[3],
          item_category5: item.categories?.[4],
          item_list_id: item.list_id?.toString(),
          item_list_name: item.list_name,
          item_variant: item.variant,
          location_id: item.location_id,
          price: item.price,
          quantity: item.quantity,
        })) ?? [],
    },
  };
}

function castViewItemEvent({ params }: GenericViewItemEvent): GaViewItemEvent {
  const itemIdField = params?.items?.[0]?.id || params?.items?.[0]?.sku || '';
  const itemCategories = params?.items?.[0]?.categories || [];

  // Ensure we have valid category values
  const itemCategory = Array.isArray(itemCategories) && itemCategories.length > 0 ? itemCategories[0] : 'Uncategorized';
  const itemCategory2 = Array.isArray(itemCategories) && itemCategories.length > 1 ? itemCategories[1] : undefined;
  const itemCategory3 = Array.isArray(itemCategories) && itemCategories.length > 2 ? itemCategories[2] : undefined;
  const itemCategory4 = Array.isArray(itemCategories) && itemCategories.length > 3 ? itemCategories[3] : undefined;
  const itemCategory5 = Array.isArray(itemCategories) && itemCategories.length > 4 ? itemCategories[4] : undefined;

  return {
    name: 'view_item',
    params: {
      currency: 'USD',
      value: params?.value || 0,
      items: [
        {
          item_id: itemIdField,
          item_name: params?.items?.[0]?.name || '',
          affiliation: params?.items?.[0]?.affiliation,
          coupon: params?.items?.[0]?.coupon,
          discount: params?.items?.[0]?.discount,
          index: params?.items?.[0]?.index,
          item_brand: params?.items?.[0]?.brand || 'Unknown Brand',
          item_category: itemCategory,
          item_category2: itemCategory2,
          item_category3: itemCategory3,
          item_category4: itemCategory4,
          item_category5: itemCategory5,
          item_list_id: params?.items?.[0]?.list_id,
          item_list_name: params?.items?.[0]?.list_name,
          item_variant: params?.items?.[0]?.variant,
          location_id: params?.items?.[0]?.location_id,
          price: params?.items?.[0]?.price || 0,
          quantity: params?.items?.[0]?.quantity || 1,
        },
      ],
    },
  };
}

function castViewCartEvent({ params }: GenericViewCartEvent): GaViewCartEvent {
  return {
    name: 'view_cart',
    params: {
      currency: params?.currency ?? '',
      value: params?.value ?? 0,
      items:
        params?.items?.map((item) => ({
          item_id: item.sku || item.id,
          item_name: item.name,
          affiliation: item.affiliation,
          coupon: item.coupon,
          discount: item.discount,
          index: item.index,
          item_brand: item.brand,
          item_category: item.categories?.[0],
          item_category2: item.categories?.[1],
          item_category3: item.categories?.[2],
          item_category4: item.categories?.[3],
          item_category5: item.categories?.[4],
          item_list_id: item.list_id?.toString(),
          item_list_name: item.list_name,
          item_variant: item.variant,
          location_id: item.location_id,
          price: item.price,
          quantity: item.quantity,
        })) ?? [],
    },
  };
}

function castBeginCheckoutEvent({ params }: GenericBeginCheckoutEvent): GaBeginCheckoutEvent {
  return {
    name: 'begin_checkout',
    params: {
      currency: params?.currency ?? '',
      value: params?.value ?? 0,
      coupon: params?.coupon,
      items:
        params?.items?.map((item) => ({
          item_id: item.sku || item.id,
          item_name: item.name,
          affiliation: item.affiliation,
          coupon: item.coupon,
          discount: item.discount,
          index: item.index,
          item_brand: item.brand,
          item_category: item.categories?.[0],
          item_category2: item.categories?.[1],
          item_category3: item.categories?.[2],
          item_category4: item.categories?.[3],
          item_category5: item.categories?.[4],
          item_list_id: item.list_id?.toString(),
          item_list_name: item.list_name,
          item_variant: item.variant,
          location_id: item.location_id,
          price: item.price,
          quantity: item.quantity,
        })) ?? [],
    },
  };
}

function castPurchaseEvent({ params }: GenericPurchaseEvent): GaPurchaseEvent {
  return {
    name: 'purchase',
    params: {
      currency: params?.currency ?? '',
      transaction_id: params?.transaction_id ?? '',
      value: params?.value ?? 0,
      affiliation: params?.affiliation,
      coupon: params?.coupon,
      shipping: params?.shipping,
      tax: params?.tax,
      delivery_date: params?.delivery_date,
      delivery_type: params?.delivery_type,
      shipping_address: params?.shipping_address,
      billing_address: params?.billing_address,
      items:
        params?.items?.map((item) => ({
          item_id: item.sku || item.id,
          item_name: item.name,
          affiliation: item.affiliation,
          coupon: item.coupon,
          discount: item.discount,
          index: item.index,
          item_brand: item.brand,
          item_category: item.categories?.[0],
          item_category2: item.categories?.[1],
          item_category3: item.categories?.[2],
          item_category4: item.categories?.[3],
          item_category5: item.categories?.[4],
          item_list_id: item.list_id?.toString(),
          item_list_name: item.list_name,
          item_variant: item.variant,
          location_id: item.location_id,
          price: item.price,
          quantity: item.quantity,
        })) ?? [],
    },
  };
}

function castViewItemListEvent({ params }: GenericViewItemListEvent): GaViewItemListEvent {
  const isFromQuickCatalog = typeof window !== 'undefined' && window.location.pathname.includes('/quick-catalog');

  const finalListId = isFromQuickCatalog ? GA_TRACK_CONSTANTS.ITEM_LIST_QUICK_CATALOG : params?.list_id?.toString();
  const finalListName = isFromQuickCatalog ? GA_TRACK_CONSTANTS.ITEM_LIST_QUICK_CATALOG : params?.list_name;

  return {
    name: 'view_item_list',
    params: {
      item_list_id: finalListId,
      item_list_name: finalListName,
      items:
        params?.items?.map((item) => ({
          item_id: item.sku || item.id,
          item_name: item.name,
          affiliation: item.affiliation,
          coupon: item.coupon,
          discount: item.discount,
          index: item.index,
          item_brand: item.brand,
          item_category: item.categories?.[0],
          item_category2: item.categories?.[1],
          item_category3: item.categories?.[2],
          item_category4: item.categories?.[3],
          item_category5: item.categories?.[4],
          item_list_id: finalListId,
          item_list_name: finalListName,
          item_variant: item.variant,
          location_id: item.location_id,
          price: item.price,
          quantity: item.quantity,
        })) ?? [],
    },
  };
}

function castSelectItemEvent({ params }: GenericSelectItemEvent): GaSelectItemEvent {
  return {
    name: 'select_item',
    params: {
      item_list_id: params.item_list_id || '',
      item_list_name: params.item_list_name || '',
      items:
        params?.items?.map((item) => ({
          item_id: item?.sku || item.id,
          item_name: item.name,
          affiliation: item.affiliation,
          coupon: item.coupon,
          discount: item.discount,
          index: item.index,
          item_brand: item.brand,
          item_category: item.categories?.[0],
          item_category2: item.categories?.[1],
          item_category3: item.categories?.[2],
          item_category4: item.categories?.[3],
          item_category5: item.categories?.[4],
          item_list_id: params.item_list_id || (item as any).list_id || '',
          item_list_name: params.item_list_name || (item as any).list_name || '',
          item_variant: item.variant,
          location_id: item.location_id,
          price: item.price,
          quantity: item.quantity,
        })) ?? [],
    },
  };
}

function castSearchEvent({ params }: GenericSearchEvent): GaSearchEvent {
  return {
    name: 'search',
    params: {
      search_term: params.search_term,
      sort_by: params.sort_by,
      refinement_list: params.refinement_list,
      page: params.page,
      toggle: params.toggle,
    },
  };
}

function castAddToOgEvent({ params }: GenericAddOGEvent): GaAddOgEvent {
  return {
    name: 'add_to_orderguide',
    params,
  };
}

function castPrintBackDownload({ params }: GenericPrintBackDownloadEvent): PrintBackDownloadEvent {
  return {
    name: 'printback_download',
    params,
  };
}

function castNewCartRequested({ params }: GenericNewCartRequested): GaNewCartRequestedEvent {
  return {
    name: 'new_cart_requested',
    params: {
      customerNumber: params?.customerNumber,
    },
  };
}

function castCartDeleted({ params }: GenericCartDeleted): GaCartDeletedEvent {
  return {
    name: 'cart_deleted',
    params: {
      customerNumber: params?.customerNumber,
      cartId: params?.cartId,
      itemCount: params?.itemCount,
      caseCount: params?.caseCount,
    },
  };
}

function castParSetup({ params }: GenericParSetup): GaParSetup {
  return {
    name: 'par_setup',
    params: {
      orderGuideNumber: params?.orderGuideNumber,
      customerNumber: params?.customerNumber,
      warehouseId: params?.warehouseId,
      userId: params?.userId,
      items: params?.items,
    },
  };
}
