import { FunctionComponent } from 'react';
import NextLink from 'next/link';
import { BoxProps, IconProps, Link, TextProps } from '@chakra-ui/react';
import { ShamrockProductSubstitution } from '@Types/shamrockApi/Product';
import { InlineMessage } from 'composable/components/inline-message';
import { getVariantForProductSubstitute } from 'composable/components/pdp';
import {
  COLOR_PROPERTIES_BY_VARIANT,
  ProductSubstituteVariant,
  SubstituteType,
} from 'composable/components/pdp/constants';
import { useFormat } from 'helpers/hooks';

type SubstitutionNoteProps = {
  shamrockProductSubstitution: ShamrockProductSubstitution;
  productNumber: string;
  textProps?: TextProps;
  boxProps?: BoxProps;
  iconProps?: IconProps;
};

export const SubstitutionNote: FunctionComponent<SubstitutionNoteProps> = ({
  shamrockProductSubstitution,
  productNumber,
  textProps,
  boxProps,
  iconProps,
}) => {
  const { formatMessage } = useFormat({ name: 'common' });
  const variant = getVariantForProductSubstitute(shamrockProductSubstitution?.substituteType as SubstituteType);

  if (!shamrockProductSubstitution || !variant) {
    return <></>;
  }

  const { textColor } = COLOR_PROPERTIES_BY_VARIANT[variant];

  return (
    <Link as={NextLink} target="_self" href={`/product/${productNumber}`} passHref>
      <InlineMessage
        variant={variant === ProductSubstituteVariant.May ? 'info' : 'warning'}
        textProps={{ fontWeight: 'medium', fontSize: 'xs', ...textProps }}
        _hover={{
          cursor: 'pointer',
          '*': {
            textDecoration: 'underline',
            textDecorationColor: textColor,
          },
        }}
        iconProps={{
          w: 6,
          h: 6,
          boxSize: 5,
          paddingRight: 1,
          ...iconProps,
        }}
        ariaLabel={formatMessage({ id: 'pdp.substitutions.productCard.' + variant.toLowerCase() })}
        mb={2}
        {...boxProps}
      >
        {formatMessage({ id: 'pdp.substitutions.productCard.' + variant.toLowerCase() })}
      </InlineMessage>
    </Link>
  );
};
