import { CheckCircleIcon } from 'composable/components/check-circle-icon';
import { WarningDiamondIcon, WarningIcon } from 'composable/components/warning-icon';

export const ALGOLIA_BASE_INDEX = process.env.NEXT_PUBLIC_ALGOLIA_BASE_INDEX ?? '';
export const ALGOLIA_QUERY_SUGGESTIONS_INDEX = process.env.NEXT_PUBLIC_ALGOLIA_QUERY_SUGGESTIONS_INDEX ?? '';
export const IMAGE_PLACEHOLDER = '/img/image-placeholder.svg';
export const GOOGLE_TAG_MANAGER_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;
/* Brand name determines what contentful content will be pulled
for ex:
- single site can set BRAND_NAME to '',
- multi-site: set BRAND_A, BRAND_B etc
*/
export const BRAND_NAME = process.env.NEXT_PUBLIC_BRAND_NAME ?? '';
export const DEFAULT_LOCALE = process.env.NEXT_PUBLIC_DEFAULT_LOCALE;
export const PRODUCT_CHANNEL =
  process.env.NEXT_PUBLIC_COMMERCETOOLS_INVENTORY_CHANNEL ?? (BRAND_NAME ? `${BRAND_NAME}-channel` : undefined);

export const APPLICATION_URL = process.env.NEXT_PUBLIC_APPLICATION_URL ?? 'https://shop.shamrockffoodsservice.com';
export const MYSHAMROCK_URL = process.env.NEXT_PUBLIC_MYSHAMROCK_URL;
export const SHAMROCK_LOGOUT_URL =
  process.env.NEXT_PUBLIC_SHAMROCK_LOGOUT_URL ??
  'https://sfcb2cdev.b2clogin.com/sfcb2cdev.onmicrosoft.com/b2c_1a_signup_signin_application/oauth2/v2.0/logout?redirect_uri=https%3A%2F%2Fshamrockorders.com';

// Urls where the <MiniCart/> should not be displayed
export const urlsToHide = ['/cart'];

export const countries = [
  {
    name: 'Canada',
    code: 'CA',
    intlId: 'text.canada',
  },
  {
    name: 'United States',
    code: 'US',
    intlId: 'text.unitedStates',
  },
];

export const NOT_ORDERABLE = 'NotOrderable';

export const QUANTITY_PICKER_DEBOUNCE_TIME = 200;

export const TOAST_ICON = {
  error: WarningIcon,
  success: CheckCircleIcon,
  warning: WarningDiamondIcon,
};

export const GENERIC_TOAST_ERROR_ID = 'GENERIC_TOAST_ERROR_ID';
export const UPDATE_LINE_ITEM_ERROR_ID = 'UPDATE_LINE_ITEM_ERROR_ID';
export const CART_HAS_BEEN_SUBMITTED_ID = 'CART_HAS_BEEN_SUBMITTED_ID';

export const RICHTEXT_SCHEMA = {
  //SCHEMA need to be match with CMS to fetch content
  pdp: {
    shipingAndReturn: 'product/category/{{category}}/shipping_and_return',
  },
  account: {
    shipingAndReturn: 'account/shipping_and_return',
  },
};

export const ENABLE_BOLD_CHECKOUT = process.env.NEXT_PUBLIC_CHECKOUT
  ? process.env.NEXT_PUBLIC_CHECKOUT === 'bold'
  : false;

export const BOLD_CHECKOUT_HOST = process.env.NEXT_PUBLIC_BOLD_CHECKOUT_HOST ?? '';

export const BOLD_COMMERCE_SHOP_ALIAS = process.env.NEXT_PUBLIC_BOLD_COMMERCE_SHOP_ALIAS ?? '';

export const SITEMAP_CONFIG = {
  LIMIT: 99,
  LOCALE: 'en-US',
  CURRENCY: 'USD',
  SITEMAP_INDEX_SLUG: 'server.xml',
  SITEMAP_PRODUCT_PATH: 'sitemap/products-{page}.xml',
  SITEMAP_CATEGORY_PATH: 'sitemap/categories-{page}.xml',
  PRODUCT_REGEX: /^products-*-(\d+)\.xml$/,
  CATEGORY_REGEX: /^categories-*-(\d+)\.xml$/,
} as const;

export const AZURE_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY =
  process.env.NEXT_PUBLIC_AZURE_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY ?? '';

const logoutThreshold = parseInt(process.env.NEXT_PUBLIC_AZURE_LOGOUT_THRESHOLD, 10);
const refreshThreshold = parseInt(process.env.NEXT_PUBLIC_AUTH_TOKEN_REFRESH_THRESHOLD, 10);
const authSessionLimit = parseInt(process.env.NEXT_PUBLIC_AUTHENTICATION_SESSION_LIMIT_HOURS, 10);

export const AZURE_LOGOUT_THRESHOLD = !isNaN(logoutThreshold) ? logoutThreshold : 3600000; // 1 hour
export const AUTH_TOKEN_REFRESH_THRESHOLD = !isNaN(refreshThreshold) ? refreshThreshold : 600000; // 10 minutes
export const AUTHENTICATION_SESSION_LIMIT_HOURS = !isNaN(authSessionLimit) ? authSessionLimit : 9;

export const DATADOG_APPLICATION_ID = process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID;
export const DATADOG_CLIENT_TOKEN = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN;
export const DATADOG_REPLAY_SAMPLE_RATE = parseInt(process.env.NEXT_PUBLIC_DATADOG_REPLAY_SAMPLE_RATE || '0', 10);
export const DATADOG_SESSION_SAMPLE_RATE = parseInt(process.env.NEXT_PUBLIC_DATADOG_SESSION_SAMPLE_RATE || '0', 10);
export const DATADOG_SERVICE = process.env.NEXT_PUBLIC_DATADOG_SERVICE;
export const DATADOG_SITE = process.env.NEXT_PUBLIC_DATADOG_SITE;
export const DATADOG_ENVIRONMENT = process.env.NEXT_PUBLIC_DATADOG_ENVIRONMENT;
export const APPLICATION_VERSION = process.env.NEXT_PUBLIC_APPLICATION_VERSION;

export const PDP_CACHE_MAX_AGE = process.env.NEXT_PUBLIC_PDP_CACHE_MAX_AGE ?? 86400;

export const PDP_CACHE_STALE = process.env.NEXT_PUBLIC_PDP_CACHE_STALE ?? 14400;

export const LOCAL_STORAGE_ENCRYPTION_KEY = process.env.LOCAL_STORAGE_ENCRYPTION_KEY;

export const CACHE_MINUTES_PRODUCT_EXTENDED_DATA = parseInt(
  process.env.NEXT_PUBLIC_CACHE_MINUTES_PRODUCT_EXTENDED_DATA || '10',
  10,
);

export const CONTACT_NUMBER = process.env.NEXT_PUBLIC_CONTACT_NUMBER || '855.397.4255';

// FEATURE FLAGS
export const FEATURE_FLAGS = {
  SHOW_PDP_REPEATED_IMAGES: process.env.NEXT_PUBLIC_FEATURE_FLAG_SHOW_PDP_REPEATED_IMAGES === 'true',
  SPLIT_ORDER_EDIT_ORDER: process.env.NEXT_PUBLIC_FEATURE_FLAG_SPLIT_ORDER_EDIT_ORDER === 'true',
  BACK_REFRESH_POC: process.env.NEXT_PUBLIC_FEATURE_FLAG_BACK_REFRESH_POC === 'true',
  SOFT_CUTOFF: process.env.NEXT_PUBLIC_FEATURE_FLAG_SOFT_CUTOFF === 'true',
  GEOCODE_CUTOFF: process.env.NEXT_PUBLIC_FEATURE_FLAG_GEOCODE_CUTOFF === 'true',
  HEADER_CHANGES: process.env.NEXT_PUBLIC_FEATURE_FLAG_HEADER_CHANGES === 'true',
  SIDEBAR_CHANGES: process.env.NEXT_PUBLIC_FEATURE_FLAG_SIDEBAR_CHANGES === 'true',
  QUEUE_LOGS: process.env.NEXT_PUBLIC_FEATURE_FLAG_QUEUE_LOGS === 'true',
  MULTI_CART: process.env.NEXT_PUBLIC_FEATURE_FLAG_MULTI_CART === 'true',
  DISPLAY_SUR_STOCK: process.env.NEXT_PUBLIC_FEATURE_FLAG_DISPLAY_SUR_STOCK === 'true',
  DISPLAY_PAR: process.env.NEXT_PUBLIC_FEATURE_FLAG_DISPLAY_PAR === 'true',
  EXTRA_DELIVERY_DATES: process.env.NEXT_PUBLIC_FEATURE_FLAG_EXTRA_DELIVERY_DATES === 'true',
  DST_ADJUSTMENTS: process.env.NEXT_PUBLIC_FEATURE_FLAG_DST_ADJUSTMENTS === 'true',
  ACCOUNTS_MANAGEMENT: process.env.NEXT_PUBLIC_FEATURE_FLAG_ACCOUNTS_MANAGEMENT === 'true',
  REWARDS_POINTS: process.env.NEXT_PUBLIC_PRODUCT_REWARD_POINTS_DISPLAY === 'true',
  SUGGESTED_SEARCHES: process.env.NEXT_PUBLIC_FEATURE_FLAG_SUGGESTED_SEARCHES === 'true',
  CART_PREVIEW: process.env.NEXT_PUBLIC_FEATURE_FLAG_CART_PREVIEW === 'true',
  QUICK_CATALOG: process.env.NEXT_PUBLIC_FEATURE_FLAG_QUICK_CATALOG === 'true',
  SUR_CHECK_STOCK_QUANTITY: process.env.NEXT_PUBLIC_FEATURE_FLAG_SUR_CHECK_STOCK_QUANTITY === 'true',
  QUICK_CATALOG_ADD_TO_OG: process.env.NEXT_PUBLIC_FEATURE_FLAG_QC_ADD_TO_OG === 'true',
  CSV_10k_IMPORT: process.env.NEXT_PUBLIC_FEATURE_FLAG_10k_CSV_IMPORT === 'true',
  CSV_OGM_IMPORT: process.env.NEXT_PUBLIC_FEATURE_FLAG_OGM_CSV_IMPORT === 'true',
  FAVORITES: process.env.NEXT_PUBLIC_FEATURE_FLAG_FAVORITES === 'true',
};
