import { ExtraProductData } from '@Types/shamrockApi/Product';
import { CART_LARGE_QTY } from 'composable/components/cart/contants';

export enum CartItemExceptionType {
  ORDER_LIMIT = 'ORDER_LIMIT',
  ON_HAND_QUANTITY = 'ON_HAND_QUANTITY',
  LARGE_QUANTITY = 'LARGE_QUANTITY',
}

type GetCartItemException = (data: {
  extraProductData?: ExtraProductData;
  quantity: number;
}) => CartItemExceptionType | undefined;

export const getCartItemException: GetCartItemException = ({ quantity, extraProductData }) => {
  const orderLimit = extraProductData?.orderLimit?.data?.orderLimit;
  const inventory = extraProductData?.inventoryInfo?.data;
  const companyInfo = extraProductData?.companyInfo?.data;
  const isSurOrJitItem = companyInfo?.isJustInTime || companyInfo?.isShipUponReceipt;

  if (!!orderLimit && quantity > orderLimit) {
    return CartItemExceptionType.ORDER_LIMIT;
  } else if (quantity > inventory?.availableQuantity && !isSurOrJitItem) {
    return CartItemExceptionType.ON_HAND_QUANTITY;
  } else if (quantity > CART_LARGE_QTY) {
    return CartItemExceptionType.LARGE_QUANTITY;
  }
};
