import { useState, useMemo, useEffect, Dispatch, SetStateAction } from 'react';
import { AsAssociateBusinessUnitResult } from '@Types/business-unit/BusinessUnit';
import { LineItem } from '@Types/cart/LineItem';
import { OrderDetail, OrderDetailLineItem } from '@Types/shamrockApi/Order';
import { ExtraProductData } from '@Types/shamrockApi/Product';
import { FEATURE_FLAGS } from 'composable/components/general';
import { getDeliveryCutoff } from 'composable/components/product-card-v2/helpers';
import { addDays, isBefore, isSameDay } from 'date-fns';
import { differenceInMinutes } from 'date-fns/differenceInMinutes';
import { isAfter } from 'date-fns/isAfter';
import { SoftCutoffsResponse } from 'helpers/services/shamrock';
import { getProductsMaxLeadDays } from 'helpers/utils/getProductsMaxLeadDays';
import { calculateLatestDeliveryDate } from 'utils/checkout/calculateLatestDeliveryDate';
import {
  listAccountNextDeliveries,
  parseDeliveryDateWithCutoffToDeliveryDatesProps,
} from 'utils/checkout/deliveriesAndCutoffs';
import {
  calculateTimeRemaining,
  getPlanByStoreKey,
  calculateUpcomingDeliveryDates,
  DeliveryDatesProps,
  getActiveAccountDeliveryDates,
} from './useGetDeliveryDates';
import { DELIVERY_DATES_CONFIG } from '../constants/deliveryDates';
import { findFurthestDates } from '../utils/find-furthest-dates';
import { mapLineItemsToProducts } from '../utils/map-line-items-to-products';
import { editGroupHasDisabledLineItems } from '../utils/order-utils';
import { partitionProductsByCutoff } from '../utils/partition-products-by-cutoff';
import { useRouter } from 'next/router';
import routes from 'helpers/constants/routes';
import { groupHasOnlyOriginalOrderItems } from 'hooks/global/use_privateCheckoutGlobal/helpers';
import { findSURWithLocalInventorySKUs } from '../utils/checkout/checkokut-utils';

export type LineItemsWithDeliveryDates = {
  deliveryDates: DeliveryDatesProps;
  lineItems: LineItem[] | OrderDetailLineItem[];
};

type UseSplitOrderReturnType = { isSplitOrder: boolean; orderGroups: LineItemsWithDeliveryDates[] };

export const SECONDARY_ORDER_GROUP_INDEX = 1;

const { count } = DELIVERY_DATES_CONFIG;

type UseSplitOrderParams = {
  isEditOrder: boolean;
  lineItems: LineItem[] | OrderDetailLineItem[];
  setCutOffDeliveryDates: Dispatch<SetStateAction<DeliveryDatesProps>>;
  geocodeDeliveryDate?: string;
  activeAccount: Partial<AsAssociateBusinessUnitResult>;
  softCutoffs: SoftCutoffsResponse;
  extraProductData: ExtraProductData[];
  editCart?: OrderDetail;
};

export function useSplitOrder({
  isEditOrder,
  lineItems,
  setCutOffDeliveryDates,
  geocodeDeliveryDate,
  activeAccount,
  softCutoffs,
  extraProductData,
  editCart,
}: UseSplitOrderParams): UseSplitOrderReturnType {
  const [orderGroups, setOrderGroups] = useState<LineItemsWithDeliveryDates[]>([]);
  const skus = lineItems?.map((item) => item.productNumber || item?.variant?.sku);
  const productsLeadDays = getProductsMaxLeadDays(extraProductData);
  // Partition products by cutoff presence
  const { productsWithoutCutoff, productsWithCutoff } = useMemo(() => {
    return partitionProductsByCutoff(extraProductData, lineItems);
  }, [skus]);

  const normalDeliveryDates = useMemo(() => {
    return getActiveAccountDeliveryDates({ activeAccount, leadDays: null, geocodeDeliveryDate, softCutoffs }) || null;
  }, [activeAccount, softCutoffs, geocodeDeliveryDate]);
  const businessDeliveryDates = useMemo(() => {
    return (
      getActiveAccountDeliveryDates({
        activeAccount,
        leadDays: productsWithoutCutoff?.length === 0 ? productsLeadDays : 0,
        geocodeDeliveryDate,
        softCutoffs,
      }) || null
    );
  }, [
    activeAccount,
    softCutoffs,
    geocodeDeliveryDate,
    JSON.stringify(productsWithoutCutoff),
    extraProductData,
    lineItems,
  ]);

  const getCommonData = () => {
    // Determine products with delivery dates later than the first available business delivery date
    const datesToConsider = productsWithCutoff.length === skus?.length ? normalDeliveryDates : businessDeliveryDates;

    const estimatedDeliveryDate = isEditOrder ? new Date(editCart.estimatedDeliveryDate) : null;
    if (isEditOrder && !!estimatedDeliveryDate) {
      estimatedDeliveryDate.setUTCHours(12);
    }

    const productsWithSURStock = [
      ...findSURWithLocalInventorySKUs(productsWithCutoff, lineItems),
      ...findSURWithLocalInventorySKUs(productsWithoutCutoff, lineItems),
    ];

    let productsWithLaterDeliveryDate = productsWithCutoff.filter((product) => {
      const productDeliveryDate = getDeliveryCutoff(product, activeAccount, datesToConsider?.dates[0]);

      // check if lineItem is from original order, if so, shouldn't consider later date
      if (isEditOrder) {
        const item = (lineItems as any[])?.find((lineItem) => {
          return lineItem?.productNumber === product.productNumber;
        });

        if (item?.hasOwnProperty('lineNumber')) {
          return isAfter(estimatedDeliveryDate, datesToConsider?.dates[0]);
        }
      }

      // Check if it's SUR with actualInventory
      if (productsWithSURStock.includes(product.productNumber)) {
        console.log(`Product ${product.productNumber} has local inventory so we are skipping the lead-time`);
        return false;
      }

      return isAfter(productDeliveryDate.deliveryDay, datesToConsider?.dates[0]);
    });

    // Compute the furthest cutoff and delivery dates among the filtered products
    let { furthestCutoffDate, furthestDeliveryDate } = findFurthestDates(
      productsWithLaterDeliveryDate,
      activeAccount,
      datesToConsider,
      lineItems,
    );
    // Filter productsWithCutoff to exclude those in productsWithLaterDeliveryDate
    const eligibleProductsWithCutoff = productsWithCutoff.filter(
      (product) => !productsWithLaterDeliveryDate.includes(product),
    );

    // Combine productsWithoutCutoff and eligibleProductsWithCutoff for mapping
    let eligibleProductsForPrimaryOrder = [...productsWithoutCutoff, ...eligibleProductsWithCutoff];

    eligibleProductsForPrimaryOrder = eligibleProductsForPrimaryOrder.reduce((acc, product) => {
      if (productsWithSURStock.includes(product.productNumber)) {
        acc.push({
          ...product,
          companyInfo: {
            ...product.companyInfo,
            data: {
              ...product.companyInfo.data,
              cutoffs: null,
            },
          },
        });
      } else {
        acc.push(product);
      }
      return acc;
    }, []);

    let { furthestCutoffDate: primFurthestCutoffDate, furthestDeliveryDate: primFurthestDeliveryDate } =
      findFurthestDates(eligibleProductsForPrimaryOrder, activeAccount, datesToConsider, lineItems);

    // Map line items to products for primary and secondary orders
    let primaryOrderLineItems = mapLineItemsToProducts(eligibleProductsForPrimaryOrder, lineItems);
    let secondaryOrderLineItems = mapLineItemsToProducts(productsWithLaterDeliveryDate, lineItems);

    // Check if SUR item request exceed the quantity available to split order
    extraProductData.forEach((element) => {
      const isSurItem = element.companyInfo?.data?.isShipUponReceipt;
      const stockAvailable = element.inventoryInfo?.data?.actualInventory;
      if (isSurItem) {
        // Find lineItem on primaryOrderLineItems
        const indexInPrimary = primaryOrderLineItems.findIndex((item) => item.productSlug === element.productNumber);
        const surDelivery = getDeliveryCutoff(element, activeAccount, datesToConsider?.dates[0]);

        const canBeDeliveredInFirstGroup =
          surDelivery?.deliveryDay && isSameDay(surDelivery.deliveryDay, datesToConsider?.dates[0]);

        if (
          !canBeDeliveredInFirstGroup &&
          indexInPrimary !== -1 &&
          primaryOrderLineItems[indexInPrimary].count > stockAvailable
        ) {
          // Move lineItem from primary to secondary
          const [itemToMove] = primaryOrderLineItems.splice(indexInPrimary, 1);
          secondaryOrderLineItems.push(itemToMove);
        }
      }
    });

    const plans = getPlanByStoreKey(activeAccount);
    const lastModifiedPlan = activeAccount?.lastModifiedAt;

    let primaryHasLeadItems = false;

    // Keep original order items estimated delivery date in their group as a option.
    if (isEditOrder) {
      // secondary gorup
      if (editGroupHasDisabledLineItems(editCart, activeAccount, secondaryOrderLineItems, isEditOrder)) {
        // If this is a only lead days order, first group is empty.
        // original lead items from order should be on the first group and
        // new added lead items should be moved to the secondary group
        if (primaryOrderLineItems?.length < 1) {
          const editOrderItems = secondaryOrderLineItems.filter((item) => {
            if (item.hasOwnProperty('lineNumber')) {
              return true;
            }
            return false;
          });

          const newAddedItems = secondaryOrderLineItems.filter((item) => {
            if (!item.hasOwnProperty('lineNumber')) {
              return true;
            }
            return false;
          });

          if (newAddedItems?.length > 0 && !!newAddedItems[0]) {
            const newItemsExtraData = extraProductData.filter((extraData) => {
              return newAddedItems.find((newProduct) => newProduct.productNumber == extraData.productNumber);
            });

            const editOrderItemsExtraData = extraProductData.filter((extraData) => {
              return editOrderItems.find((originalProduct) => originalProduct.productNumber == extraData.productNumber);
            });

            const editItemsLeadDays = getProductsMaxLeadDays(editOrderItemsExtraData, false);
            const newItemsLeadDays = getProductsMaxLeadDays(newItemsExtraData, false);
            const newItemEarliestDeliveryDate = addDays(new Date(), newItemsLeadDays);

            // based on when the new added lead items can be delivered
            // we recalculate dates and reorder the groups
            if (isSameDay(newItemEarliestDeliveryDate, estimatedDeliveryDate)) {
              // when both new and previous items can be delivered in the same day, merge into a single group
              const allItems = [...editOrderItems, ...newAddedItems];
              primaryOrderLineItems = allItems;
              eligibleProductsForPrimaryOrder = [...newItemsExtraData, ...editOrderItemsExtraData];
              secondaryOrderLineItems = [];
              productsWithLaterDeliveryDate = [];

              const recalculateFurthest = findFurthestDates(
                [...editOrderItemsExtraData, ...newItemsExtraData],
                activeAccount,
                datesToConsider,
                lineItems,
              );
              primFurthestCutoffDate = recalculateFurthest.furthestCutoffDate;
              primFurthestDeliveryDate = recalculateFurthest.furthestDeliveryDate;
            } else if (isBefore(newItemEarliestDeliveryDate, estimatedDeliveryDate)) {
              // when new lead items can be delivered before original order estimate day add them to first group
              primaryOrderLineItems = newAddedItems;
              eligibleProductsForPrimaryOrder = newItemsExtraData;
              secondaryOrderLineItems = editOrderItems;
              productsWithLaterDeliveryDate = editOrderItemsExtraData;

              primaryHasLeadItems = newItemsLeadDays > 0;

              const recalculateFurthest = findFurthestDates(
                newItemsExtraData,
                activeAccount,
                datesToConsider,
                newAddedItems,
              );
              primFurthestCutoffDate = recalculateFurthest.furthestCutoffDate;
              primFurthestDeliveryDate = recalculateFurthest.furthestDeliveryDate;

              const recalculateFurthestSecondary = findFurthestDates(
                editOrderItemsExtraData,
                activeAccount,
                datesToConsider,
                editOrderItems,
              );
              furthestCutoffDate = recalculateFurthestSecondary.furthestCutoffDate;
              furthestDeliveryDate = recalculateFurthestSecondary.furthestDeliveryDate;
            } else {
              // when new lead items can be delivered after original order estimate day add them to second group
              primaryOrderLineItems = editOrderItems;
              eligibleProductsForPrimaryOrder = editOrderItemsExtraData;
              secondaryOrderLineItems = newAddedItems;
              productsWithLaterDeliveryDate = newItemsExtraData;

              primaryHasLeadItems = editItemsLeadDays > 0;

              const recalculateFurthest = findFurthestDates(
                newItemsExtraData,
                activeAccount,
                datesToConsider,
                newAddedItems,
              );
              furthestCutoffDate = recalculateFurthest.furthestCutoffDate;
              furthestDeliveryDate = recalculateFurthest.furthestDeliveryDate;

              const recalculateFurthestPrimary = findFurthestDates(
                editOrderItemsExtraData,
                activeAccount,
                datesToConsider,
                editOrderItems,
              );
              primFurthestCutoffDate = recalculateFurthestPrimary.furthestCutoffDate;
              primFurthestDeliveryDate = recalculateFurthestPrimary.furthestDeliveryDate;
            }
          }

          if (secondaryOrderLineItems?.length > 0 && !secondaryOrderLineItems[0]) {
            secondaryOrderLineItems = [];
          }
        }
      }
    }

    const primLeadDays = getProductsMaxLeadDays(eligibleProductsForPrimaryOrder, true, primFurthestDeliveryDate);
    const primaryValidFurthestIso = primFurthestDeliveryDate?.toISOString();

    let primDates =
      calculateUpcomingDeliveryDates(
        plans[0],
        primaryValidFurthestIso,
        count,
        primLeadDays,
        geocodeDeliveryDate,
        softCutoffs,
        lastModifiedPlan,
      )?.dates || [];

    let primOrderBy = null;

    const currentDate = new Date();
    const validGeocodeDeliveryDate =
      FEATURE_FLAGS.GEOCODE_CUTOFF && !!geocodeDeliveryDate && isAfter(new Date(geocodeDeliveryDate), currentDate);

    if (
      ((secondaryOrderLineItems.length > 0 && primaryOrderLineItems.length > 0) ||
        productsWithLaterDeliveryDate.length === 0) &&
      !validGeocodeDeliveryDate &&
      !primaryHasLeadItems
    ) {
      primOrderBy = normalDeliveryDates.orderBy;
    } else if (!!primFurthestCutoffDate) {
      primOrderBy = {
        ...calculateTimeRemaining(differenceInMinutes(primFurthestCutoffDate, new Date())),
        orderByDate: primFurthestCutoffDate,
      };
    }

    // Determine additional details for the secondary order group
    const secondaryLeadDays = getProductsMaxLeadDays(productsWithLaterDeliveryDate, true, furthestDeliveryDate);
    const secondaryValidFurthestIso = furthestDeliveryDate?.toISOString();

    let dates =
      calculateUpcomingDeliveryDates(
        plans[0],
        secondaryValidFurthestIso,
        count,
        secondaryLeadDays,
        null,
        softCutoffs,
        lastModifiedPlan,
        true,
      )?.dates || [];
    let orderBy = {
      ...calculateTimeRemaining(differenceInMinutes(furthestCutoffDate, new Date())),
      orderByDate: furthestCutoffDate ?? new Date(),
    };

    if (isEditOrder) {
      // if dates are equal we should not split

      let shouldMerge = primDates?.length > 0 && dates?.length > 0 && isSameDay(primDates[0], dates[0]);

      // if primary group only has only original order items we should
      // merge only if estimatedDeliveryDate is the same day as primary order
      // as offdate value will be applied on edit-checkout body

      if (
        !shouldMerge &&
        dates?.[0] &&
        groupHasOnlyOriginalOrderItems(primaryOrderLineItems) &&
        !isSameDay(dates[0].toUTCString(), estimatedDeliveryDate)
      ) {
        shouldMerge = false;
      }

      // if lead items group (secondary) only has only original order items we should
      // merge if estimatedDeliveryDate is the same day as primary order
      // as offdate value will be applied on edit-checkout body
      shouldMerge =
        shouldMerge ||
        (primDates?.[0] &&
          groupHasOnlyOriginalOrderItems(secondaryOrderLineItems) &&
          isSameDay(primDates[0].toUTCString(), estimatedDeliveryDate));

      if (shouldMerge) {
        if (primaryOrderLineItems?.length > 0 && secondaryOrderLineItems?.length > 0 && !!secondaryOrderLineItems[0]) {
          primaryOrderLineItems = [...primaryOrderLineItems, ...secondaryOrderLineItems];
          secondaryOrderLineItems = [];
          dates = null;
          orderBy = null;
        }
      }
    }

    const items = [primaryOrderLineItems, secondaryOrderLineItems];

    return {
      items,
      deliveryDates: { primary: { dates: primDates, orderBy: primOrderBy }, secondary: { dates, orderBy } },
      hasDelayedProducts: productsWithLaterDeliveryDate?.length > 0,
    };
  };

  const processSplitOrder = (): LineItemsWithDeliveryDates[] => {
    const { items, deliveryDates, hasDelayedProducts } = getCommonData();
    const { primary: primaryDeliveryDates, secondary: secondaryDeliveryDates } = deliveryDates;
    const [primary, secondary] = items;
    const primaryOrderGroup = { lineItems: primary, deliveryDates: primaryDeliveryDates };
    const secondaryOrderGroup = { lineItems: secondary, deliveryDates: secondaryDeliveryDates };
    const orderGroups = [primaryOrderGroup, secondaryOrderGroup];

    const orderGroupsWithItems = orderGroups.filter(
      (group) => group.lineItems?.length > 0,
    ) as LineItemsWithDeliveryDates[];

    hasDelayedProducts && orderGroupsWithItems.length !== 2
      ? setCutOffDeliveryDates(secondaryDeliveryDates)
      : setCutOffDeliveryDates(primaryDeliveryDates);

    return orderGroupsWithItems;
  };

  // TODO make this a pure function
  const processSingleOrder = () => {
    // TODO If there was a increase in quantity the product shouldn't be considered old
    const { items } = getCommonData();
    // calculate delivery dates (considering order edit)
    if (isEditOrder) {
      // Split items into the ones already in the original order and new ones
      const orderDeliveryDate = new Date(`${editCart.estimatedDeliveryDate}T12:00:00.000Z`);
      const newProducts = editCart.lineItems.filter((lineItem) => lineItem?.lineNumber == null);
      // Calculate the MCD of deliveries for new products
      const newProductsExtraData = extraProductData.filter((extra) => {
        return newProducts.find((newProd) => newProd.productNumber == extra.productNumber) != null;
      });
      // Calculate Standard delivery options that can deliver all products but it's not before the orignal delivery date
      const latestNewDeliveryDate = calculateLatestDeliveryDate(newProductsExtraData);
      const currentDate = new Date();
      const tomorrow = new Date();
      tomorrow.setDate(currentDate.getDate() + 1);

      let startingAfter = orderDeliveryDate > latestNewDeliveryDate ? orderDeliveryDate : latestNewDeliveryDate;
      const deliveryList = listAccountNextDeliveries({
        account: activeAccount as AsAssociateBusinessUnitResult,
        startDate: isSameDay(startingAfter, currentDate) ? tomorrow : startingAfter,
      });
      const deliveryDates = parseDeliveryDateWithCutoffToDeliveryDatesProps(deliveryList);
      return [{ lineItems: items.flat(), deliveryDates }] as LineItemsWithDeliveryDates[];
    }
    return [{ lineItems: items.flat(), deliveryDates: businessDeliveryDates }] as LineItemsWithDeliveryDates[];
  };

  const router = useRouter();
  const isCheckoutPage = router.asPath.includes(routes.CHECKOUT) || router.asPath.includes(routes.EDIT_ORDER_CHECKOUT);

  useEffect(() => {
    if (!businessDeliveryDates?.dates?.length || !extraProductData.length || !activeAccount) {
      return;
    }

    const shouldProccessSplitOrder = !isEditOrder || (isEditOrder && FEATURE_FLAGS.SPLIT_ORDER_EDIT_ORDER);

    const orders = shouldProccessSplitOrder ? processSplitOrder() : processSingleOrder();
    setOrderGroups(orders);
  }, [extraProductData, activeAccount, businessDeliveryDates, lineItems, isEditOrder, softCutoffs, isCheckoutPage]);

  const isSplitOrder = orderGroups.length > 1;

  return { isSplitOrder, orderGroups };
}
