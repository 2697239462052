import { format } from 'date-fns/format';
import { FormatMessageParams } from 'helpers/hooks';

export const getNextDueDateMessage = (
  nextDueDate: Date,
  intl: { formatMessage: (args: Omit<FormatMessageParams, 'name'>) => string },
) => {
  const nextDueDateStr = nextDueDate ? format(nextDueDate, 'MM/dd') : '';

  if (!nextDueDateStr) {
    return null;
  }

  const dueDateMessage = intl.formatMessage({
    id: 'inventory.dueDate',
    defaultMessage: `\nDue on ${nextDueDateStr}`,
    values: { nextDueDateStr },
  });

  return dueDateMessage;
};
