import { CartGlobalAction, CartGlobalStateActions, CartState } from './types';

export const cartInitialState: CartState = {
  cart: null,
  shippingMethods: null,
  loading: true,
  error: null,
  cartsList: null,
};

export function reduceCart(state: CartState, action: CartGlobalAction): CartState {
  switch (action.type) {
    case CartGlobalStateActions.UPDATE:
      return {
        ...state,
        error: null,
        cart: action.payload.cart,
        loading: false,
      };
    case CartGlobalStateActions.SET_LOADING:
      return { ...state, loading: action.payload.loading };
    case CartGlobalStateActions.SET_ERROR:
      return { ...state, error: action.payload.error };
    case CartGlobalStateActions.SET_CARTS_LIST:
      return { ...state, cartsList: action.payload.cartsList };
    case CartGlobalStateActions.UPDATE_CART_LIST:
      const cartExists = state.cartsList?.find((cart) => cart.cartId === action.payload.cart.cartId);
      const newCartList = cartExists
        ? state.cartsList?.map((cart) => (cart.cartId === action.payload.cart.cartId ? action.payload.cart : cart))
        : [action.payload.cart, ...(state.cartsList ?? [])];
      return {
        ...state,
        cartsList: newCartList,
      };
    case CartGlobalStateActions.REMOVE_CART_FROM_LIST:
      return {
        ...state,
        cartsList: state.cartsList?.filter((cart) => cart.cartId !== action.payload.cart.cartId) ?? [],
      };
    default:
      return state;
  }
}
