import { ExtraProductData, ShamrockProductSubstitution } from '@Types/shamrockApi/Product';
import { GenericAddOGEvent } from 'composable/analytics/generic/types';
import { ProductAttributeKeys } from 'composable/components/types';
import { APPLICABLE_SUBSTITUTE_TYPES, ProductSubstituteVariant, SubstituteType } from './constants';
import { FullProduct } from '../order-guide/helpers';
import { OrderGuideMultipleCustomers } from '../order-guide-management/components/types';
import { getProductDetails } from '../product-card-v2/helpers';
import { AddOgOrigin, PageVariant } from '../types';

/**
 * Custom handler function type for complex transformations of attribute values.
 *
 * @param attributes - The original attributes to transform.
 *
 * @returns An object containing the transformed value.
 */
export type CustomHandler = (attributes: Record<string, any>) => { value: string };

/**
 * Describes the transformation method to be used for attribute mapping.
 *
 * - 'default': Maps attributes directly to their corresponding labels and values.
 * - 'json_array_to_label_value': Transforms a JSON array into an array of objects with 'label' and 'value' fields.
 */
export type TransformationType = 'default' | 'json_array_to_label_value';

/**
 * Interface for a single entry in the data map.
 *
 * @property key - The attribute key.
 * @property label - The human-readable label for the attribute key. Optional if custom transformation is applied.
 * @property order - The order in which this entry should appear in the final mapped array.
 * @property customHandler - Optional custom handler function for more complex transformations.
 * @property type - Optional type for special transformations like 'json_array_to_label_value'.
 */
export interface DataMapEntry {
  key: string;
  label?: string;
  order: number;
  customHandler?: CustomHandler;
  type?: TransformationType;
}

/**
 * Type definition for a mapped attribute.
 */
export interface MappedAttribute {
  key: string;
  value: string | boolean;
  order: number;
}

/**
 * Removes object values from the given map.
 *
 * @param obj - The original object containing key-value pairs.
 *
 * @returns A new object with object values removed.
 */
export const removeObjectsFromMap = (obj: { [key: string]: any }) => {
  const newObj: { [key: string]: any } = {};
  for (const [key, value] of Object.entries(obj)) {
    if (value !== null && typeof value !== 'object') {
      newObj[key] = value;
    }
  }
  return newObj;
};

/**
 * Transforms a set of attributes based on a data mapping configuration.
 *
 * @param originalAttributes - The original set of attributes as key-value pairs.
 * @param dataMap - The data mapping configuration specifying how each attribute should be transformed.
 * @returns An array of mapped attributes sorted by their order.
 */
export const mapAttributesToData = (
  originalAttributes: Record<string, any>,
  dataMap: DataMapEntry[],
  extraProductData?: ExtraProductData,
): MappedAttribute[] => {
  const mappedAttributes = !!extraProductData
    ? replaceCtValuesWithExtraData(originalAttributes, extraProductData)
    : { ...originalAttributes };
  const data: MappedAttribute[] = [];

  dataMap.forEach((entry) => {
    const result = mapEntryToAttribute(mappedAttributes, entry);
    data.push(...result);
  });

  return data.sort((a, b) => a.order - b.order);
};

const mapEntryToAttribute = (mappedAttributes: Record<string, any>, entry: DataMapEntry): MappedAttribute[] => {
  const { key, label, order, customHandler, type } = entry;

  if (customHandler) {
    return [{ key: label, value: customHandler(mappedAttributes).value, order }];
  }

  if (type === 'json_array_to_label_value') {
    return mapJsonArrayToAttributes(mappedAttributes[key], order);
  }

  return [{ key: label, value: getValue(mappedAttributes, key), order }];
};

const mapJsonArrayToAttributes = (jsonValue: string, baseOrder: number): MappedAttribute[] => {
  if (typeof jsonValue !== 'string') {
    return [];
  }

  const jsonArray = JSON.parse(jsonValue);
  return jsonArray.map((obj: any, index: number) => ({
    key: obj.type?.en ?? 'N/A',
    value: obj.instructions?.en ?? 'N/A',
    order: baseOrder + index / 100,
  }));
};

const getValue = (attributes: Record<string, any>, key: string): string | boolean => {
  if (!attributes.hasOwnProperty(key)) {
    return '-';
  }

  const value = attributes[key];
  return value === true ? 'Yes' : value === false ? 'No' : value;
};

export const convertNumToPixel = (num: number): string => {
  if (!isNaN(num)) {
    return num.toString() + 'px';
  }
};

export const displayTitle = (title: string): string => {
  return title?.length < 59 ? title?.toLowerCase() : title?.substring(0, 59)?.toLowerCase() + '...';
};

export const getObjectForTrackingAddOg = ({
  product,
  selectedNumberOrderGuides,
  customOrderGuides,
  productVariant,
  originPage,
  comingFromHomePage,
}: {
  product: FullProduct;
  selectedNumberOrderGuides: string[];
  customOrderGuides: OrderGuideMultipleCustomers[];
  productVariant: PageVariant;
  originPage: AddOgOrigin;
  comingFromHomePage: boolean;
}): GenericAddOGEvent['params'] => {
  const { productName, brand, sku, discountedPrice, category } = getProductDetails(
    product?.ctInformation,
    product?.extraInformation,
  );
  return {
    orderGuideNames: selectedNumberOrderGuides.map((number) => {
      const orderGuide = customOrderGuides.find((og) => og.orderGuideNumber === number);
      return orderGuide?.name ?? '';
    }),
    sku: sku,
    itemName: productName,
    isComingFromHomePage: comingFromHomePage,
    price: discountedPrice / 100,
    brand,
    category,
    originPage,
  };
};

export const getVariantForProductSubstitute = (
  productSubstituteType: SubstituteType,
): ProductSubstituteVariant | null => {
  if ([SubstituteType.AUTO, SubstituteType.CLEAN_INVOICE].includes(productSubstituteType)) {
    return ProductSubstituteVariant.May;
  }

  return SubstituteType.FORCED === productSubstituteType ? ProductSubstituteVariant.Will : null;
};

export const getFirstApplicableSubstituteType = (
  substitutes: ShamrockProductSubstitution[],
): ShamrockProductSubstitution | null => {
  return substitutes?.find((sub) => APPLICABLE_SUBSTITUTE_TYPES.includes(sub.substituteType as SubstituteType)) ?? null;
};

const replaceCtValuesWithExtraData = (data: Record<string, any>, extraProductData: ExtraProductData) => {
  const palletHigh = extraProductData.companyInfo?.data?.palletHigh;
  const palletQty = extraProductData.companyInfo?.data?.palletQuantity;
  const palletTier = extraProductData.companyInfo?.data?.palletTier;

  const replacements = {
    [ProductAttributeKeys.PalletQty]: palletQty,
    [ProductAttributeKeys.PalletTi]: palletTier,
    [ProductAttributeKeys.PalletHi]: palletHigh,
  };

  for (const key in replacements) {
    if (replacements[key]) {
      data[key] = replacements[key];
    }
  }

  return data;
};
