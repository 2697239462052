import { NextRouter } from 'next/router';
import { useToast } from '@chakra-ui/react';
import { AsAssociateBusinessUnitResult } from '@Types/business-unit/BusinessUnit';
import { analyticsTrackLogin } from 'composable/analytics/analytics-event-tracking';
import { APPCUES_ID } from 'composable/analytics/appcues';
import { appcuesIdentifyAndGroupUser } from 'composable/analytics/appcues/appcues-tracking';
import { LOGROCKET_ID } from 'composable/analytics/logrocket/constants';
import { logrocktIdentifyUser } from 'composable/analytics/logrocket/logrocket-tracking';
import {
  APPLICATION_URL,
  AUTHENTICATION_SESSION_LIMIT_HOURS,
  AUTH_TOKEN_REFRESH_THRESHOLD,
  GENERIC_TOAST_ERROR_ID,
  TOAST_ICON,
} from 'composable/components/general';
import { ACCESS_TOKEN, REFRESH_ATTEMPT, REFRESH_TOKEN } from 'composable/helpers/constants';
import { add, fromUnixTime, isAfter, isEqual, sub } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { AuthErrorKeys, ERROR_DICTIONARY } from 'helpers/constants/auth';
import { LOGIN_METHOD } from 'helpers/constants/eventTracking';
import routes from 'helpers/constants/routes';
import { isSlugValid } from 'helpers/slugHelpers';
import { trackShamrockUserLogin } from 'helpers/utils/trackShamrockUserLogin';
import { fetchApiHub } from 'frontastic';
import { debugLogout, deleteLocalStorageValuesWithoutBu, LoginResponse } from 'frontastic/actions/account';
import { azureGetTokens, azureRefreshTokens } from 'frontastic/actions/azure/azureActions';
import { GetTokensResponse } from 'frontastic/actions/azure/types';
import { getStoreDetailsForSelectedBu } from 'frontastic/actions/customer';
import { FRONTASTIC_COOKIE, LOGOUT_CALLERS, ADDITIONAL_AZURE_URL_PARAMS, MAX_REFRESH_ATTEMPT } from './constants';
import { CustomerDataProps, LogLogoutType } from './types';

type UserLogoutParams = {
  hardLogout: boolean;
  options?: {
    shouldReload?: boolean;
    message?: string;
    originPath?: string;
    caller?: string | object;
  };
  syncTokens?: (accessToken: string, refreshToken: string) => void;
};

/**
 * Validates accessToken is a valid string and has not expired
 * for refreshToken don't add the expiration check
 *
 * @param accessToken string accessToken or refreshToken
 * @param expiration string expiration time in unix timestamp (optional)
 * @returns boolean
 */
export function validateAccessToken(accessToken?: string, expiration?: number): boolean {
  if (expiration) {
    const expirationTime = new Date(expiration * 1000);
    const currentTime = new Date();

    if (expirationTime < currentTime) {
      return false;
    }
  }

  return !!(accessToken && accessToken.length > 5);
}

/**
 * Validates accessToken if has not expired
 * The refresh token can not be validate since the JWT is encrypted
 *
 * @param token string accessToken
 * @returns boolean
 */
export function isStillValidToken(token: string): boolean {
  const timeToExpire = getExpTimeFromAccessToken(token);
  if (!timeToExpire) {
    return false;
  }
  const expirationTime = fromUnixTime(timeToExpire);
  const currentTime = new Date();
  return isAfter(expirationTime, currentTime) || isEqual(expirationTime, currentTime);
}

//fetch selected account details
export function selectedAccountDetails(activeAccountKey, accountList = []): Partial<AsAssociateBusinessUnitResult> {
  const accountDetails = accountList.find((bu) => bu.key === activeAccountKey) || accountList[0];
  return accountDetails;
}

export function getAdditionalAzureUrlParams(redirectUrl: string): GetServerShamrockRedirectUrlParams {
  // Parse the URL and extract the query parameters
  const urlParams = new URLSearchParams(redirectUrl.split('?')[1]);

  // Get the additional url parameters and parse it as a JSON object
  const additionalParams = urlParams.get(ADDITIONAL_AZURE_URL_PARAMS);
  const additionalParamsParsed: GetServerShamrockRedirectUrlParams = JSON.parse(additionalParams);

  return additionalParamsParsed;
}

/**
 * logout user function to be used outside of react components.
 * for react compoents use useGlobal().useUserGlobal.logout
 *
 * @param hardLogout boolean true if sessions should also be erased from azure
 * @param message string error message to identify why user logout and redirect to error page
 * @returns void
 */
export async function userLogout(
  hardLogout: UserLogoutParams['hardLogout'] = false,
  options: UserLogoutParams['options'] = {},
  syncTokens?: UserLogoutParams['syncTokens'],
) {
  const { message, originPath, caller, shouldReload = false } = options;
  let incrementalLog: LogLogoutType = {
    hardLogout,
    message,
    originPath,
    caller,
    trace: new Error().stack.split('\n'),
  };
  const shouldRefresh = message === AuthErrorKeys.Unauthorized || message === AuthErrorKeys.SessionExpired;
  if (shouldRefresh && !hardLogout) {
    // try to refresh token
    const refreshToken = window.localStorage.getItem(REFRESH_TOKEN);

    if (validateAccessToken(refreshToken)) {
      incrementalLog.refresh = {
        attempt: true,
      };
      console.info('Refreshing token for unauthorized user');
      await refreshAccessToken(refreshToken, async (getTokensResponse) => {
        syncTokens?.(getTokensResponse.access_token, getTokensResponse.refresh_token);
        incrementalLog.refresh.newToken = (getTokensResponse.access_token ?? '').substring(0, 5);
        if (validateAccessToken(getTokensResponse.access_token)) {
          incrementalLog.refresh.success = true;
          await debugLogout(incrementalLog);
          console.info('Successfully refreshed token for unauthorized user');
          //This is when the handle Shamrock errors is called
          //There is not an easy way to synchronize the token with the global state then we need to reload the page
          shouldReload && window.location.reload();
          return;
        }
      });
    }
  }

  if (hardLogout) {
    deleteLocalStorageValuesWithoutBu();
    incrementalLog.hardLogoutCall = {
      attempt: true,
    };
    try {
      const thereIsFrontasticCookie = hasCookie(FRONTASTIC_COOKIE);
      incrementalLog.hardLogoutCall.thereIsFrontasticCookie = thereIsFrontasticCookie;
      thereIsFrontasticCookie && (await fetchApiHub('/action/account/logout', { method: 'POST' }));
      // If error is included in dictionary send user to error page
      if (ERROR_DICTIONARY[message || '']) {
        const errorPageUrl = `${routes.PUBLIC_ERROR_PAGE}?login=${message}`;
        incrementalLog.hardLogoutCall.success = true;
        await debugLogout(incrementalLog);
        window.location.href = errorPageUrl;
        return;
      }

      // otherwise, send to azure login
      deleteLocalStorageValuesWithoutBu();
      incrementalLog.hardLogoutCall.azureLogout = true;
      await debugLogout(incrementalLog);
      window.location.href = await getServerAzureLogoutUrl(message, originPath);
      return;
    } catch (error) {
      console.error('Error API call: logout', error);
      incrementalLog.hardLogoutCall.success = false;
      incrementalLog.hardLogoutCall.error = error;
      await debugLogout(incrementalLog);
      const errorPageUrl = `${routes.PUBLIC_ERROR_PAGE}?login=${AuthErrorKeys.Network}`;
      window.location.href = errorPageUrl;
      return;
    }
  }
  incrementalLog.softLogout = true;
  await debugLogout(incrementalLog);
  window.location.href = getServerShamrockRedirectUrl(APPLICATION_URL, '301', '', { redirectTo: '/' });
}

export const handleLoginSuccess = ({
  router,
  originPath,
  activeAccount,
  customerInCT,
  browserAccessToken,
  clientIp,
  permissions,
}: {
  router: NextRouter;
  originPath: string;
  activeAccount: Partial<AsAssociateBusinessUnitResult>;
  customerInCT: any;
  browserAccessToken: string;
  clientIp: string;
  permissions: string[];
}) => {
  trackShamrockUserLogin({ accessToken: browserAccessToken, clientIp });

  analyticsTrackLogin({ method: LOGIN_METHOD });

  // Call to logrocket if logrocketid is available to identify user
  if (LOGROCKET_ID) {
    logrocktIdentifyUser(customerInCT.customer, activeAccount);
  }

  if (browserAccessToken) {
    const redirectPath = originPath.toLowerCase();
    const canRedirect = isSlugValid(redirectPath);

    if (redirectPath !== routes.HOME && redirectPath !== '' && canRedirect) {
      router.replace(
        {
          pathname: redirectPath,
          query: '',
        },
        undefined,
        { shallow: false },
      );
    } else {
      // remove the ?code from the url
      const { code } = router.query;
      if (code) {
        router.replace(
          {
            pathname: routes.HOME,
            query: '',
          },
          undefined,
          { shallow: true },
        );
      }
    }
  }

  // Delaying appcues call after login to avoid issues while resolving redirects.
  setTimeout(() => {
    // Call to appcues if appcuesid and customer information is available
    if (APPCUES_ID) {
      appcuesIdentifyAndGroupUser(customerInCT.customer, activeAccount, permissions);
    }
  }, 3000);
};

/**
 * Retrieves the browser access token using the provided authorization code.
 *
 * @param {string} code - The authorization code to exchange for an access token.
 * @returns {Promise<{accessToken: string | null, refreshToken: string | null, expiresOn: string | null, origin: string | null, incrementalLog: any}>} - An object containing the access token, refresh token, expiration time, origin, and incremental log.
 */
export async function getBrowserAccessToken(code: string) {
  let incrementalLog: any = { code };

  // Call to azureController::getTokens function to get the access token
  const response = await azureGetTokens(code);
  incrementalLog.success = response.success;
  if (response.success) {
    return {
      accessToken: response.access_token,
      refreshToken: response.refresh_token,
      expiresOn: response.expires_on,
      origin: 'getToken',
      incrementalLog,
    };
  }

  return {
    accessToken: null,
    refreshToken: null,
    expiresOn: null,
    origin: null,
    incrementalLog,
  };
}

/**
 * Refresh the access token before the token spires.

 * @param refreshToken azure refresh token taken on get or refresh token calls
 * @param logoutUrl azure logout url
 * @param nextTimeout time in milliseconds to check the token again
 */
export async function refreshAccessToken(refreshToken: string, cb: (tokenResponse: GetTokensResponse) => void) {
  try {
    const now = new Date();
    const authToken = localStorage.getItem(ACCESS_TOKEN);

    const expiresUnixTime = getExpTimeFromAccessToken(authToken);
    const issuedUnixTime = getIatTimeFromAccessToken(authToken);

    const expiresOn = fromUnixTime(expiresUnixTime);
    const issuedAt = fromUnixTime(issuedUnixTime);

    //Logs
    const secondsToRenew = Math.floor(AUTH_TOKEN_REFRESH_THRESHOLD / 1000);
    const refreshTime = sub(expiresOn, { seconds: secondsToRenew });
    const refreshTimeFormatted = formatInTimeZone(refreshTime, 'UTC', 'yyyy-MM-dd HH:mm:ss');
    const nowFormatted = formatInTimeZone(now, 'UTC', 'yyyy-MM-dd HH:mm:ss');
    console.log('Time to refresh again token', { timeToRefresh: refreshTimeFormatted, now: nowFormatted });

    const refreshAttempt = parseInt(window.localStorage.getItem(REFRESH_ATTEMPT) ?? '0');
    if (refreshAttempt >= MAX_REFRESH_ATTEMPT) {
      console.log('Max refresh attempt reached');
      return await userLogout(true, { message: AuthErrorKeys.UserNotFound, caller: LOGOUT_CALLERS.REFRESH_TOKEN });
    }

    // Check if user login is past the session hours limit
    if (issuedUnixTime && issuedAt) {
      const loginLimit = add(issuedAt, { hours: AUTHENTICATION_SESSION_LIMIT_HOURS });

      if (isAfter(now, loginLimit) || isEqual(now, loginLimit)) {
        console.info(`user session is greater than the ${AUTHENTICATION_SESSION_LIMIT_HOURS} hours limit`);

        // redirect user to error page avoiding a login loop if we tried it too many times
        deleteLocalStorageValuesWithoutBu();
        return await userLogout(true, { caller: LOGOUT_CALLERS.REFRESH_TOKEN });
      }
    }

    if (isAfter(now, refreshTime)) {
      const res = await azureRefreshTokens(refreshToken);
      if (!res.success) {
        window.localStorage.setItem(REFRESH_ATTEMPT, (refreshAttempt + 1).toString());
        return await refreshAccessToken(refreshToken, cb);
      }
      console.log('Token refreshed successfully');
      // refresh token was successful, reset the refresh attempt
      window.localStorage.setItem(REFRESH_ATTEMPT, '0');
      localStorage.setItem(ACCESS_TOKEN, res.access_token);
      localStorage.setItem(REFRESH_TOKEN, res.refresh_token);

      cb(res);
      return;
    }

    // if token expired already and refresh was not successful,
    // logout user to avoid idle session
    if (isAfter(now, expiresOn) || isEqual(now, expiresOn)) {
      deleteLocalStorageValuesWithoutBu();
      // redirect user to azure logout
      return await userLogout(true, { caller: LOGOUT_CALLERS.REFRESH_TOKEN });
    }
  } catch (error) {
    console.error('Error refreshing token', error);
    return await userLogout(true, { message: AuthErrorKeys.Network, caller: LOGOUT_CALLERS.REFRESH_TOKEN });
  }
}

/**
 * Returns the URL to redirect the user to the Shamrock login page.
 * @param origin
 * @param errorCode
 * @returns string URL to login
 */
export const getShamrockRedirectUrl = (baseAzureRedirectUrl: string, errorCode?: string, errorMessage?: string) => {
  const origin = APPLICATION_URL;
  const parameters = new URLSearchParams({
    redirect_uri: origin,
    nonce: new Date().getTime().toString(),
  });

  if (errorCode) {
    parameters.append('error', errorCode);
  }

  if (errorMessage) {
    parameters.append('error', errorMessage);
  }

  return `${baseAzureRedirectUrl}&${parameters.toString()}`;
};

export const getAzureLogoutUrl = (baseAzureLogoutUrl: string, baseAzureRedirectUrl: string, errorCode?: string) => {
  // This function will return shamrock azure logout page URL
  // Since we use Shamrock to fetch the accessToken - we are login in on their Azure backend
  // So we will have to logout on the Azure backend
  const parameters = new URLSearchParams({
    redirect_uri: getShamrockRedirectUrl(baseAzureRedirectUrl, errorCode),
    error: errorCode ?? 'invalid_token',
  });
  return `${baseAzureLogoutUrl}&${parameters.toString()}`;
};

export const getTokenDetails = (accessTokenValue: string) => {
  // In the JSON Web Token (JWT) standard, the "kid" (key ID) claim is a string that indicates the key that was used to digitally sign the JWT.
  // This is used to verify the au  thenticity and integrity of the JWT, and to prevent JWT token abuse.
  const splitToken = accessTokenValue.split('.');
  const headerObj = JSON.parse(atob(splitToken[0]));
  const expiryDate = JSON.parse(atob(splitToken[1])).exp * 1000;
  return {
    kid: headerObj.kid,
    expiryDate: expiryDate,
  };
};

export const initialUserDetails = {
  accessToken: '',
  userId: '',
  customer: {
    customer: {},
    businessUnits: [],
  },
  customerPermissionsFromShamrock: [],
};

export const WarehouseInitialData = { payBillUrl: '', businessUnit: '', businessSegment: '', warehouseNumber: '' };

const checkIfApiFailed = async (status: number = 200, clearAllDataAndLogOut: () => {}) => {
  if (status == 401 || status == 403) {
    return await clearAllDataAndLogOut();
  }
  return;
};

export const fetchBuStoreDetails = async (
  buStoreKey: string,
  selectedBusinessUnitKey: string,
  clearAllDataAndLogOut: () => {},
  toast: ReturnType<typeof useToast>,
  toastMessage: string,
) => {
  try {
    const storeDetails = await getStoreDetailsForSelectedBu(buStoreKey, selectedBusinessUnitKey, toast, toastMessage);
    await checkIfApiFailed(storeDetails?.status, clearAllDataAndLogOut);

    if (storeDetails?.businessSegment !== '') {
      return storeDetails;
    } else {
      throw '"storeIds info" not received from getStoreDetailsForSelectedBuAPI. Maybe an issue in the accessToken. Please try logging in Incognito mode or another browser';
    }
  } catch (error) {
    console.error('Error API: fetchBuStoreDetails', error);
    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }
    await clearAllDataAndLogOut();
  }
};

export const selectTheAssociatedBuObjectFromBusinessUnits = (
  businessUnits: Partial<AsAssociateBusinessUnitResult>[],
  selectedBusinessUnitKey: string,
) => {
  return businessUnits?.find((businessUnit) => {
    return businessUnit['key'] == selectedBusinessUnitKey;
  });
};

export const getCustomerObject = (accessToken: string, loginResponse: LoginResponse): CustomerDataProps => {
  const { shamrockUser, commercetoolsUser, businessUnits } = loginResponse;
  return {
    accessToken: accessToken,
    userId: shamrockUser?.user?.userId,
    customer: {
      customer: commercetoolsUser,
      businessUnits: businessUnits,
    },
    customerPermissionsFromShamrock: shamrockUser?.user?.permissions?.data,
  };
};

/**
 * Check localstorage accessToken and if the expiration time is still valid.
 * @returns Boolean - true if the user is logged in, false otherwise
 */
export const isLoggedIn = (): Boolean => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN) || '';
  const expiresOn = getExpTimeFromAccessToken(accessToken);

  if (accessToken && expiresOn > 0) {
    const expirationTime = fromUnixTime(expiresOn);

    if (isAfter(expirationTime, new Date())) {
      return true;
    } else {
      // We have an expired access token, to avoid any issue we clear the data
      deleteLocalStorageValuesWithoutBu();
    }
  }

  return false;
};

/**
 * get expiration time from a jwt access token
 */
export const getExpTimeFromAccessToken = (accessToken: string) => {
  if (!accessToken) {
    return '';
  }
  try {
    const jwtData = JSON.parse(atob(accessToken.split('.')[1]));
    return jwtData?.exp;
  } catch (error) {
    return 0;
  }
};

/**
 * get the time the accessToken was issued.
 * @param accessToken
 * @returns number iat time
 */
export const getIatTimeFromAccessToken = (accessToken: string) => {
  if (!accessToken) {
    return '';
  }
  try {
    const jwtData = JSON.parse(atob(accessToken.split('.')[1]));
    return jwtData?.iat;
  } catch (error) {
    console.error('failed to parse accessToken');
    return '';
  }
};

/**
 * Returns the URL to redirect the user to the Shamrock login page.
 * Called on server functions to avoid server timeout
 * Legacy function that still uses frontend env vars
 * @param errorCode
 * @returns string URL to login
 */

// Add more fields here as needed
export interface GetServerShamrockRedirectUrlParams {
  redirectTo: string;
}

export const getServerShamrockRedirectUrl = (
  origin: string,
  errorCode?: string,
  errorMessage?: string,
  params?: GetServerShamrockRedirectUrlParams,
) => {
  const shamrockUrl = new URL(process.env.NEXT_PUBLIC_SHAMROCK_REDIRECT_URL);

  // stringify the params object to pass it as a query param
  const paramsAsString = JSON.stringify(params);

  const parameters = new URLSearchParams({
    response_type: 'code',
    client_id: process.env.NEXT_PUBLIC_SHAMROCK_CLIENT_ID,
    redirect_uri: origin,
    scope: process.env.NEXT_PUBLIC_SHAMROCK_REDIRECT_URL_SCOPE,
    nonce: new Date().getTime().toString(),
    [ADDITIONAL_AZURE_URL_PARAMS]: paramsAsString,
  });

  if (errorCode) {
    parameters.append('error', errorCode);
  }

  if (errorMessage) {
    parameters.append('error', errorMessage);
  }

  shamrockUrl.search = parameters.toString();

  return shamrockUrl.toString();
};

/**
 * Finish user Azure session and redirect to Shamrock login page.
 * Called on server functions to avoid server timeout
 * Legacy function that still uses frontend env vars
 * @param errorCode
 * @returns string URL to login
 */
export const getServerAzureLogoutUrl = (errorCode?: string, originPath?: string) => {
  // This function will return shamrock azure logout page URL
  // Since we use Shamrock to fetch the accessToken - we are login in on their Azure backend
  // So we will have to logout on the Azure backend
  const AzureUrl = new URL(process.env.NEXT_PUBLIC_SHAMROCK_AZURE_LOGOUT);
  const state = { redirectTo: originPath ?? routes.HOME };
  const parameters = new URLSearchParams({
    redirect_uri: getServerShamrockRedirectUrl(APPLICATION_URL, errorCode, null, state),
    error: errorCode ?? 'invalid_token',
  });
  AzureUrl.search = parameters.toString();
  return AzureUrl.toString();
};

/**
 * Checks if a cookie with the specified name exists.
 *
 * @param {string} name - The name of the cookie to check for.
 * @returns {boolean} - Returns true if the cookie exists, false otherwise.
 */
export const hasCookie = (name: string): boolean => {
  return (document?.cookie.split(';') || []).some((item) => item.trim().startsWith(name + '='));
};

export const isSessionAlreadyExpired = (accessToken: string) => {
  const issuedUnixTime = getIatTimeFromAccessToken(accessToken);
  const issuedAt = fromUnixTime(issuedUnixTime);
  const now = new Date();
  const loginLimit = add(issuedAt, { hours: AUTHENTICATION_SESSION_LIMIT_HOURS });
  return isAfter(now, loginLimit) || isEqual(now, loginLimit);
};

/**
 * Checks if a given string is a well-formed JWT.
 *
 * @param {string} token - The JWT to check.
 * @returns {boolean} - Returns true if the token is well-formed, false otherwise.
 */
export const isWellFormedJWT = (token: string): boolean => {
  if (!token) return false;
  const parts = token.split('.');
  try {
    const header = JSON.parse(atob(parts[0]));

    return typeof header === 'object' && Boolean(header?.kid);
  } catch (e) {
    return false;
  }
};
