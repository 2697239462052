import { useCallback, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { KEYBOARD_KEYS } from 'composable/components/pdp/constants';
import routes from 'helpers/constants/routes';

export const useGlobalEscapeKeyPress = (
  onPress: () => void,
  onDoublePress: () => void,
  inputRef: React.RefObject<HTMLInputElement>,
  disable?: boolean,
) => {
  const lastKeyPressRef = useRef<number>(0);
  const { asPath } = useRouter();

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const isCustomModalOpen = document.querySelector('#chakra-modal-custom-modal');

      if (!!isCustomModalOpen || asPath === routes.ORDER_GUIDE) {
        return;
      }

      if (event.key === KEYBOARD_KEYS.ESC && !disable) {
        event.preventDefault();
        event.stopPropagation();

        const currentTime = Date.now();
        const timeSinceLastPress = currentTime - lastKeyPressRef.current;

        if (timeSinceLastPress <= 300) {
          onDoublePress();
        } else {
          onPress();
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }

        lastKeyPressRef.current = currentTime;
      }
    },
    [onPress, onDoublePress, inputRef, disable, asPath],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, true);
    return () => {
      document.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [handleKeyDown]);
};
