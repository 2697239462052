export function determineColor(darkVariant: boolean, currentPrice: number, discountedPrice: number) {
  if (darkVariant && currentPrice !== discountedPrice) {
    return 'yellow.400';
  } else if (darkVariant) {
    return 'white';
  } else if (currentPrice === discountedPrice) {
    return 'neutral.900';
  } else {
    return 'yellow.600';
  }
}

export const getVariantColorForText = (variant: string) => {
  switch (variant) {
    case 'plp':
      return 'neutral.600';
    case 'pdp':
      return 'neutral.300';
    default:
      return 'neutral.600';
  }
};
