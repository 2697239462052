import { FunctionComponent, PropsWithChildren } from 'react';
import { Text, TextProps } from '@chakra-ui/react';

type PricePerUnitProps = {
  textProps?: TextProps;
};

export const PricePerUnit: FunctionComponent<PropsWithChildren<PricePerUnitProps>> = ({ children, textProps }) => {
  return (
    <Text
      className="price-per-unit"
      fontSize={{ base: 'xs', md: 'sm' }}
      color="neutral.600"
      pt={1}
      lineHeight={{ base: '18px', md: '21px' }}
      {...textProps}
      noOfLines={1}
    >
      {children}
    </Text>
  );
};
