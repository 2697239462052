/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useEffect, useReducer, useRef } from 'react';
import { useRouter } from 'next/router';
import { useToast } from '@chakra-ui/react';
import { algoliaInitSearchInsights } from 'composable/analytics/algolia/algolia-tracking';
import { analyticsTrackLogin } from 'composable/analytics/analytics-event-tracking';
import { APPCUES_ID } from 'composable/analytics/appcues';
import { appcuesIdentifyAndGroupUser } from 'composable/analytics/appcues/appcues-tracking';
import { LOGROCKET_ID } from 'composable/analytics/logrocket/constants';
import { logrocktIdentifyUser } from 'composable/analytics/logrocket/logrocket-tracking';
import { APPLICATION_URL, TOAST_ICON } from 'composable/components/general';
import { DRAFT_CART_ID } from 'composable/components/mini-cart/helpers';
import { impersonatorIdFromStorage } from 'composable/components/super-user/helpers/utils';
import { ACCESS_TOKEN, REFRESH_TOKEN, SELECTED_BUSINESS_UNIT_KEY } from 'composable/helpers/constants';
import { useLocalStorage } from 'composable/helpers/hooks';
import { getAlgoliaKey } from 'composable/helpers/utils/user-utils';
import { AuthErrorKeys } from 'helpers/constants/auth';
import { LOGIN_METHOD } from 'helpers/constants/eventTracking';
import { permission } from 'helpers/constants/permissions';
import routes from 'helpers/constants/routes';
import { useFormat } from 'helpers/hooks';
import { isSlugValid, isSuperUserPage as isSuperUserPageCb } from 'helpers/slugHelpers';
import { trackShamrockUserLogin } from 'helpers/utils/trackShamrockUserLogin';
import {
  getBrowserAccessToken,
  getCustomerObject,
  getExpTimeFromAccessToken,
  getServerShamrockRedirectUrl,
  GetServerShamrockRedirectUrlParams,
  isSessionAlreadyExpired,
  isStillValidToken,
  isWellFormedJWT,
  refreshAccessToken,
} from 'hooks/global/use_privateUserGlobal/utils';
import { trackLoginPage } from 'hooks/useTrackingLoginPage';
import { useSWRConfig } from 'swr';
import { fetchApiHub } from 'frontastic';
import { login, getMdLogin, debugLogin, LoginResponse } from 'frontastic/actions/account';
import { getAllCartsPerAccount } from 'frontastic/actions/cart';
import { LOGOUT_CALLERS } from './constants';
import { reduceUser, userInitialState } from './reduce-user';
import { LogLoginType, UserGlobalStateActions } from './types';
import { getAdditionalAzureUrlParams, selectedAccountDetails, userLogout, validateAccessToken } from './utils';

const QUERY_PARAM_CODE = 'code';

export const use_privateUserGlobal = ({ isPublic, isOffline }) => {
  const [state, dispatch] = useReducer(reduceUser, userInitialState);
  const [localStorageAccessToken] = useLocalStorage(ACCESS_TOKEN, '');
  const [localStorageRefreshToken] = useLocalStorage(REFRESH_TOKEN, '');

  const activeAccountKey = typeof window !== 'undefined' ? window.localStorage.getItem(SELECTED_BUSINESS_UNIT_KEY) : '';
  const toast = useToast();
  const router = useRouter();
  const { formatMessage } = useFormat({ name: 'common' });
  const azureRedirectUrl = useRef('');
  const { cache } = useSWRConfig();

  //On this way we execute the algoliaInitSearchInsights only once
  useEffect(() => {
    if (state.ctUser && state.activeAccount) {
      algoliaInitSearchInsights({ user: state.ctUser.customer, activeAccount: state.activeAccount });
    }
  }, [state.ctUser, state.activeAccount]);

  //Super User Guard
  useEffect(() => {
    const isSuperUserPage = isSuperUserPageCb(router.asPath);
    if (state.isSuperUser && isSuperUserPage) {
      dispatch({ type: UserGlobalStateActions.SET_LOADING, payload: { loading: false } });
      return;
    }
    if (isSuperUserPage && !state.isSuperUser) {
      router.push(routes.HOME);
    }
  }, [router.asPath, state.isSuperUser]);

  // Login once we hit here.
  useEffect(() => {
    if (state.readyToLogin) {
      checkIfLoggedIn();
    }
  }, [state.readyToLogin]);

  //To have one only single source of truth for the access token
  //From this point we don't need to use the localstorage values
  useEffect(() => {
    dispatch({
      type: UserGlobalStateActions.SET_ACCESS_TOKEN,
      payload: {
        accessToken: localStorageAccessToken,
        refreshToken: localStorageRefreshToken,
        expiresOn: getExpTimeFromAccessToken(localStorageAccessToken),
        readyToLogin: true,
      },
    });
  }, []);

  useEffect(() => {
    if (state.accountList.length > 1) {
      if (state.accountList[0]?.cartsQuantity === undefined) {
        fetchAllAccountsWithCarts();
      }
    }
  }, [state.accountList]);

  const setAzureUrls = () => {
    const asPath = router.asPath.split('?')?.[0] ?? routes.HOME;
    // Additional params to be passed to the azure redirect url
    const params: GetServerShamrockRedirectUrlParams = { redirectTo: asPath };
    azureRedirectUrl.current = getServerShamrockRedirectUrl(APPLICATION_URL, '301', '', params);
  };

  const successCallback = async (
    accessToken: string,
    clientIp: string,
    stateSuccess: any,
    trackLogin: boolean = true,
  ) => {
    if (trackLogin) {
      trackShamrockUserLogin({ accessToken, clientIp });
      analyticsTrackLogin({
        method: LOGIN_METHOD,
        customerNumber: stateSuccess?.activeAccount?.key || activeAccountKey,
      });
    }

    // Call to logrocket if logrocketid is available to identify user
    if (LOGROCKET_ID) {
      const superUserEmail = state?.isSuperUser ? state?.superUserEmail : null;
      logrocktIdentifyUser(stateSuccess?.ctUser?.customer, stateSuccess?.activeAccount, superUserEmail);
    }

    const accessCallback = window.localStorage.getItem(ACCESS_TOKEN);

    if (accessCallback) {
      // Extract any additional parameters from the azure redirect url
      const additionalParams = getAdditionalAzureUrlParams(azureRedirectUrl.current);

      let { code, state: queryState, redirectTo } = router.query;

      // Parse the query state if present
      const queryStateParsed = !!queryState && queryState !== 'undefined' ? JSON.parse(queryState as string) : null;
      let queryRedirectTo = queryStateParsed?.redirectTo || redirectTo;

      if (!!queryRedirectTo && queryRedirectTo.includes(routes.PUBLIC_PDP)) {
        queryRedirectTo = (queryRedirectTo as string).replace(routes.PUBLIC_PDP, routes.PDP);
      }

      let redirectToValue = queryRedirectTo ? queryRedirectTo : additionalParams.redirectTo;
      redirectToValue = isSlugValid(redirectToValue) ? redirectToValue : routes.HOME;

      // Reconstruct URL to include query params if any
      const redirectUrl = new URL(redirectToValue, window.location.origin);
      if (router.query.query) {
        redirectUrl.searchParams.set('query', router.query.query as string);
      }
      if (router.query.brand) {
        redirectUrl.searchParams.set('brand', router.query.brand as string);
      }

      // Redirect only if we have the `code` param, indicating first login
      if (code) {
        router.replace(
          {
            pathname: redirectUrl.pathname,
            query: redirectUrl.searchParams.toString() ? redirectUrl.searchParams.toString() : undefined,
            hash: redirectUrl.hash || '',
          },
          undefined,
          { shallow: redirectUrl.pathname === routes.HOME },
        );
      }

      setTimeout(() => {
        dispatch({ type: UserGlobalStateActions.SET_LOADING, payload: { loading: false } });
      }, 1000);

      // Delaying appcues call after login to avoid issues while resolving redirects.
      setTimeout(() => {
        // Call to appcues if appcuesid and customer information is available
        if (APPCUES_ID) {
          appcuesIdentifyAndGroupUser(
            stateSuccess?.ctUser?.customer,
            stateSuccess?.activeAccount,
            stateSuccess?.shamrockUser?.user.permissions?.data,
          );
        }
      }, 3000);
    }
  };

  const logout = useCallback(async (hardLogout: boolean = false, message?: string, caller?: string | object) => {
    hardLogout && dispatch({ type: UserGlobalStateActions.SET_LOADING, payload: { loading: true } });
    userLogout(hardLogout, { message, originPath: router.asPath, caller: caller || 'UseUser' }, syncTokens);
  }, []);

  const setSelectedAccount = async (
    accessToken: string,
    accountKey: string,
    shouldTrackLogin: boolean = true,
    refreshToken?: string,
    impersonatedUserId?: string,
  ) => {
    const impersonatorId = impersonatedUserId || impersonatorIdFromStorage();
    try {
      if (!accountKey) {
        accountKey =
          typeof window !== 'undefined' ? window.localStorage.getItem(SELECTED_BUSINESS_UNIT_KEY) : activeAccountKey;
      }

      const loginResponse = await login(accessToken, accountKey, impersonatedUserId || impersonatorId);
      if (loginResponse?.isSuperUser) {
        //We use router instead window.href.location to avoid a new login call
        router.push(routes.SUPER_USER_ACCOUNTS);
        dispatch({
          type: UserGlobalStateActions.SET_IS_SUPER_USER,
          payload: { isSuperUser: true, superUserEmail: loginResponse?.superUserEmail },
        });
        return;
      }

      if (loginResponse.statusCode || loginResponse.error_code) {
        throw loginResponse;
      }

      window.localStorage.setItem(SELECTED_BUSINESS_UNIT_KEY, loginResponse?.selectedBuKey || accountKey);

      const customerObject = getCustomerObject(accessToken, loginResponse);

      const actionPayload = {
        accessToken: accessToken,
        refreshToken: refreshToken || localStorageRefreshToken,
        expiresOn: getExpTimeFromAccessToken(accessToken),
        shamrockUser: loginResponse.shamrockUser,
        ctUser: customerObject.customer,
        activeAccount: selectedAccountDetails(loginResponse.selectedBuKey, loginResponse.businessUnits),
        activeWarehouse: { ...loginResponse.store.details, id: loginResponse.store.result.id },
        accountList: loginResponse.businessUnits,
      };
      dispatch({
        type: UserGlobalStateActions.SET_LOGGED_AS_SUPER_USER,
        payload: { loggedAsSuperUser: loginResponse.loggedAsSuperUser },
      });
      dispatch({ type: UserGlobalStateActions.LOGIN, payload: actionPayload });
      successCallback(accessToken, loginResponse.clientIp, actionPayload, shouldTrackLogin);
      return loginResponse;
    } catch (error) {
      // avoid user not found getting stuck in loading
      console.warn('##DLDebug error during login process', error);

      if (error?.message) {
        // if impersonatorId is present, we should throw the error to avoid the user getting stuck in the login
        // the error will be handled in the impersonateUser function
        if (impersonatorId && state.isSuperUser) {
          throw error;
        }
        try {
          const parsedError = JSON.parse(error.message);
          console.warn('##DLDebug error code', parsedError?.error_code);

          const knownError = Object.values(AuthErrorKeys).includes(parsedError?.error_code);
          const errorToDisplay = knownError ? parsedError?.error_code : AuthErrorKeys.Unauthorized;

          await logout(true, impersonatorId ? undefined : errorToDisplay, LOGOUT_CALLERS.ERROR_ON_SET_ACCOUNT);
          return;
        } catch (e) {
          console.error('Error parsing login message', e);
          await logout(true, AuthErrorKeys.Unauthorized, LOGOUT_CALLERS.ERROR_ON_SET_ACCOUNT_PARSING_ERROR);
          return;
        }
      }

      await logout(true, AuthErrorKeys.Network, LOGOUT_CALLERS.ERROR_ON_SET_ACCOUNT_NO_ERROR_MESSAGE);
    }
  };

  const impersonateUser = async (accessToken: string, accountKey: string, impersonatedUserId: string) => {
    console.log('##DLDebug impersonateUser', accountKey, impersonatedUserId);
    try {
      //The reason to handle the loading state here is because we need to wait for the user to be logged in
      //before redirecting to the home page, we se again the loader since by default the setSelectedAccount function sets it to false
      dispatch({ type: UserGlobalStateActions.SET_STATE_FOR_NEW_IMPERSONATION });
      await setSelectedAccount(accessToken, accountKey, true, localStorageRefreshToken, impersonatedUserId);
      router.push(routes.HOME);
      dispatch({
        type: UserGlobalStateActions.SET_LOGGED_AS_SUPER_USER,
        payload: { loggedAsSuperUser: true },
      });
    } catch (error) {
      dispatch({ type: UserGlobalStateActions.SET_LOADING, payload: { loading: false } });
      toast({
        duration: 5000,
        status: 'error',
        title: formatMessage({ id: 'superUser.error.message' }),
        icon: TOAST_ICON.error,
      });
    }
  };

  const switchAccount = useCallback(
    async (accountKey: string) => {
      if (!accountKey) {
        return;
      }

      for (const key of cache.keys()) {
        cache.delete(key);
      }
      dispatch({ type: UserGlobalStateActions.SET_LOADING, payload: { loading: true } });
      localStorage.setItem('previousAccount', activeAccountKey);
      localStorage.removeItem(DRAFT_CART_ID);
      try {
        await fetchApiHub(
          '/action/account/switchAccount',
          { method: 'POST' },
          { accessToken: state.accessToken, selectedBuKey: accountKey },
        );

        await setSelectedAccount(state.accessToken, accountKey, true, '');

        toast({
          status: 'success',
          title: formatMessage({ id: 'switchAccount.toast.success' }),
          duration: 5000,
          icon: TOAST_ICON.success,
        });
      } catch (error) {
        console.error('Error while switching account', error);
      }
    },
    [state.accessToken],
  );

  const fetchAllAccountsWithCarts = useCallback(async () => {
    try {
      const response = await getAllCartsPerAccount({
        accountList: state.accountList,
        associateId: state.ctUser?.customer?.id,
      });
      if (response) {
        dispatch({ type: UserGlobalStateActions.SET_ACCOUNT_LIST, payload: { accountList: response } });
      }
      return response;
    } catch (error) {
      console.error('GLOBAL Cart error: fetchAllAccountsCarts', error);
    }
  }, [state.accountList]);

  //Principal function to check if the user is logged in
  const checkIfLoggedIn = async () => {
    let loginAccessToken = state.accessToken;
    let loginRefreshToken = state.refreshToken;
    const incrementalLog: Partial<LogLoginType> = {
      tokens: {
        access: (loginAccessToken ?? '').substring(0, 5),
        refresh: (loginRefreshToken ?? '').substring(0, 5),
      },
      url: window.location.search,
    };

    const setAzureUrlsstartTime = performance.now();
    setAzureUrls();
    incrementalLog.azureUrl = azureRedirectUrl.current;
    const code = new URLSearchParams(window.location.search).get(QUERY_PARAM_CODE);
    if (isPublic && !loginAccessToken) {
      dispatch({ type: UserGlobalStateActions.SET_LOADING, payload: { loading: false } });
      return;
    }

    // Handle users redirected to redirect-from-so route
    if (router?.pathname?.toLowerCase().includes(routes.REDIRECT_FROM_SO.toLowerCase())) {
      incrementalLog.redirectedFromShamrockOrders = true;
      console.warn('DLDebug Redirecting user to login because user landed on the redirection page', incrementalLog);
      await debugLogin(incrementalLog);
      await logout(false, undefined, { caller: LOGOUT_CALLERS.REDIRECT_FROM_SO, log: incrementalLog });
      return;
    }

    //If we don't have a valid token and we don't have a code in the query and there is not a refresh token
    //Inmediatly hard logout the user
    if (!isStillValidToken(loginAccessToken) && !isWellFormedJWT(loginRefreshToken) && !code) {
      incrementalLog.noCodeQueryParamNoAccessToken = true;
      await debugLogin(incrementalLog);
      await logout(true, undefined, {
        caller: LOGOUT_CALLERS.ERROR_NO_VALID_ACCESS_TOKEN_AND_NO_CODE,
        log: incrementalLog,
      });
      return;
    }

    if (isWellFormedJWT(loginAccessToken) && isSessionAlreadyExpired(loginAccessToken)) {
      incrementalLog.sessionExpired = true;
      await debugLogin(incrementalLog);
      await logout(true, undefined, {
        caller: LOGOUT_CALLERS.ERROR_SESSION_EXPIRED,
        log: incrementalLog,
      });
      return;
    }

    let hasValidToken = isStillValidToken(loginAccessToken);
    let shouldTrackLogin = false;
    const canIGetTokens = Boolean(!hasValidToken && code);

    incrementalLog.hasValidToken = hasValidToken;
    incrementalLog.canIGetTokens = canIGetTokens;

    const setTokenStartTime = performance.now();

    if (canIGetTokens) {
      const {
        accessToken,
        refreshToken,
        expiresOn,
        origin,
        incrementalLog: logFromGetTokens,
      } = await getBrowserAccessToken(code);

      incrementalLog.responseFromBrowserAccessCall = {
        accessToken: (accessToken ?? '').substring(0, 5),
        refreshToken: (refreshToken ?? '').substring(0, 5),
        expiresOn,
        origin,
        ...logFromGetTokens,
      };

      // if we couldn't get the tokens, we should logout the user
      if (!accessToken) {
        await debugLogin(incrementalLog);
        // couldn't get tokens so we need to logout the user. Doing a hard logout to avoid redirection loop
        await logout(true, AuthErrorKeys.UserNotFound, {
          caller: LOGOUT_CALLERS.ERROR_FAILED_TO_GET_TOKENS,
          log: incrementalLog,
        });
        return;
      }

      if (accessToken && refreshToken) {
        syncTokens(accessToken, refreshToken);

        loginAccessToken = accessToken;
        loginRefreshToken = refreshToken;
        shouldTrackLogin = true;
        hasValidToken = isStillValidToken(loginAccessToken);
        incrementalLog.shouldTrackLogin = shouldTrackLogin;
      }
    }

    const tryToRefreshToken = !hasValidToken && Boolean(loginRefreshToken);
    if (tryToRefreshToken) {
      incrementalLog.refreshToken = {
        attempt: true,
      };
      await refreshAccessToken(loginRefreshToken, (getTokensResponse) => {
        if (validateAccessToken(getTokensResponse.access_token)) {
          incrementalLog.refreshToken.success = true;
          syncTokens(getTokensResponse.access_token, getTokensResponse.refresh_token);

          loginAccessToken = getTokensResponse.access_token;
          loginRefreshToken = getTokensResponse.refresh_token;
          hasValidToken = true;
        }
      });
    }

    // We already tried to refresh the token and we still don't have a valid token
    if (!hasValidToken && !tryToRefreshToken) {
      incrementalLog.unableToLogin = true;
      console.warn('DLDebug Unable to login user, redirecting to login page', incrementalLog);
      await debugLogin(incrementalLog);
      await logout(true, undefined, { caller: LOGOUT_CALLERS.ERROR_UNABLE_TO_LOGIN, log: incrementalLog });
      return;
    }

    if (hasValidToken && !isOffline) {
      const loginResponse: LoginResponse | undefined = await setSelectedAccount(
        loginAccessToken,
        activeAccountKey,
        shouldTrackLogin,
        loginRefreshToken,
      );
      if (!loginResponse) {
        return;
      }

      incrementalLog.loginCall = {
        buLength: loginResponse?.businessUnits?.length,
        isSuperUser: loginResponse?.isSuperUser,
        frontasticRequestid: loginResponse?.frontasticRequestid,
        shamrockUserId: loginResponse?.shamrockUser?.user?.userId,
      };
    }

    const setTokenEndTime = performance.now();
    const setAzureUrlsendTime = performance.now();
    const mdGetAzureUrls = setAzureUrlsendTime - setAzureUrlsstartTime;
    const mdGetTokens = setTokenEndTime - setTokenStartTime;
    const mdLogin = getMdLogin();

    if (code && shouldTrackLogin) {
      trackLoginPage({
        mdGetAzureUrls: mdGetAzureUrls,
        mdLogin: mdLogin,
        mdGetTokens: mdGetTokens,
      });
    }
    await debugLogin(incrementalLog);
  };

  //eslint-disable-next-line
  const canViewProductCatalog = useCallback(() => {
    // If user get error on algoliaKey treat as permission denied
    if (!state.loading && state?.activeAccount?.key) {
      const algoliaKey = getAlgoliaKey(state.activeAccount, state.ctUser?.customer);

      if (!algoliaKey) {
        return false;
      }
    }

    return state.shamrockUser?.user?.permissions?.data?.includes(permission.CanViewProductCatalog) || state.loading;
  }, [state.shamrockUser?.user, state.loading, state.activeAccount?.key]);

  const syncTokens = useCallback((accessToken: string, refreshToken: string) => {
    window.localStorage.setItem(ACCESS_TOKEN, accessToken);
    Boolean(refreshToken) && window.localStorage.setItem(REFRESH_TOKEN, refreshToken);
    dispatch({
      type: UserGlobalStateActions.SET_ACCESS_TOKEN,
      payload: {
        accessToken,
        refreshToken,
        expiresOn: getExpTimeFromAccessToken(accessToken),
      },
    });
  }, []);

  return {
    state,
    dispatch,
    logout,
    switchAccount,
    canViewProductCatalog,
    impersonateUser,
    azureRedirectUrl: azureRedirectUrl.current,
    successCallback,
    fetchAllAccountsWithCarts,
  };
};
