export const PRODUCT_CARD_TEST_IDS = {
  ERROR_MESSAGE: (sku: string) => `product-error-message-${sku}`,
  LAST_PURCHASED: (sku: string) => `product-last-purchased-${sku}`,
  ORDER_BY: (sku: string) => `product-order-by-${sku}`,
  STOCK_STATUS: (sku: string) => `product-stock-status-${sku}`,
  DUE_DATE: (sku: string) => `product-due-date-${sku}`,
  QUANTITY_AVAILABLE: (sku: string) => `product-quantity-available-${sku}`,
} as const;

export const CHECKOUT_TEST_IDS = {
  DELIVERY_METHOD: {
    'standard-shipping-method': 'delivery-method-scheduled-delivery',
    'willcall-shipping-method': 'delivery-method-pickup',
    'alc-shipping-method': 'delivery-method-alc',
  },
  DELIVERY_DATE: (index: number) => `delivery-date-${index}`,
  DELIVERY_WINDOW: (index: number) => `delivery-window-${index}`,
} as const;
