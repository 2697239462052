import { AsAssociateBusinessUnitResult } from '@Types/business-unit/BusinessUnit';
import { OrderDetail } from '@Types/shamrockApi/Order';
import { FEATURE_FLAGS } from 'composable/components/general';
import { addHours, isAfter, isBefore, isSameDay } from 'date-fns';
import { getPlanByStoreKey } from '../hooks';

/**
 * Reference https://oriumhq.jira.com/browse/SHAM-2528
 */

/**
 * Adjusts the cutoff time for Daylight Saving Time (DST) based on the anchor date.
 *
 * @param {Date} anchorDate - The anchor date to compare against.
 * @param {Date} cutoffTime - The cutoff time to adjust.
 * @returns {Date} - The adjusted cutoff time considering DST.
 */
export function handleDST(anchorDate: Date, cutoffTime: Date) {
  if (!FEATURE_FLAGS.DST_ADJUSTMENTS) {
    return cutoffTime;
  }

  // A. Convert the Anchor date to the user timezone
  const anchorLocal = new Date(anchorDate);
  const cutoffLocal = new Date(cutoffTime);

  // B. Get the difference of the cutoff time and the local time
  const offsetHours = (cutoffLocal.getTimezoneOffset() - anchorLocal.getTimezoneOffset()) / 60;

  // C. Apply the offset from point B to the cutoff time
  const cutoffTimeLocal = addHours(cutoffLocal, offsetHours);
  return cutoffTimeLocal;
}

/**
 * Gets the active anchor date for the given account.
 *
 * @param {Partial<AsAssociateBusinessUnitResult>} activeAccount - The active account information.
 * @returns {Date} - The active anchor date or a fallback date if no valid schedule is found.
 */
export function getActiveAnchorDate(activeAccount: Partial<AsAssociateBusinessUnitResult>) {
  const fallbackAnchorDate = new Date(activeAccount?.lastModifiedAt);
  const plans = getPlanByStoreKey(activeAccount);

  if (!plans || !plans.length) {
    return fallbackAnchorDate;
  }
  const today = new Date();
  const currentSchedule = plans[0].DeliverySchedules?.find((schedule) => {
    const startDateTime = new Date(schedule?.StartDateTime);
    const endDateTime = schedule?.EndDateTime ? new Date(schedule.EndDateTime) : today;
    return isAfter(today, startDateTime) && (isBefore(today, endDateTime) || isSameDay(today, endDateTime));
  });

  const anchorDateSchedule = new Date(currentSchedule?.AnchorDateTime);
  return isValidDay(anchorDateSchedule) ? anchorDateSchedule : fallbackAnchorDate || new Date();
}

/**
 * Checks if a given date is valid.
 *
 * @param {Date} date - The date to check.
 * @returns {boolean} - True if the date is valid, false otherwise.
 */
const isValidDay = (date: Date): boolean => !isNaN(date.getTime());

/**
 * Converts the estimated delivery date and time slot to UTC.
 *
 * @param {string | null} estimatedDeliveryDate - The estimated delivery date in ISO format.
 * @param {OrderDetail['estimatedDeliveryTimeSlot'] | null} estimatedDeliveryTimeSlot - The estimated delivery time slot containing start and end times.
 * @returns {{ startTimeUTC: Date, endTimeUTC: Date } | null} - An object containing the start and end times in UTC, or null if inputs are invalid.
 */
export const mountEstimatedDeliveryTimeSlotUTC = (
  estimatedDeliveryDate: string | null,
  estimatedDeliveryTimeSlot: OrderDetail['estimatedDeliveryTimeSlot'] | null,
) => {
  if (!estimatedDeliveryDate || !estimatedDeliveryTimeSlot) {
    return null;
  }

  const startTimeUTC = new Date(`${estimatedDeliveryDate}T${estimatedDeliveryTimeSlot.deliveryWindowStartTime}Z`);
  const endTimeUTC = new Date(`${estimatedDeliveryDate}T${estimatedDeliveryTimeSlot.deliveryWindowEndTime}Z`);

  return {
    startTimeUTC,
    endTimeUTC,
  };
};
