// TODO add TSDoc
import { useToast } from '@chakra-ui/react';
import { Address } from '@Types/account/Address';
import { AsAssociateBusinessUnitResult } from '@Types/business-unit/BusinessUnit';
import { Cart } from '@Types/cart/Cart';
import { CustomFields } from '@Types/cart/CustomFields';
import { Discount } from '@Types/cart/Discount';
import { LineItem } from '@Types/cart/LineItem';
import { Payment } from '@Types/cart/Payment';
import { Money } from '@Types/product/Money';
import { Variant } from '@Types/product/Variant';
import { OrderFilter } from '@Types/query/OrderFilter';
import { GENERIC_TOAST_ERROR_ID, TOAST_ICON, UPDATE_LINE_ITEM_ERROR_ID } from 'composable/components/general';
import { CART_ID, LAST_CHECKOUT_CART_ID } from 'composable/helpers/constants';
import { useProductExtraData } from 'helpers/hooks/useProductExtraData';
import { calculateNewLineItems } from 'helpers/utils/calculateNewLineItems';
import { removeProductFromLocalStorage } from 'helpers/utils/removeProductFromLocalStorage';
import delay from 'lodash/delay';
import useSWR, { mutate } from 'swr';
import { fetchApiHub, revalidateOptions } from 'frontastic';

export type CartDetails = {
  account?: { email: string };
  shipping?: Address;
  billing?: Address;
  custom?: CustomFields;
};

export type AddToCartItem = {
  variant: Variant;
  quantity: number;
  price: Money;
};

export type CheckoutPayload = {
  purchaseOrderNumber?: string;
};

export const cartItems = (toast: ReturnType<typeof useToast>, toastMessage: string, shouldLoad: boolean = true) => {
  try {
    //eslint-disable-next-line
    return useSWR(shouldLoad ? '/action/cart/getCart' : null, fetchApiHub, {
      // these options need to be set to true for the submitted toast cart message work properly
      // file ref: frontend/helpers/hooks/useSubmittedCartHandler.ts
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    });
  } catch (error) {
    console.error('Error API: getCart', error);
    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }
    throw error;
  }
};

export const cartItemsWithProductExtraData = (
  toast: ReturnType<typeof useToast>,
  toastMessage: string,
  shouldLoad?: boolean,
) => {
  const response = cartItems(toast, toastMessage, shouldLoad);
  const lineItems = response.data?.lineItems ?? [];
  const skus = lineItems.map((item) => item?.variant?.sku ?? '');
  //eslint-disable-next-line
  const { extraProductData, isLoadingShamrockData, isValidatingShamrockData } = useProductExtraData(skus);

  const newLineItems = calculateNewLineItems(lineItems, extraProductData);

  const sumCentAmount = newLineItems.reduce((acc, cur) => acc + (cur.totalPrice?.centAmount ?? 0), 0);

  return {
    ...response,
    data: {
      ...response.data,
      lineItems: newLineItems,
      sum: { ...response.data?.sum, centAmount: sumCentAmount },
    },
    isLoading: response.isLoading || isLoadingShamrockData,
    isValidating: response.isValidating || isValidatingShamrockData,
  };
};

export const getCart = async (toast: ReturnType<typeof useToast>, toastMessage: string) => {
  try {
    const res = await fetchApiHub(
      '/action/cart/getCart',
      {
        method: 'POST',
      },
      {},
      10,
    );
    mutate('/action/cart/getCart', res, { revalidate: false });
    return res;
  } catch (error) {
    console.error('Error API: getCart', error);
    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }
    throw error;
  }
};

export const checkout = async (toast: ReturnType<typeof useToast>, toastMessage: string, payload?: CheckoutPayload) => {
  try {
    const res = await fetchApiHub(
      '/action/cart/checkout',
      {
        method: 'POST',
      },
      payload,
    );
    window.localStorage.setItem(LAST_CHECKOUT_CART_ID, res.cart.cartId);
    mutate('/action/cart/getCart', res);
    return res;
  } catch (error) {
    console.error('Error API: checkout', error);
    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }
    throw error;
  }
};

export const orderHistory = async (toast: ReturnType<typeof useToast>, toastMessage: string, filter?: OrderFilter) => {
  try {
    return await fetchApiHub('/action/cart/getOrders', { method: 'POST' }, { filter });
  } catch (error) {
    console.error('Error API: orderHistory', error);
    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }
    throw error;
  }
};

export const getProjectSettings = async (toast: ReturnType<typeof useToast>, toastMessage: string) => {
  try {
    throw new Error();
    return await fetchApiHub('/action/project/getProjectSettings');
  } catch (error) {
    console.error('Error API: getProjectSettings', error);
    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }
    throw error;
  }
};

export const getShippingMethods = (
  toast: ReturnType<typeof useToast>,
  toastMessage: string,
  shouldLoad: boolean = true,
) => {
  try {
    //eslint-disable-next-line
    return useSWR(shouldLoad ? '/action/cart/getShippingMethods' : null, fetchApiHub, {
      ...revalidateOptions,
      revalidateOnFocus: false,
    });
  } catch (error) {
    console.error('Error API: getShippingMethods', error);
    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }
    throw error;
  }
};

export const addItem = async (items: AddToCartItem[], toast: ReturnType<typeof useToast>, toastMessage: string) => {
  const payload = items.map((item) => ({
    variant: {
      sku: item.variant.sku,
      quantity: item.quantity,
      externalPrice: item.price,
      value: item.price,
    },
  }));

  try {
    const cartId = window.localStorage.getItem(CART_ID);

    if (!cartId) {
      return;
    }

    const cart = (await fetchApiHub(
      `/action/cart/addToCart?cartId=${cartId}`,
      {
        method: 'POST',
      },
      payload,
    )) as Cart;

    if (cart.cartId) {
      delay(() => {
        mutate('/action/cart/getCart', cart, { revalidate: false });
      }, 100);
      return { success: true, nextCart: cart };
    }
    return { success: false };
  } catch (err) {
    console.error('Error API: addItem', err);
    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }

    return { success: false, error: err };
  }
};

export const removeItem = async (
  lineItemId: string,
  toast: ReturnType<typeof useToast>,
  toastMessage: string,
  prevCart?: Partial<Cart>,
) => {
  const payload = {
    lineItem: { id: lineItemId },
  };

  try {
    const cartId = window.localStorage.getItem(CART_ID);

    if (!cartId) {
      return;
    }

    const res = (await fetchApiHub(
      `/action/cart/removeLineItem?cartId=${cartId}`,
      {
        method: 'POST',
      },
      payload,
    )) as Cart;

    if (res.cartId) {
      delay(() => {
        mutate('/action/cart/getCart', res, { revalidate: false });
        removeProductFromLocalStorage(lineItemId, prevCart);
      }, 100);
      return { success: true, nextCart: res };
    }
    return { success: false };
  } catch (err) {
    console.error('Error API: removeItem', err);
    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }

    return { success: false, error: err };
  }
};

export const updateItem = async (
  lineItemId: string,
  newQuantity: number,
  externalPrice: Money,
  toast: ReturnType<typeof useToast>,
  toastMessage: string,
) => {
  const payload = {
    lineItem: {
      id: lineItemId,
      count: newQuantity,
      externalPrice,
    },
  };

  try {
    const cartId = window.localStorage.getItem(CART_ID);

    if (!cartId) {
      return;
    }
    const res = await fetchApiHub(
      `/action/cart/updateLineItem?cartId=${cartId}`,
      {
        method: 'POST',
      },
      payload,
    );

    if (res.cartId) {
      delay(() => {
        mutate('/action/cart/getCart', res, { revalidate: false });
      }, 100);
      return { success: true, nextCart: res };
    }
    if (res.getResponse) {
      const error = await res.getResponse().json();

      if (error?.errorCode) {
        toast({
          duration: 5000,
          status: 'error',
          title: error?.message,
          icon: TOAST_ICON.error,
          id: UPDATE_LINE_ITEM_ERROR_ID,
        });
      }
    }
    return { success: false };
  } catch (err) {
    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }

    return { success: false, error: err };
  }
};

export const updateCart = async (
  payload: CartDetails,
  toast: ReturnType<typeof useToast>,
  toastMessage: string,
): Promise<Cart> => {
  try {
    const res = await fetchApiHub(
      '/action/cart/updateCart',
      {
        headers: {
          accept: 'application/json',
        },
        credentials: 'include',
        method: 'POST',
      },
      payload,
    );
    mutate('/action/cart/getCart', res, { revalidate: false });
    return res;
  } catch (error) {
    console.error('Error API: updateCart', error);
    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }
  }
};

export const splitCart = async (
  cartId: string,
  lineItems: LineItem[],
  toast: ReturnType<typeof useToast>,
  toastMessage: string,
): Promise<Cart> => {
  try {
    const payload = {
      cartId: cartId,
      lineItems,
    };

    const res = await fetchApiHub(
      '/action/cart/splitCart',
      {
        headers: {
          accept: 'application/json',
        },
        credentials: 'include',
        method: 'POST',
      },
      payload,
    );
    mutate('/action/cart/getCart', res, { revalidate: false });
    return res;
  } catch (error) {
    console.error('Error API: splitCart', error);
    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }
  }
};

export const setShippingMethod = async (
  shippingMethodId: string,
  toast: ReturnType<typeof useToast>,
  toastMessage: string,
) => {
  try {
    const payload = {
      shippingMethod: {
        id: shippingMethodId,
      },
    };

    const res = await fetchApiHub(
      `/action/cart/setShippingMethod?shippingMethodId=${shippingMethodId}`,
      {
        headers: {
          accept: 'application/json',
        },
        credentials: 'include',
        method: 'POST',
      },
      payload,
    );
    mutate('/action/cart/getCart', res, { revalidate: false });
  } catch (error) {
    console.error('Error API: setShippingMethod', error);
    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }
  }
};

export const redeemDiscountCode = async (code: string, toast: ReturnType<typeof useToast>, toastMessage: string) => {
  try {
    const payload = {
      code: code,
    };
    const res = await fetchApiHub(
      `/action/cart/redeemDiscount`,
      {
        headers: {
          accept: 'application/json',
        },
        credentials: 'include',
        method: 'POST',
      },
      payload,
    );
    mutate('/action/cart/getCart', res, { revalidate: false });
  } catch (error) {
    console.error('Error API: redeemDiscountCode', error);
    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }
    throw error;
  }
};

export const removeDiscountCode = async (
  discount: Discount,
  toast: ReturnType<typeof useToast>,
  toastMessage: string,
) => {
  try {
    const payload = {
      discountId: discount.discountId,
    };
    const res = await fetchApiHub(
      '/action/cart/removeDiscount',
      {
        headers: {
          accept: 'application/json',
        },
        credentials: 'include',
        method: 'POST',
      },
      payload,
    );
    mutate('/action/cart/getCart', res, { revalidate: false });
  } catch (error) {
    console.error('Error API: removeDiscountCode', error);
    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }
    throw error;
  }
};

export const getOrder = async (orderId: string, toast: ReturnType<typeof useToast>, toastMessage: string) => {
  try {
    const payload = { orderId };

    return await fetchApiHub(
      '/action/cart/getOrder',
      {
        method: 'POST',
      },
      payload,
    );
  } catch (error) {
    console.error('Error API: getOrder', error);
    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }
    throw error;
  }
};

export const getCheckoutOrder = (
  toast: ReturnType<typeof useToast>,
  toastMessage: string,
  canProceedWithFetch?: boolean,
) => {
  try {
    //eslint-disable-next-line
    return useSWR(() => (canProceedWithFetch ? '/action/cart/getCheckoutOrder' : null), fetchApiHub, revalidateOptions);
  } catch (error) {
    console.error('Error API: getCheckoutOrder', error);
    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }
    throw error;
  }
};

export const addPaymentByInvoice = async (
  payment: Payment,
  toast: ReturnType<typeof useToast>,
  toastMessage: string,
) => {
  try {
    const payload = { payment };
    const res = await fetchApiHub(
      '/action/cart/addPaymentByInvoice',
      {
        method: 'POST',
      },
      payload,
    );
    mutate('/action/cart/addPaymentByInvoice', res);
    return res;
  } catch (error) {
    console.error('Error API: addPaymentByInvoice', error);
    toast({
      duration: 5000,
      status: 'error',
      title: toastMessage,
      id: GENERIC_TOAST_ERROR_ID,
      icon: TOAST_ICON.error,
    });
    throw error;
  }
};

export const emptyCartValue = () => {
  return {
    data: null,
    isLoading: false,
    isValidating: false,
    error: null,
    mutate: null,
  };
};

export const createCart = async (payload: {
  businessUnitKey: string;
  associateId: string;
  storeId: string;
  shamrockUserId?: string;
}) => {
  try {
    const res = await fetchApiHub(
      '/action/cart/createCart',
      { method: 'POST' },
      {
        ...payload,
      },
    );
    return res;
  } catch (error) {
    console.error('Error API: createCart', error);
  }
};

export const deleteCart = async (payload: { cartId: string; version: number }) => {
  try {
    const res = await fetchApiHub(
      '/action/cart/deleteCart',
      { method: 'POST' },
      {
        ...payload,
      },
    );
    return res;
  } catch (error) {
    console.error('Error API: deleteCart', error);
  }
};

export const getAllCartsPerAccount = async (payload: {
  accountList: AsAssociateBusinessUnitResult[];
  associateId: string;
}): Promise<AsAssociateBusinessUnitResult[]> => {
  try {
    const res = await fetchApiHub(
      '/action/cart/getCartsListPerAccount',
      { method: 'POST' },
      {
        ...payload,
      },
    );
    return res;
  } catch (error) {
    console.error('Error API: getCartsListPerAccount', error);
  }
};

export const getCartsList = async (payload: { businessUnitKey: string; associateId: string }) => {
  try {
    const res = await fetchApiHub(
      '/action/cart/getCartsList',
      { method: 'POST' },
      {
        ...payload,
      },
      2,
    );
    return res;
  } catch (error) {
    console.error('Error API: getCartsList', error);
  }
};

export const getCartById = async (cartId: string): Promise<Cart | null> => {
  try {
    const res = await fetchApiHub(`/action/cart/getCartById?cartId=${cartId}`, { method: 'GET' });
    if (res?.response?.status === 404) {
      return null;
    }
    return res;
  } catch (error) {
    console.error('Error API: getCartById', error);
  }
};
