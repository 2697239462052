import { ExtraProductData } from '@Types/shamrockApi/Product';
import { startOfWeek } from 'date-fns/startOfWeek';
import { endOfWeek } from 'date-fns/endOfWeek';
import { parse } from 'date-fns/parse';
import { differenceInCalendarWeeks } from 'date-fns/differenceInCalendarWeeks';
import { FormatMessageParams, useFormat } from 'helpers/hooks';

function getRelativeTimeInWeeks(
  dateString: string,
  formatMessage: (args: Omit<FormatMessageParams, 'name'>) => string,
) {
  const date = parse(dateString, 'yyyy-MM-dd', new Date());
  const now = new Date();

  const startOfCurrentWeek = startOfWeek(now);
  const endOfCurrentWeek = endOfWeek(now);

  if (date >= startOfCurrentWeek && date <= endOfCurrentWeek) {
    return formatMessage({ id: 'lastPurchaseDate.text.thisWeek' });
  } else {
    const weeksAgo = differenceInCalendarWeeks(now, date);

    return weeksAgo === 1
      ? formatMessage({ id: 'lastPurchaseDate.text.previousWeekSingular', values: { weeks: weeksAgo } })
      : formatMessage({ id: 'lastPurchaseDate.text.previousWeek', values: { weeks: weeksAgo } });
  }
}

export const useGetLastPurchaseDate = (extraProductData: ExtraProductData) => {
  const { formatMessage } = useFormat({ name: 'common' });

  if (extraProductData?.purchaseHistory?.data?.lastPurchase?.date) {
    const date = extraProductData?.purchaseHistory?.data?.lastPurchase?.date;
    const dateText = getRelativeTimeInWeeks(date, formatMessage);

    return {
      lastPurchaseDate: dateText,
    };
  }

  return {
    lastPurchaseDate: null,
  };
};
