import { useIsBrowser } from 'composable/helpers/hooks';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

export const DELAY_SCROLL = 500;
export const LIMIT_CALLS = 20;

const extractComponentId = (encodedUrl: string) => {
  try {
    const decodedUrl = decodeURIComponent(encodedUrl);

    const match = decodedUrl.match(/"redirectTo":"(.*?)"/);
    if (!match || match.length < 2) {
      return null;
    }

    const redirectTo = match[1];

    const idMatch = redirectTo.match(/#([a-f0-9-]+)/);
    return idMatch ? idMatch[1] : null;
  } catch (error) {
    return null;
  }
};

export const useScrollCMSComponents = () => {
  const alreadyScrolled = useRef(false);
  const router = useRouter();
  const [tries, setTries] = useState(0);
  const isBrowser = useIsBrowser();

  useEffect(() => {
    if (!isBrowser) return;

    const timeoutId = setTimeout(() => {
      const hash = window.location.hash || extractComponentId(router.asPath);
      if (hash && !alreadyScrolled.current && tries < LIMIT_CALLS) {
        const elementId = hash.replace('#', '');
        const element = document.getElementById(elementId);
        if (element) {
          alreadyScrolled.current = true;
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
          return;
        }
        setTries((prev) => prev + 1);
      }
    }, DELAY_SCROLL);

    return () => clearTimeout(timeoutId);
  }, [isBrowser, tries]);
};
