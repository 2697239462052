import { useMemo } from 'react';
import { useGlobal } from 'components/globalProvider';
import { WarehouseProps } from 'hooks/global/use_privateUserGlobal/types';

const SPLIT_TOKEN = '_';

export const useWarehouseByCustomerNumber = (customerNumber: string) => {
  const user = useGlobal().useUserGlobal.state;
  const { accountList } = user;
  const customerWarehouseHumber = useMemo<Partial<WarehouseProps>>(() => {
    const warehouse = accountList.find((bu) => bu.key === customerNumber)?.stores[0]?.key;
    if (!warehouse) {
      return {};
    }
    const [businessUnit, businessSegment, warehouseNumber] = warehouse.split(SPLIT_TOKEN);
    return {
      businessUnit,
      businessSegment,
      warehouseNumber,
    };
  }, [customerNumber, accountList]);

  return customerWarehouseHumber;
};
