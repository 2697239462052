import { BoxProps, Text } from '@chakra-ui/react';
import { ExtraProductData } from '@Types/shamrockApi/Product';
import { useFormat } from 'helpers/hooks';
import { getVariantColorForText } from '../helpers/determineColor';

type OrderLimitProps = BoxProps & {
  extraProductData: ExtraProductData;
  variant?: 'plp' | 'pdp';
};

export const OrderLimit = ({ extraProductData, variant = 'plp', ...rest }: OrderLimitProps) => {
  const orderLimit = extraProductData?.orderLimit?.data?.orderLimit;
  const { formatMessage } = useFormat({ name: 'common' });

  if (!orderLimit) {
    return null;
  }

  return (
    <Text
      as="p"
      fontSize={{ base: 'xs', md: 'sm' }}
      lineHeight="120%"
      color={getVariantColorForText(variant)}
      mt={2}
      {...rest}
    >
      {`${formatMessage({ id: 'productCard.limit' })} ${orderLimit}`}
    </Text>
  );
};
