// PLP
export const ADDEDD_PRODUCTS_FROM_PLP = 'addedProductsFromPlp';

//APP
export const ACCESS_TOKEN = 'access_token';
export const REFRESH_TOKEN = 'refresh_token';
export const HAS_SHAMROCK_USER_LOGIN_BEEN_TRACKED = 'hasShamrockUserLoginBeenTracked';
export const AUTH = 'auth';
export const REFRESH_ATTEMPT = 'refresh_attempt';

// Cart
export const TOTAL_EXCEPTIONS = 'totalExceptions';
export const OPEN_CART_MODAL = 'openCartModal';
export const NUM_OF_CART_EXCEPTIONS = 'numOfCartExceptions';
export const CART_ID = 'cartId';
export const LAST_CHECKOUT_CART_ID = 'lastCheckoutCartId';
export const IS_SPLIT_ORDER = 'isSplitOrder';
export const SWAP_CART_AND_ACCOUNT = 'swapCartAndAccount';

//OG
export const OG_LOCAL_STORAGE = 'ogLocalStorage';
export const PH_LOCAL_STORAGE = 'phLocalStorage';
export const SHOW_RESET_ORDER_GUIDE_BUTTON = 'showResetOrderGuideButton';
export const RESET_RESULTS_ORDER_GUIDE = 'resetResultsOrderGuide';
export const SHOW_RESET_ORDER_GUIDE_MANAGEMENT_BUTTON = 'showResetOrderGuideButtonManagement';
export const RESET_RESULTS_ORDER_GUIDE_MANAGEMENT = 'resetResultsOrderGuideManagement';
export const WENT_OFFLINE = 'wentOffline';
export const CURRENT_OG = 'currentOG';

//OGM Edit
export const SHOW_RESET_ORDER_GUIDE_EDIT_BUTTON = 'showResetOrderGuideEditButton';
export const RESET_RESULTS_ORDER_GUIDE_EDIT = 'resetResultsOrderGuideEdit';
export const TOAST_ON_REMOVE = 'toastOnRemove';

//OGM Modal
export const SHOW_RESET_ORDER_GUIDE_MODAL_BUTTON = 'showResetOrderGuideModalButton';
export const RESET_RESULTS_ORDER_GUIDE_MODAL = 'resetResultsOrderGuideModal';

//PDP Add to Order Guide Modal

export const SHOW_RESET_ADD_ORDER_GUIDE_MODAL_BUTTON = 'showResetAddOrderGuideModalButton';
export const RESET_RESULTS_ADD_ORDER_GUIDE_MODAL = 'resetResultsAddOrderGuideModal';

//OGM
export const OGM_LOCAL_STORAGE = 'ogmLocalStorage';

//Customer
export const SELECTED_BUSINESS_UNIT_KEY = 'selectedBusinessUnitKey';

// Edit Order
export const EDIT_ORDER_STATE = 'editOrder';
export const CURRENT_ORDER_EDITING = 'orderEditing';
export const ORIGINAL_ORDER = 'originalOrder';
export const SHIP_ON_PRODUCT_EXISTS = 'shipOnProductExists';
export const VALIDATING_CART = 'validatingCart';

export const CHECKOUT_PRIMARY_ORDER = 'checkoutPrimaryOrder';
export const CHECKOUT_SECONDARY_ORDER = 'checkoutSecondaryOrder';

//Super User
export const SU_ACCOUNT_NUMBER = 'suAccountNumber';
export const SU_ACCOUNT_NAME = 'suAccountName';
export const SU_ACCOUNT_INFORMATION = 'suAccountInformation';
