import { FunctionComponent } from 'react';
import { Flex, Text, TextProps } from '@chakra-ui/react';
import { ExtraProductData } from '@Types/shamrockApi/Product';
import { getInventoryMessageWithDueDate } from 'composable/helpers/utils/get-inventory-message-with-due-date';
import { InventoryStatus } from 'composable/helpers/utils/inventory-utils';
import { useFormat } from 'helpers/hooks/useFormat';
import { getObsoleteInventoryMessage } from 'composable/helpers/utils/get-obsolete-inventory-message';
import { PRODUCT_CARD_TEST_IDS } from '../constants';

type StockStatusProps = Pick<ExtraProductData, 'nextDeliveryDueDates'> & {
  status: InventoryStatus;
  textProps?: TextProps & {
    'data-testid'?: string;
  };
  availableQuantity?: number;
};

export const StockStatus: FunctionComponent<StockStatusProps> = ({
  nextDeliveryDueDates,
  status,
  textProps,
  availableQuantity,
}) => {
  const intl = useFormat({ name: 'common' });
  const { dueDate, status: statusMessage } = getInventoryMessageWithDueDate(
    status,
    intl,
    nextDeliveryDueDates,
    availableQuantity,
  );
  const obsoleteMessage = getObsoleteInventoryMessage(status, intl);

  const sku = textProps?.['data-testid']?.split('-').pop();

  if (!statusMessage && !dueDate) {
    return null;
  }

  return (
    <Flex flexDir="column" gap={0.5}>
      {obsoleteMessage && (
        <Text
          fontSize={{ base: 'xs', md: 'sm' }}
          color="neutral.600"
          textAlign="left"
          whiteSpace={{ base: 'normal', lg: 'pre-wrap' }}
          lineHeight="120%"
          {...textProps}
        >
          {obsoleteMessage}
        </Text>
      )}
      <Text
        fontSize={{ base: 'xs', md: 'sm' }}
        color="neutral.600"
        pt={{ md: 1 }}
        textAlign="left"
        lineHeight="120%"
        whiteSpace={{ base: 'normal', lg: 'pre-wrap' }}
        paddingTop={2}
        {...textProps}
        data-testid={PRODUCT_CARD_TEST_IDS.QUANTITY_AVAILABLE(sku)}
      >
        {statusMessage}
      </Text>
      {dueDate && (
        <Text
          fontSize={{ base: 'xs', md: 'sm' }}
          color="neutral.600"
          textAlign="left"
          whiteSpace={{ base: 'normal', lg: 'pre-wrap' }}
          lineHeight="120%"
          {...textProps}
          data-testid={PRODUCT_CARD_TEST_IDS.DUE_DATE(sku)}
        >
          {dueDate}
        </Text>
      )}
    </Flex>
  );
};
