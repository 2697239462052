export const MAXIMUM_ORDER_GUIDE_NAME_LENGTH = 30;

export const exportPHOptions = ['PDF', 'Excel', 'Word'] as const;

export const HEADER_VALUES = ['sku', 'cases'];

const MEGABYTE = 1024 * 1024;

export const MAX_CSV_FILE_SIZE = 2 * MEGABYTE;

export enum DOWNLOAD_CSV_PAGES {
  OGM = 'OGM',
}
