import { Flex, Text } from '@chakra-ui/react';
import { useFormat } from 'helpers/hooks';

type QuickCatalogModalEmptyStateProps = {
  showNoResultsText?: boolean;
};

export const QuickCatalogModalEmptyState = ({ showNoResultsText = true }: QuickCatalogModalEmptyStateProps) => {
  const { formatMessage } = useFormat({ name: 'common' });

  return (
    <Flex
      width="100%"
      height="66vh"
      justify="center"
      align="center"
      borderColor="neutral.200"
      borderRadius={12}
      borderWidth={1}
    >
      <Text textAlign="center" color="neutral.900" fontSize="base" fontWeight="normal" lineHeight="6">
        {`${showNoResultsText ? formatMessage({ id: 'qc.modal.noResults.message' }) : ''} ${formatMessage({
          id: 'qc.modal.empty.message',
        })}`}
      </Text>
    </Flex>
  );
};
