export const fonts = {
  body: 'Hanken Grotesk',
  heading: 'Hanken Grotesk',
};
export const fontSizes = {
  xxs: '0.625rem', //10px
  xs: '0.75rem', //12px
  sm: '0.875rem', //14px
  base: '1rem', //16px
  md: '1.125rem', //18
  lg: '1.25rem', //20
  xl: '1.5rem', //24
  xxl: '1.75rem', //28
  xxxl: '2.25rem', //36
  xxxxl: '2.625rem', //42
  xxxxxl: '3rem', //48

  //chakra-ui default
  '2xl': '1.5rem',
  '3xl': '1.875rem',
  '4xl': '2.25rem',
  '5xl': '3rem',
  '6xl': '3.75rem',
  '7xl': '4.5rem',
  '8xl': '6rem',
  '9xl': '8rem',
};
export const fontWeights = {
  light: 300,
  normal: 400,
  medium: 500,
  bold: 700,

  //chakra-ui default
  hairline: 100,
  thin: 100,
  semibold: 600,
  extrabold: 700,
  black: 800,
};
export const letterSpacings = {
  extraTight: '-0.04em',
  tight: '-0.02em',
  normal: '0',
  relaxed: '0.02em',
  casual: '0.03em',
  loose: '0.04em',
  extraLoose: '0.08em',

  //chakra-ui default
  tighter: '-0.05em',
  wide: '0.025em',
  wider: '0.05em',
  widest: '0.1em',
};
export const lineHeights = {
  none: 1,
  tight: 1.2,
  normal: 1.4,
  relaxed: 1.5,
  loose: 2,

  //chakra-ui default
  base: 1.5, // 150%
  shorter: 1.25,
  short: 1.375,
  tall: 1.625,
  taller: 2,
  '3': '.75rem', //12px
  '3.9': '0.9rem', // 14.4
  '4': '1rem', //16px
  '4.5': '1.05rem', // 16.8px
  '4.8': '1.125rem', // 18px
  '4.9': '1.2rem', // 19.2px
  '5': '1.25rem', //20
  '6': '1.5rem', //24
  '7': '1.75rem', //28
  '8': '2rem', //32,
  '8.1': '2.1rem', // 33.6
  '9': '2.25rem', //36
  '10': '2.5rem', //40
};

// Need to match with chakra-ui Theme Key
export const typography = {
  fonts,
  fontSizes,
  fontWeights,
  letterSpacings,
  lineHeights,
};

export type Typography = typeof typography;

export default typography;
