import { useState, useRef, useEffect, useCallback } from 'react';
import { useBreakpointValue } from '@chakra-ui/react';
import { ParQuantityPickersRefType } from 'composable/components/product-card-v2/parts/par-quantity-pickers';
import { QuantityPickerRef } from 'composable/components/quantity-picker';

export const useQuantityPickerFocus = (isOrderable: boolean, isParEnabled?: boolean) => {
  const isLargerThanTablet = useBreakpointValue({ base: false, md: true });
  const [showQuantityPicker, setShowQuantityPicker] = useState(false);
  const [isCardFocused, setIsCardFocused] = useState(false);
  const quantityRef = useRef<QuantityPickerRef>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const parQuantityRef = useRef<ParQuantityPickersRefType>(null);

  const handleCardClick = useCallback(() => {
    if (isParEnabled && !isCardFocused) {
      setIsCardFocused(true);
      setTimeout(() => {
        parQuantityRef.current?.focus();
      }, 0);
      return;
    }

    if (!showQuantityPicker && isOrderable) {
      setIsCardFocused(true);

      setTimeout(() => {
        quantityRef.current?.focus();
      }, 0);
    } else if (showQuantityPicker) {
      quantityRef.current?.focus();
    }
    setIsCardFocused(true);
    setShowQuantityPicker(true);
  }, [showQuantityPicker, isOrderable]);

  const handleOutsideClick = useCallback((event: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
      setShowQuantityPicker(false);
      setIsCardFocused(false);
    }
  }, []);

  const handleKeyDown = useCallback((event: React.KeyboardEvent) => {
    if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      event.preventDefault();
      quantityRef.current?.focus();

      if (event.key === 'ArrowLeft') {
        quantityRef.current?.decrement();
      } else if (event.key === 'ArrowRight') {
        quantityRef.current?.increment();
      }
    }
  }, []);

  useEffect(() => {
    if (isLargerThanTablet) {
      document.addEventListener('mousedown', handleOutsideClick);
      return () => {
        document.removeEventListener('mousedown', handleOutsideClick);
      };
    }
  }, [handleOutsideClick, isLargerThanTablet]);

  return {
    showQuantityPicker,
    setShowQuantityPicker,
    isCardFocused,
    setIsCardFocused,
    quantityRef,
    containerRef,
    handleCardClick: isLargerThanTablet ? handleCardClick : undefined,
    handleKeyDown: isLargerThanTablet && !isParEnabled ? handleKeyDown : undefined,
    parQuantityRef,
  };
};
