import { HStack, Icon, StackProps, Text, TextProps } from '@chakra-ui/react';
import { useGlobal } from 'components/globalProvider';
import { formatDateWTimeZone } from 'composable/components/plp/helpers';
import { UseGetOrderByDateType } from 'composable/helpers/hooks/useGetOrderByDate';
import { getActiveAnchorDate, handleDST } from 'composable/helpers/utils/dst';
import { format } from 'date-fns/format';
import { useFormat } from 'helpers/hooks/useFormat';
import { Truck } from 'phosphor-react';

type ShippingMessageProps = {
  deliveryDate: UseGetOrderByDateType['deliveryDate'];
  showDeliveryDate: UseGetOrderByDateType['showDeliveryDate'];
  textProps?: TextProps & {
    'data-testid'?: string;
  };
  stackProps?: StackProps;
  sku: string;
  hideIcon?: boolean;
};

export const ShippingMessage = ({
  deliveryDate,
  showDeliveryDate,
  textProps,
  stackProps,
  sku,
  hideIcon = false,
}: ShippingMessageProps) => {
  const {
    state: { activeAccount },
  } = useGlobal().useUserGlobal;
  const { formatMessage } = useFormat({ name: 'common' });

  const isTimeValid = (deliveryDate: UseGetOrderByDateType['deliveryDate']) => {
    return deliveryDate && deliveryDate?.cutoffDate && deliveryDate?.deliveryDay;
  };

  const dstCutoffDate = deliveryDate?.cutoffDate; //handleDST(getActiveAnchorDate(activeAccount), deliveryDate?.cutoffDate);
  const dstDeliveryDate = deliveryDate?.deliveryDay; //handleDST(getActiveAnchorDate(activeAccount), deliveryDate?.deliveryDay);

  if (!showDeliveryDate || !deliveryDate) {
    return;
  }

  return (
    <HStack mb={4} alignItems="flex-start" spacing={1} className="shipping-message" {...stackProps}>
      {showDeliveryDate && deliveryDate && (
        <>
          {!hideIcon && (
            <Icon
              as={Truck}
              boxSize={5}
              color="secondary.700"
              aria-label={formatMessage({ id: 'product.truck.icon.label', values: { value: sku } })}
            />
          )}
          <Text
            fontSize={{ base: 'xs', md: 'sm' }}
            color="secondary.800"
            lineHeight={{ base: '14.4px', md: '16.8px' }}
            mt="2px"
            {...textProps}
          >
            {isTimeValid(deliveryDate) &&
              formatMessage({
                id: 'categoryProductCard.orderByInfo',
                values: {
                  time: formatDateWTimeZone(dstCutoffDate),
                  date: format(dstDeliveryDate, 'MM/dd'),
                },
              })}
          </Text>
        </>
      )}
    </HStack>
  );
};
