export function getProductsMaxLeadDays(
  extraProductData: any[],
  daysForCurrentDate: boolean = false,
  furthestDate?: Date,
) {
  const dayOfWeek = new Date().getUTCDay();
  const furhtestDateOfWeek = furthestDate ? furthestDate.getUTCDay() : dayOfWeek;

  const productsleadDays = extraProductData?.map((product) => {
    const cutoffs = product?.companyInfo?.data?.cutoffs?.daysOfWeek ?? [];

    if (cutoffs.length > 0) {
      // find respective dayofweek cutoff lead days

      const weekDayCutoff = cutoffs.find((cutoff) => {
        return cutoff?.dayOfWeek === furhtestDateOfWeek;
      });

      const cutoffLeadDays = daysForCurrentDate
        ? [weekDayCutoff?.leadDays || 0]
        : cutoffs.map((cutoff) => cutoff?.leadDays || 0);

      return Math.abs(Math.max(...cutoffLeadDays));
    }
    return 0;
  });

  if (productsleadDays?.length > 0) {
    return Math.max(...productsleadDays);
  }

  return 0;
}
