import { FormEvent, useEffect, useMemo, useState } from 'react';
import { Box, Icon, Image, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { MagnifyingGlass } from 'phosphor-react';
import { useGlobalSearch } from 'composable/components/global-search/context';
import { useFormat } from 'helpers/hooks';

type QuickCatalogModalSearchInputProps = {
  isDisabled?: boolean;
  onClearSearch?: () => void;
  onSearchSubmit?: (value: string) => void;
};

export const QUICK_CATALOG_SEARCH_INPUT = 'quick-catalog-search-bar';

export const QuickCatalogModalSearchInput = ({
  isDisabled = false,
  onClearSearch = undefined,
  onSearchSubmit = undefined,
}: QuickCatalogModalSearchInputProps) => {
  const { formatMessage } = useFormat({ name: 'common' });
  const { inputValue } = useGlobalSearch();
  const [value, setValue] = useState('');

  const [hasFocused, setHasFocused] = useState(false);
  const [hasBeenDisabled, setHasBeenDisabled] = useState(false);

  const isEmpty = useMemo(() => {
    return !value || value.trim().length <= 0;
  }, [value]);

  useEffect(() => {
    if (inputValue === '') {
      setHasFocused(true);
    }

    return () => {
      setHasFocused(false);
    };
  }, []);

  useEffect(() => {
    if (isDisabled) {
      setHasBeenDisabled(true);
    }

    return () => {
      setHasBeenDisabled(false);
    };
  }, [isDisabled]);

  useEffect(() => {
    if (isDisabled === false && hasBeenDisabled) {
      const searchBar = document.getElementById(QUICK_CATALOG_SEARCH_INPUT);
      if (searchBar && !hasFocused) {
        (searchBar as HTMLInputElement).selectionStart = value.length;
        searchBar.focus();
        setHasFocused(true);
      }
    }
  }, [isDisabled, value]);

  useEffect(() => {
    if (inputValue !== '') {
      setValue(inputValue);
    }
  }, [inputValue]);

  const resetSearchInput = () => {
    setValue('');
    if (onClearSearch) {
      onClearSearch();
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isEmpty) {
      return;
    }

    if (onSearchSubmit) {
      onSearchSubmit(value);
      (document.activeElement as HTMLElement)?.blur();
    }
  };

  return (
    <Box width="100%">
      <form onSubmit={handleSubmit}>
        <InputGroup className="search-input" width={{ base: ' 100%', md: '347px', lg: '687px' }}>
          <Input
            autoFocus
            id={QUICK_CATALOG_SEARCH_INPUT}
            data-testid={QUICK_CATALOG_SEARCH_INPUT}
            value={value}
            paddingLeft={4}
            paddingRight={15}
            type="search"
            placeholder={formatMessage({ id: 'qc.modal.search.placeholder' })}
            fontSize="base"
            color="neutral.900"
            borderColor="neutral.300"
            _placeholder={{ color: 'neutral.600' }}
            _hover={{ borderColor: 'neutral.300' }}
            _focus={{ borderColor: 'secondary.600' }}
            onChange={({ target }) => {
              if (target.value === '' && onClearSearch) {
                onClearSearch();
              }
              setValue(target.value);
            }}
            enterKeyHint="search"
            isDisabled={isDisabled}
          />
          <InputRightElement width="fit-content" paddingRight={2}>
            {!isEmpty && (
              <Box
                data-testid="quick-catalog-search-cancel"
                as="button"
                type="button"
                width={5}
                height={5}
                marginRight={2}
                disabled={isEmpty}
                onClick={resetSearchInput}
              >
                <Image src="/images/XCircle.svg" alt={formatMessage({ id: 'action.cancel' })} />
              </Box>
            )}
            <Box
              data-testid="quick-catalog-search-submit"
              as="button"
              type="submit"
              display="flex"
              justifyContent="center"
              alignItems="center"
              disabled={isEmpty}
            >
              <Icon
                as={MagnifyingGlass}
                color="primary.400"
                boxSize={6}
                cursor={isEmpty ? 'default' : 'pointer'}
                aria-label={formatMessage({ id: 'search.filter.icon.label', values: { value: '3' } })}
              />
            </Box>
          </InputRightElement>
        </InputGroup>
      </form>
    </Box>
  );
};
