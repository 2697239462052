import { MAXIMUM_NEXT_PRODUCT_PURCHASE_DATES } from 'composable/components/pdp/constants';
import { GetProductExtendedDataProps } from 'frontastic/actions/shamrockApi/types';
import { WarehouseProps } from 'hooks/global/use_privateUserGlobal/types';

export const createProductExtraQueryParams = (
  accessToken: string,
  selectedBusinessUnitKey: string,
  storeIds: WarehouseProps,
  listOfBuKeys: string[],
  page: string,
): GetProductExtendedDataProps => {
  let queryParams = {
    warehouseNumber: storeIds?.warehouseNumber,
    businessUnitName: storeIds?.businessUnit,
    businessSegmentName: storeIds?.businessSegment,
    customerNumber: selectedBusinessUnitKey,
    includePricing: true,
    includeInventory: true,
    includeCompany: true,
    includeNextDeliveryDueDates: true,
    includeSubstitutions: true,
    accessToken: accessToken,
    includePurchaseHistory: {
      includeLastPurchase: true,
      includeCustomerHistory: false,
    },
  };
  if (page === 'pdp') {
    queryParams['nextDuePurchaseOrdersLimit'] = MAXIMUM_NEXT_PRODUCT_PURCHASE_DATES;
    queryParams['includeNextDuePurchaseOrders'] = true;
    queryParams['includePurchaseHistory'] = {
      includeLastPurchase: true,
      includeCustomerHistory: true,
    };
    if (listOfBuKeys.length > 0) {
      queryParams['includePurchaseHistory']['purchaseHistoryOtherCustomers'] = [
        {
          warehouseNumber: storeIds.warehouseNumber,
          businessUnitName: storeIds.businessUnit,
          businessSegmentName: storeIds.businessSegment,
          customerNumbers: listOfBuKeys,
        },
      ];
    }
  }
  return queryParams;
};
