import { FunctionComponent, PropsWithChildren } from 'react';
import { Text, TextProps } from '@chakra-ui/react';

type DescriptionProps = {
  textProps?: TextProps;
};

export const Description: FunctionComponent<PropsWithChildren<DescriptionProps>> = ({ children, textProps }) => {
  return (
    <Text
      color="neutral.900"
      fontSize="xs"
      lineHeight="120%"
      textOverflow="ellipsis"
      whiteSpace="nowrap"
      overflow="hidden"
      maxWidth="100%"
      textTransform="capitalize"
      {...textProps}
    >
      {children}
    </Text>
  );
};
