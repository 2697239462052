import { createContext, FunctionComponent, PropsWithChildren, useContext, useEffect, useMemo } from 'react';
import { useGlobal } from 'components/globalProvider';
import capitalize from 'lodash/capitalize';
import uniq from 'lodash/uniq';
import { OrderGuideMultipleCustomers } from '../order-guide-management/components/types';

// Define the shape of your context value
type OrderGuidesByCustomerCustomersContextType = {
  allOrderGuides: OrderGuideMultipleCustomers[];
  myCustomOrderGuides: OrderGuideMultipleCustomers[];
  isLoadingOrderGuides: boolean;
  autocompleteStrings: string[];
  revalidateOrderGuides: (includeDrafts: boolean) => void;
  showAddToOrderGuideModal: boolean;
};

type OrderGuidesByCustomerCustomersProviderProps = {
  currentOne?: boolean;
};

// Create the context
const OrderGuidesByCustomerCustomersContext = createContext<OrderGuidesByCustomerCustomersContextType | undefined>(
  undefined,
);

// Create a custom hook to access the context
export const useOrderGuidesByCustomerNumbersContext = (): OrderGuidesByCustomerCustomersContextType => {
  const context = useContext(OrderGuidesByCustomerCustomersContext);
  if (!context) {
    throw new Error(
      'useOrderGuidesByCustomerNumbersContext must be used within a OrderGuidesByCustomerCustomersContext',
    );
  }
  return context;
};

export const OrderGuidesByCustomerCustomersProvider: FunctionComponent<
  PropsWithChildren<OrderGuidesByCustomerCustomersProviderProps>
> = ({ children, currentOne }) => {
  const orderGuideListGlobal = useGlobal().useOrderGuideListGlobal;
  const userGlobal = useGlobal().useUserGlobal;

  // fetch once draft orderguides
  useEffect(() => {
    orderGuideListGlobal.listOrderGuides(true);
  }, []);

  // all OGs are loaded at once, then we filter them by customer number when needed
  const allOrderGuides = useMemo(
    () =>
      orderGuideListGlobal.state.draftOgList.filter((orderGuide) => {
        if (currentOne) {
          return orderGuide.customerNumbers.includes(userGlobal.state.activeAccount?.key);
        }

        return true;
      }),
    [currentOne, orderGuideListGlobal.state.draftOgList, userGlobal.state.activeAccount?.key],
  );

  const autocompleteStrings = useMemo(() => {
    if (!allOrderGuides?.length) {
      return [];
    }

    return uniq(
      allOrderGuides.reduce((acc, curr) => {
        acc.push(capitalize(curr?.name));

        return acc;
      }, []),
    );
  }, [allOrderGuides]);

  const myCustomOrderGuides = allOrderGuides.filter((orderGuide) => !orderGuide.isContract);

  const showAddToOrderGuideModal = myCustomOrderGuides.length > 0 && !orderGuideListGlobal.state.loading;

  return (
    <OrderGuidesByCustomerCustomersContext.Provider
      value={{
        allOrderGuides,
        isLoadingOrderGuides: orderGuideListGlobal.state.loading,
        autocompleteStrings,
        revalidateOrderGuides: (includeDrafts: boolean) => {
          orderGuideListGlobal.listOrderGuides(includeDrafts);
        },
        showAddToOrderGuideModal,
        myCustomOrderGuides,
      }}
    >
      {children}
    </OrderGuidesByCustomerCustomersContext.Provider>
  );
};
