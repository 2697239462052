import { Box, BoxProps, Link, Text, TextProps } from '@chakra-ui/react';
import breakpoints from 'composable/chakra/theme/foundations/breakpoints';
import NextLink, { LinkProps } from 'next/link';
import { FunctionComponent, PropsWithChildren } from 'react';

type BrandProps = {
  breakpointTokenToDesktop?: keyof typeof breakpoints;
  clickable?: boolean;
  textProps?: TextProps;
  boxProps?: BoxProps;
};

export const Brand: FunctionComponent<PropsWithChildren<BrandProps>> = ({
  breakpointTokenToDesktop = 'md',
  children,
  clickable,
  textProps,
  boxProps,
}) => {
  return (
    <Text
      fontSize={{ base: 'xs', [breakpointTokenToDesktop]: 'sm' }}
      color="neutral.600"
      lineHeight={{ base: '150%', [breakpointTokenToDesktop]: '120%' }}
      _focusWithin={{
        outlineColor: 'violet.500',
        outlineWidth: '2px',
        outlineStyle: 'solid',
        outlineOffset: '1px',
        boxShadow: 'none',
        borderRadius: '4px',
      }}
      {...(clickable && { _hover: { color: 'primary.500' } })}
      {...textProps}
    >
      {clickable ? (
        <Link
          as={NextLink}
          href={`/search?brand=${encodeURIComponent(children as string)}`}
          _hover={{ textDecoration: 'none' }}
          passHref
        >
          <Box
            as="a"
            w="100%"
            {...boxProps}
            _focus={{
              outlineColor: 'violet.500',
              outlineWidth: '2px',
              outlineStyle: 'solid',
              outlineOffset: '1px',
              boxShadow: 'none',
              borderRadius: '4px',
            }}
          >
            {children}
          </Box>
        </Link>
      ) : (
        children
      )}
    </Text>
  );
};
