import NextLink from 'next/link';
import NextImage from 'next/image';
import { Box, BoxProps, Link } from '@chakra-ui/react';

type QuickCatalogModalProductImageProps = BoxProps & {
  slug: string;
  priority: boolean;
  imageURL: string;
  productName: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

export const QuickCatalogModalProductImage = ({
  slug,
  priority,
  imageURL,
  productName,
  onClick,
  ...props
}: QuickCatalogModalProductImageProps) => {
  return (
    <Box
      _focusWithin={{
        outlineColor: 'violet.500',
        outlineWidth: '2px',
        outlineStyle: 'solid',
        boxShadow: 'none',
        borderRadius: '1px',
      }}
    >
      <Link 
        as={NextLink} 
        href={`/product/${slug}`} 
        passHref
        onClick={(e) => {
          if (onClick) {
            e.stopPropagation();
            onClick(e);
          }
        }}
      >
        <Box 
          as="a" 
          display="flex" 
          position="relative" 
          borderRadius={'lg'}
          onClick={(e) => e.stopPropagation()}
          {...props}
        >
          <NextImage
            priority={priority}
            src={imageURL || '/images/image-na-placeholder.webp'}
            alt={productName}
            layout="responsive"
            height={0}
            width={0}
            style={{ width: '100%', height: 'auto' }}
            sizes="(max-width: 1024px) 48px, (max-width: 1440px) 48px"
          />
        </Box>
      </Link>
    </Box>
  );
};
