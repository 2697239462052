/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useEffect, useReducer } from 'react';
import { getDeliveryOptionsSoftCutoffs } from 'frontastic/actions/shamrockApi';
import { cutoffsInitialState, reduceCutoffs } from './reduce-cutoffs';
import { CutoffsGlobalStateActions, UseCutoffsGlobalParams } from './types';
import { useRouter } from 'next/router';
import { isSuperUserPage as isSuperUserPageFn } from 'helpers/slugHelpers';

export const use_privateCutoffsGlobal = ({ user, isPublic }: UseCutoffsGlobalParams) => {
  const [state, dispatch] = useReducer(reduceCutoffs, cutoffsInitialState);
  const router = useRouter();
  const isSuperUserPage = isSuperUserPageFn(router.asPath);

  const userIsReady = () => {
    return !isPublic && !!user && !user?.loading && !!user.activeAccount?.key && !!user.accessToken && !isSuperUserPage;
  };

  const loadSoftCutoffs = useCallback(async () => {
    dispatch({ type: CutoffsGlobalStateActions.SET_LOADING, payload: { loading: true } });

    // Soft Cutoffs API call
    try {
      const response = await getDeliveryOptionsSoftCutoffs(user.accessToken, {
        warehouseNumber: user.activeWarehouse?.warehouseNumber,
        businessUnitName: user.activeWarehouse?.businessUnit,
        businessSegmentName: user.activeWarehouse?.businessSegment,
        customerNumber: user.activeAccount?.key,
      });

      dispatch({
        type: CutoffsGlobalStateActions.SET_SOFT_CUTOFFS,
        payload: { softCutoffs: response, loading: false },
      });
    } catch (error) {
      console.error('API Error soft cutoffs', error);
    } finally {
      dispatch({ type: CutoffsGlobalStateActions.SET_LOADING, payload: { loading: false } });
    }
  }, [user?.activeAccount?.key, user?.loading, isPublic, user?.accessToken, isSuperUserPage]);

  useEffect(() => {
    if (!userIsReady()) {
      return;
    }

    loadSoftCutoffs();
  }, [user?.loading, isPublic, user?.activeAccount?.key, user?.accessToken]);

  // clear Cutoff if we get into super user page
  // so it'll reset the object after user change accounts
  useEffect(() => {
    if (isSuperUserPage) {
      dispatch({
        type: CutoffsGlobalStateActions.SET_INITIAL,
      });
    } else if (userIsReady() && state.softCutoffs === cutoffsInitialState.softCutoffs) {
      loadSoftCutoffs();
    }
  }, [isSuperUserPage]);

  return { ...state, dispatch };
};
